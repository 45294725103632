import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser'; 

import connector from './connector.js';
import {keys, func} from './func.js';

import {Link} from "react-router-dom";

import Header from './Header.js';
import Select from './Select.js';
import Teachers from './Teachers.js';
import Footer from './Footer.js';
import {UserProfile, OdminPanel, LT, currSel} from './Odmin.js';
import OdminPage from './OdminPage.js';
import {events_exp, setActiveEvent}	from './Footer.js';

import './Main.css';
import './Events.css';
import './Select.css';

import menu_icon_schedule from '../images/schedule.png';
import menu_icon_teacher from '../images/teacher1.png';
import menu_icon_event from '../images/event.png';

//import img_fone_main from '../images/fone_main.png';
import img_fone_main_left from '../images/fone_main_left.png';
import img_fone_main_right from '../images/fone_main_right.png';
import img_fone_header from '../images/fone_select_light.png';

const _touchTimeout = 5 * (60 * 1000);
const _reloadTimeout = 60 * (60 * 1000); // 60 мин

class Main extends Component {

	constructor(props) {
		super(props);

		this.uz 				= this.props.uz;
		this.fac_id			= this.props.fac_id;
		this.group_id 	= this.props.group_id;

		this.odmin_mode	= UserProfile.getOdminMode(this.uz && this.uz.url);
 
		this.sel_item		= this.props.sel_item || 
											(this.uz && (this.fac_id || this.group_id)? 1: 0);

		this.state = {
			uz					: this.uz,
			lang				: UserProfile.getLang(),
			sel_item		: this.sel_item,
			innerWidth	: window.innerWidth,
			innerHeight	: window.innerHeight,
		}

		// this.uz.subj, this.uz.teacher и остальные, кто "объекты",
		// при их создании на серваке, сортируются по индексам,
		// и пофиг что запрос был "order by name"
		// такчто здесь делаем массив и сортируем по алфавиту
		// и это всё для combobox'ов (select'ов) для выбора значений полей типа room_id
		if (this.odmin_mode) {
			this.uz.subj_arr = this.uz.subj && Object.entries(this.uz.subj).sort(
															(a, b) => a[1].name < b[1].name? -1: 1) || [];
			this.uz.teacher_arr = this.uz.teacher && Object.entries(this.uz.teacher).sort(
															(a, b) => a[1].name < b[1].name? -1: 1) || [];
			this.uz.less_type_arr = this.uz.less_type && Object.entries(this.uz.less_type).sort(
															(a, b) => a[1].name < b[1].name? -1: 1) || [];
			this.uz.room_arr = this.uz.room && Object.entries(this.uz.room).sort(
															(a, b) => a[1].name < b[1].name? -1: 1) || [];
			this.uz.build_arr = this.uz.build && Object.entries(this.uz.build).sort(
															(a, b) => a[1].name < b[1].name? -1: 1) || [];
			this.uz.pics_arr = this.uz.pics_names && Object.entries(this.uz.pics_names).sort(
															(a, b) => a[1].name < b[1].name? -1: 1) || [];
		}

		this.root			= this.props.root;
		
		this.menu_icon	= ['',	menu_icon_schedule, menu_icon_teacher, '', menu_icon_event];
		this.menu				= ['',	'Расписание',
														'Преподаватели', 
														'',
														'События', // События
												];
		this.links			= ['',	'schedule', 
														'teacher', 
														'', 
														'event', //event
													];

		// в Header передаётся this.menu[this.sel_item]
		// и onClick для 0-го эл-та свой. кароче он тут не просто так.
		// if (this.uz)
			this.menu[0] = func.Words('MainCaption');// 'Электронное расписание';
		// this.uz.UI.name_in_header !== false? this.uz.name: 'Электронное расписание';
		//this.uz.UI.name_in_header !== false? this.uz.name_full: this.uz.name;

		this.cnt			= 0
		this.facRef 	= []

		this.onTouch			= this.onTouch.bind(this);
	} // constructor

	onTouchTimeout = () => {
		console.log('onTouchTimeout', this.sel_item);

		clearTimeout(this.ti_Touch);

		if (this.sel_item > 0 && window.innerWidth > 1500) {
			this.props.history.push(`${this.root}${this.uz.url}`);
		}
		else {
			this.ti_Touch = setTimeout(this.onTouchTimeout, _touchTimeout);
		}
	}

	onTouch() {
		console.log('touchListener');

		clearTimeout(this.ti_Touch);
		this.ti_Touch = setTimeout(this.onTouchTimeout, _touchTimeout);

		// пока есть касания - таймер перезагрузки не срабатывает
		clearTimeout(this.ti_reload);
		this.ti_reload = setTimeout(this.onReloadTimeout, _reloadTimeout);
	}

	onReloadTimeout = () => {
		console.log('onReloadTimeout');

		if (this.uz) {
			console.log('Server ping...');
			connector.do_query({cmd:'ping', uz_id:this.uz.id}, (msg) => {
				console.log('Ping reply:', msg);
				if (msg == 'pong!')
					window.location.reload();
				else
					this.ti_reload = setTimeout(this.onReloadTimeout, _reloadTimeout);
			});
		}
		else
			window.location.reload();
	}

	onResize = () => {
		this.setState({
			innerWidth:		window.innerWidth,
			innerHeight:	window.innerHeight
		});
	}

	componentDidMount() {
		// console.log(`DidMount Main`);

		window.addEventListener('touchstart', this.onTouch);

		window.addEventListener('resize', this.onResize, true);

		if (!this.uz && this.props.msg)
			document.documentElement.style.setProperty(`--font-family`, 'Segoe UI');

		// this.menu_icon.forEach((icon, idx) => {
		// 	if (icon) {
		// 		let img = new Image();
		// 		img.src = icon;
		// 	}
		// });

		this.ti_facRef = setInterval(() => {
			// курсор бегает по меню
			if (this.facRef.length === 0) return;

			let prev = this.cnt - 1;
			if (prev < 0)
				prev = this.facRef.length - 1;

			if (this.facRef[prev] && this.facRef[prev].current)
				this.facRef[prev].current.classList.remove('curr');

			this.cnt ++;
			if (this.cnt > this.facRef.length * 3)
				this.cnt = 0;

			if (this.facRef[this.cnt] && this.facRef[this.cnt].current)
				this.facRef[this.cnt].current.classList.add('curr');
		}, 150);

		this.ti_reload = setTimeout(this.onReloadTimeout, _reloadTimeout);
	}

	componentWillUnmount() {
		clearTimeout(this.ti_Touch);
		window.removeEventListener('touchstart', this.onTouch);
		window.removeEventListener('resize', this.onResize);
		clearInterval(this.ti_facRef);
		clearInterval(this.ti_reload);
	}

	handle_menu_Click(idx) {
		// if (idx === 0 && this.sel_item == 0) {
		// 	window.location.href = '/'+this.props.root;
		// 	return
		// }
		return;

		this.fac_id = undefined;
		this.group_id = undefined;

		this.sel_item = idx;

		this.setState({
			sel_item : this.sel_item
		});
	}

	handle_lang_Click(lang) {
		UserProfile.setLang(lang);
		this.setState({
			lang: lang
		});
	}

	render() {
		if (!this.props.group_id && this.odmin_mode) {
			currSel.cancelOper(true);
		}

		// console.log('Main render()');
		this.menu = LT && LT.main_menu || this.menu;

		this.sel_item = this.props.sel_item || 
										(this.props.uz && (this.props.fac_id || this.props.group_id)? 1: 0);
		this.fac_id		= this.props.fac_id;

		let sym = ' ' + (this.uz && this.uz.UI && this.uz.UI['link-symbol'] || '❯') + ' ';
		let docTile = func._mainDocTitle + sym + (this.uz && this.uz.name);
		document.title = docTile;

		if (this.uz && this.props.updateManifest)
			this.props.updateManifest(this.uz.name, window.location.href);


		const MainMenu = (props) => {
			if (!this.uz) return null;
			
			this.facRef = [];
			if (this.sel_item !== 0) return null;
			this.cnt = this.menu.length;

			const MenuItem = (props) => {
				if (!props.m1 || props.m1 === '-')
					return <div className='menu_divider'></div>

				this.facRef[props.idx] = React.createRef();

				return (
					<Link to={`${this.root}${this.uz.url}/${this.links[props.idx]}`}>
						<div	className={'menu_item' + (props.idx > 2? ' gray': '')}
									ref={this.facRef[props.idx]} 
									onClick={this.handle_menu_Click.bind(this, props.idx)}>
							{this.state.innerWidth > 400 && this.menu_icon[props.idx]? 
								<img src={this.menu_icon[props.idx]} alt=""/>
								: null}
							{props.m1}
						</div>
					</Link>
				)
			}

			return (<div className={'menu_cont'
							 + (this.uz && this.uz.UI && this.uz.UI['menu_as_board']? ' menu_as_board': '')
							 + (!this.uz.pics? ' menu_cont_lower': '')}>
				{this.menu.map((m1, idx) => 
					idx > 0 && (this.links[idx] || m1 === '-') &&
						<MenuItem m1={m1} idx={idx} key={idx}/>
				)}
			</div>)
		} // MainMenu

		const NavButtons = (props) => {
			//◀🏠▲◀🡸🡹
			if (!this.uz || !this.sel_item || this.state.innerWidth <= 320) return null;
			let wide = this.state.innerWidth > 1000;
			return (
				<>
				{wide?
					<div	title={LT && LT.navigation.back || 'Назад'} className="Button NavButton NavButtonRight" 
								onClick={() => {window.history.back();}}>
						{'ç' /* стрелка влево */ }
					</div>: null}
				<Link to={this.root + this.uz.url}>
					<div	title={LT.navigation.home} className={"Button NavButton"}>
					{'é' /* стрелка вверх */ }
					</div>
				</Link>
			</>)
		}

		const LangButtons = (props) => {
			if (this.sel_item) return null;
			// let wide = this.state.innerWidth > 1000;
			return (
				<div className='LangButton_cont'>
					<div className={'Button LangButton'
													+(this.state.lang === 'eng'? ' selected': '')}
								onClick={this.handle_lang_Click.bind(this, 'eng')}>
						eng
					</div>
					<div className={'Button LangButton LangButtonR'
													+(this.state.lang === 'rus'? ' selected': '')}
								onClick={this.handle_lang_Click.bind(this, 'rus')}>
						рус
					</div>
					<div className={'Button LangButton LangButtonRR'
													+(this.state.lang === 'kaz'? ' selected': '')}
								onClick={this.handle_lang_Click.bind(this, 'kaz')}>
						қаз
					</div>
				</div>)
		}

		const UzNotFound = (props) => {
			if (this.uz) return null;

			return (<div className='Select'>
								<div className='menu_cont_no_uz'>
									{this.props.msg}
								</div>
								<div	title={LT.navigation && LT.navigation.back || ''} 
											className="Button NavButton NavButtonRight NavButtonRightSingle" 
											onClick={() => {window.history.back();}}>
									{'ç'}
								</div>
								<a href={window.location.href}>
									<div	title={LT.navigation && LT.navigation.refresh} 
												className={"Button NavButton NavButtonRightSingle"}>
										{'6'}
									</div>
								</a>
							</div>)
		}

		const Events = (props) => {
			if (!events_exp) {
				clearTimeout(this.ti_WhaitForEvents);
				this.ti_WhaitForEvents = setTimeout(() => {
					this.forceUpdate();
				}, 1000);
				return null;
			}

			return (
				<div className='Select'>
					<div className='Events_Cont'>
						{events_exp.map((ev, idx) => {
								let strDateTime = func.localTime(new Date(ev.date), true, true) + '';
								if (strDateTime.split(' ')[1] === '00:00')
									strDateTime = strDateTime.split(' ')[0];

								return (<div key={idx} className='Event' onClick={() => setActiveEvent(ev.id)}>
									<div className='Event_Header'>
										{strDateTime}
									</div>
									<div className='Event_Body'>
										{ReactHtmlParser(ev.text)}
									</div>
									</div>)
						})}
					</div>
				</div>
			)
		}

		document.documentElement.style.setProperty(`--nav-bottom`, '0px');
		// document.documentElement.style.setProperty(`--nav-display`, 'block');
		// document.documentElement.style.setProperty(`--nav-opacity`, '50%');

		const Hdr = () => (<Header 
												root={this.props.root} 
												uz={this.uz} 
												sel_item={this.sel_item}
												caption={this.menu[this.sel_item]}
												onClick={this.handle_menu_Click.bind(this, 0)} 
											/>);

		let img_bw = (!this.uz? ' img_fone_bw': '');

		// console.log('top_b64', this.uz.pics.top_b64);
		// console.log('img_fone_header', img_fone_header);

		return (
			<div className={'Main' + (this.sel_item === 0? ' fone_main': ' fone_others')}>

				{this.sel_item 	> 0 && this.uz && this.uz.pics &&
						<img	className={'img_fone_header' + img_bw}
									src={this.uz && this.uz.pics.top_b64 || img_fone_header}/>}

				{!this.props.odmin && this.sel_item == 0 && this.uz && this.uz.pics &&
					<img	className={'img_fone_main_left' + img_bw} 
								src={this.uz && this.uz.pics.left_b64 || img_fone_main_left}/>}
				{!this.props.odmin && this.sel_item == 0 && this.uz && this.uz.pics &&
					<img	className={'img_fone_main_right' + img_bw} 
								src={this.uz && this.uz.pics.right_b64 || img_fone_main_right}/>}

				{this.uz? 
					<a href={`${this.root}${this.uz.url}`}><Hdr/></a>
					: <a href={window.location.href}><Hdr/></a>}

				<OdminPanel 
					root  = {this.props.root} 
					uz		= {this.uz} 
					uz_url= {this.uz && this.uz.url} 
					odmin = {this.props.odmin}
				/>

				{this.props.odmin && this.uz?
					<OdminPage uz={this.uz} />
					: <>
							<LangButtons />
							<UzNotFound />
							<MainMenu />
						</>}

				{this.sel_item === 1 &&																				// расписание
					<Select root={this.props.root} 
									uz={this.sel_item === 1? this.uz: undefined} 
									fac_id={this.props.fac_id} 
									group_id={this.props.group_id} 
									updateManifest={this.props.updateManifest} />
					}

				{this.sel_item === 2 &&																				// преподаватели
					<Teachers root={this.props.root}
										uz={this.uz}
										teacher_id={this.props.teacher_id}
										updateManifest={this.props.updateManifest} />
					}

				{this.sel_item === 4 && <Events />}

				{this.sel_item > 4 && <Select />}

				<NavButtons />

				{/* Теперь Footer вызывается из App, чтобы не перекрывался лентой */}
				{/* <Footer uz={this.uz}/> */}
			</div>
		)
	}
} // Main

export default withRouter(Main);
