import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

import connector from './connector.js';
import {keys, func} from './func.js';

import AnimateHeight from 'react-animate-height';

import {Link} from "react-router-dom";
// import Tooltip from '@material-ui/core/Tooltip';

import CircularProgress from '@material-ui/core/CircularProgress';
import './Teachers.css';

import Schedule_T from './Schedule_T.js';
import {UserProfile, OdminPanel} from './Odmin.js';


class Teachers extends Component {

	constructor(props) {
		super(props);

		this.state = {
			teachers			: undefined,
			teachers_msg	: undefined,

			t_id					: this.props.teacher_id,
		}

		this.root						= this.props.root
		this.uz 						= this.props.uz
		this.odmin_mode			= UserProfile.getOdminMode(this.uz && this.uz.url);

		this.teachers						= undefined
		this.req_interval				= 60 * 1000
		this.req_interval_mount	= 50
		this.filter							= []

		this.cnt				= 0
		this.facRef 		= []

		this.t_id				= this.state.t_id;
		this.t					= undefined
	}

	componentDidMount() {
		// console.log(`DidMount Teachers`);
		this.getData(this.req_interval_mount);

		this.ti_facRef = setInterval(() => {
			// курсор бегает по меню
			if (this.facRef.length === 0) return;

			let prev = this.cnt - 1;
			if (prev < 0)
				prev = this.facRef.length - 1;

			if (this.facRef[prev] && this.facRef[prev].current)
				this.facRef[prev].current.classList.remove('curr');

			this.cnt ++;
			if (this.cnt > Math.max(this.facRef.length, 100))
				this.cnt = 0;

			if (this.facRef[this.cnt] && this.facRef[this.cnt].current)
				this.facRef[this.cnt].current.classList.add('curr');
		}, 50);
	}

	componentWillUnmount() {
		clearTimeout(this.ti_query);
		clearInterval(this.ti_facRef);
	}

	getData(delay) {
		delay = delay || this.req_interval || 1000;
		clearTimeout(this.ti_query);

		this.ti_query = setTimeout(() => {
			connector.do_query({cmd:'get_teachers', uz_id:this.uz.id},
					this.receiveData);
		}, delay);
	};

	receiveData = (data_msg, data) => {
		this.teachers = data;
		this.teacher_msg = data_msg;

		if (this.t_id)
			this.t = this.teachers 
								&& this.teachers.length 
								&& this.teachers.find((t1) => t1.id === this.t_id)
							|| null;

		if (this.teachers && this.teachers.length )
			this.teachers.forEach((t1) => {
				t1.name_short = func.getShortFIO(t1.name);
			});

//		console.log('receiveData', data_msg);

		this.setState({
			teachers		: this.teachers,
			teacher_msg	: data_msg,
		});

		this.getData();
	};

	key_click = (c1) => {
		// let k = this.filter.indexOf(c1);
		// if (k >= 0)
		// 	this.filter.splice(k, 1)
		// else
		// 	this.filter.push(c1);

		if (this.filter[0] === c1)		// по 1й букве
			this.filter = [];
		else
			this.filter = [c1];

		this.setState({
			filter	: this.filter,
		});
	}

	teacher_click(t1) {
		this.t_id = t1? t1.id : undefined
		this.t = t1

		this.setState({
			t_id: this.t_id
		});
	}

	render() {
		this.t_id = this.props.teacher_id;
		if (!this.t_id) 
			this.t = undefined;
		else
			this.t = this.teachers && this.teachers.find((t1) => t1.id === this.t_id);

		let msg	= this.sched_msg;
		let chain_height = this.t? 'auto': 0;

		let sym = ' ' + (this.uz && this.uz.UI && this.uz.UI['link-symbol'] || '❯') + ' ';
		let docTile = this.uz.name + sym + (this.t? this.t.name: func.Words('Teachers'))
		document.title = docTile;

		if (this.props.updateManifest)
			this.props.updateManifest(docTile, window.location.href);

		// кнопки навигации для клавы
		document.documentElement.style.setProperty(`--nav-bottom`, 
									!this.t_id &&	window.innerWidth <= 430? '160px': '0vh');		
			
								
		const SelectTeacher = (props) => {
			this.facRef = [];
			if (!this.teachers || this.teachers.length === 0) return (
				<div className='Select'>
					<div className='menu_cont'>
						{this.state.teacher_msg || 'Список пуст'}
					</div>
				</div>
			)

			this.cnt = 0;//this.teacher.length;

			let last_letter = '';
			let cls = 'foo';

			return (
				<div className='Teacher_Cont_Cont'>
					<div className='Teacher_Cont'>
						{this.teachers.map((t1, idx) => {
							let c1 = t1.name_short.substr(0, 1);
							if (this.filter.length > 0 && this.filter.indexOf(c1) < 0)
								return null;

							cls = '';
							if ((!last_letter || c1 !== last_letter)) {
								cls = cls? '': 'new_letter';
								last_letter = c1;
							}
							
							this.cnt ++;
							this.facRef[this.cnt - 1] = React.createRef();

							return (<Link to = {`${this.root}${this.uz.url}/teacher/${t1.id}`} key={idx}>
											<div	className={'teacher'}
														// + cls
														ref={this.facRef[this.cnt - 1]} 
														idx={idx}
														onClick={this.teacher_click.bind(this, t1)}
											>
												{!cls? t1.name_short
													: <>
															<span className={'teacher_new_letter'}>
																{t1.name_short.substr(0, 1)}
															</span>
															{t1.name_short.substr(1)}
														</>}
											</div>
											</Link>)
						})}
					</div>
				</div>
			)
		} // SelectTeacher

		const Keyboard = (props) => {
			if (this.t_id) return null;

			const Key = (props) => {
				let enabled = this.teachers && this.teachers.find((t1) => t1.name.substr(0, 1) === props.c1);

				return <div key={props.idx} idx={props.idx}
										className={'Button_Filter KeySuka '
											+ (this.filter.indexOf(props.c1) >= 0? ' selected': '')
											+ (!enabled? ' disabled': '')
										}
										onClick={!enabled? null: this.key_click.bind(this, props.c1)}
								>
									{props.c1}
								</div>
			}

			return (
				<div className='Keys_Cont'>
					<div className='Key_Row Filter_Kurs'>
					    {['Ә','І','Ң','Ғ',',','.','Ү','Ұ','Қ','Ө','Һ'].map((c1, idx) => 
							<Key c1={c1} idx={idx} key={idx} />)}
					</div>
					<div className='Key_Row Filter_Kurs'>
						{['Й','Ц','У','К','Е','Н','Г','Ш','Щ','З','Х','Ъ'].map((c1, idx) => 
							<Key c1={c1} idx={idx} key={idx} />)}
					</div>
					<div className='Key_Row Filter_Kurs'>
						{['Ф','Ы','В','А','П','Р','О','Л','Д','Ж','Э'].map((c1, idx) => 
							<Key c1={c1} idx={idx} key={idx} />)}
					</div>
					<div className='Key_Row Filter_Kurs'>
						{['Я','Ч','С','М','И','Т','Ь','Б','Ю','.'].map((c1, idx) => 
							<Key c1={c1} idx={idx} key={idx} />)}
					</div>
				</div>
			)
		}

		const Chain = (props) => {
			// if (!canSelectGroup) return null;
			if (!this.t) return null;

			const CL = (props) =>
				// <Tooltip title={props.title || ''} disableFocusListener interactive
				// 					enterDelay={500} leaveDelay={200}>
					<Link to={`${this.root}${this.uz.url}/teacher`}>
						<div className='Chain_Link' onClick={props.onClick}
						>{props.children}</div>
					</Link>
				// </Tooltip>

			return (
				<div className={'Chain' + (!this.uz.level? ' School': '')}>
					<CL	onClick={() => this.teacher_click(undefined)}
							title={this.t.name_full}>
						<strong>{this.t.name_full || this.t.name}</strong>
					</CL>
				</div>
			)
		} // Chain

		if (!this.uz)
			return null
		else 
		return (<div className='Select_cont'>
			{ this.t
				?	<>
						{/* in costruzione... */}
							<AnimateHeight 
									easing='ease-out'
									id={'Filter_Cont'}
									duration={ 250 }
									height={ chain_height }
							>
								<Chain />
							</AnimateHeight>

							<div className='Select'>
								<Schedule_T 
									root={this.props.root}
									uz={this.uz}
									innerWidth={window.innerWidth}
									teacher={this.t}
								/>
							</div>
					</>
				: <>
						{this.teachers
						? <>
								<SelectTeacher />
							</>
						: this.state.teacher_msg
							? <div className='Teacher_Cont_Cont'>
									<div className='Teacher_Cont'>
										{this.state.teacher_msg}
									</div>
								</div>
							:	<div className='img_loading'>
									<CircularProgress color='inherit' size='5rem'/>
								</div>
						}
						<Keyboard />
					</>
			}
			</div>)
	} // render
} // Teachers

export default Teachers;