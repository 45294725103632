import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import './Odmin.js';
import {UserProfile, currSel} from './Odmin.js';
import connector from './connector.js';
import {keys, func} from './func.js';
import './EnterLess.css';
import { queryAllByAltText } from '@testing-library/dom';

const _op_Add		= 1;
const _op_Edit	= 2;
const _op_Del		= 3;

class EnterSprav extends Component {

	constructor(props) {
		super(props);
		this.uz = this.props.uz;
		this.odmin_mode	= UserProfile.getOdminMode(this.uz && this.uz.url);

		this.state = {
			focus_name : undefined,
			columns		 : props.columns
		}

		// this.uz.subj, this.uz.teacher и остальные, кто "объекты",
		// при их создании на серваке, сортируются по индексам,
		// и пофиг что запрос был "order by name"
		// такчто здесь сортируем по алфавиту
		// if (this.odmin_mode) {
		// 	this.uz.subj_arr = Object.entries(this.uz.subj).sort(
		// 													(a, b) => a[1].name < b[1].name? -1: 1);
		// 	this.uz.teacher_arr = Object.entries(this.uz.teacher).sort(
		// 													(a, b) => a[1].name < b[1].name? -1: 1);
		// 	this.uz.less_type_arr = this.uz.less_type &&
		// 													Object.entries(this.uz.less_type).sort(
		// 													(a, b) => a[1].name < b[1].name? -1: 1);
		// 	this.uz.room_arr = this.uz.room &&
		// 													Object.entries(this.uz.room).sort(
		// 													(a, b) => a[1].name < b[1].name? -1: 1);
		// }
	} // constructor

	componentDidMount() {
		let columns = this.state.columns;

		if (this.props.oper === _op_Add) {
			columns.forEach((c1) => c1.value = null);
		}

		columns.forEach((col) => {
			let value = null;

			if (this.props.oper === _op_Edit && this.props.row) {
				value = this.props.row[col.name];
				// вот это изза времени в ленте
				// туда-суда оно не очень пролазиет
				if (value && col.format)
					value = col.format(value);
			}

			if (this.props.oper === _op_Add) {
				if (col.default)
					value = col.default;
			}
			
			col.value = value;
		});

		this.setState({
			columns : columns
		});
	}

	componentDidUpdate() {
	}

	form_Click(e) {
		e.stopPropagation();
	}

	form_KeyDown(e) {
		// console.log('form_KeyDown', e.target.name);
	
		if (e.key == 'Delete' && e.target.type == 'select-one') {
			e.stopPropagation();
			e.preventDefault();
			let col = this.state.columns.find((c1) => c1.name === e.target.name);
			col.value = null;
			this.setState((state) => ({
				focus_name	: e.target.name,
			}));
		}

		if (e.keyCode == 13) {
			if (e.target.name === 'text') return;
			e.stopPropagation();
			e.preventDefault();
			this.saveSprav();
		}

		if (e.keyCode === 27) {
			e.stopPropagation();
			e.preventDefault();
			this.back_Click(0);
		}
	}

	back_Click(e) {
		e && e.stopPropagation();
		
		this.props.callback_Enter(false);
	}

	field_Changed(e) {
		let columns = this.state.columns;
		let col = columns.find((c1) => c1.name === e.target.name);

		if (e.target.type === 'checkbox')
			col.value = e.target.checked + 0;
		else
		if (e.target.type === 'text' 
				|| e.target.type === 'password'
				|| e.target.type === 'textarea')
			col.value = e.target.value || null;
		else
			col.value = +e.target.value || null;

		this.setState({
			columns 		: columns,
			focus_name	: e.target.name,
		});
	}

	saveSprav() {
		// сохранение
		this.props.callback_Enter(true);
	}

	b_SaveClick(e) {
		e.stopPropagation();
		this.saveSprav();
	}

	b_ClearClick(e) {
		let columns = this.state.columns;
		columns.forEach((c1) => {if (!c1.password) c1.value = null});
		this.setState({
			columns 		: columns,
			focus_name	: undefined
		});
	}

	render() {

		const Btn = (btn) => {
			return (<div 
									{...btn}
									className={'op_button ' + btn.className}
							>
								{btn.caption || btn.children}
							</div>)
		}

		let itemCount = 0;
		this.state.columns.forEach((col) => {
			if (col.name
					&& col.name.indexOf('_b64') < 0
					&& col.name.indexOf('_name') < 0) 
			{
				itemCount ++;

				if ((col.label || '').length >= 30) 
					itemCount += 0.5;

				let isLongName =(col.label || '').indexOf(' ') >= 0 && (col.label || '').length >= 15;
				let needTextarea = !col.forceInput
														&& (col.name.indexOf('_full') >= 0 
																|| col.name === 'text' 
																|| col.name === 'addr'
																|| col.textarea
																|| col.maxlen > 200);
				if (needTextarea) 
					itemCount += 1.65;

				if ((col.name === 'text' || col.maxlen > 200) && !col.forceInput)
					itemCount += 2.4;
			}
		});

		let focused = false;
		this.columns = this.state.columns;

		return (
			<Backdrop open={true} onClick={this.back_Click.bind(this)} 
								style={{zIndex:'999'}}>
				<div className='pic_full enter' tabIndex={-1} id='theDiv'
							style={{height:(itemCount*2.3 + 5)+'em'}}
							onClick={this.form_Click.bind(this)}
							onKeyDown={this.form_KeyDown.bind(this)}
							>
					<div className='pic_full_name enter_header_left'>
						{window.innerWidth >= 320 &&
							<span className='enter_header_span_L'>
								{func.Words(`op_${this.props.oper === _op_Add? 'Add': 'Edit'}`)}
								&nbsp;:&nbsp;
								{this.props.table.name}
							</span>
						}
						<span className='enter_header_span'>
						</span>
					</div>

					<div className='enter_body'>
					<form>
						{this.state.columns.map((col, idx) => {										// ПОЛЯ
							if (col.name.indexOf('_b64') >= 0) return;
							if (col.name.indexOf('_name') >= 0) return;

							let af = (!this.state.focus_name 
												|| this.state.focus_name === col.name);
							if (focused) af = false;
							if (af) focused = true;

							let value = col.value;// || '';		иначе 0 как пусто был
							if (value !== 0)
								value = value || '';

							// там очень сокращённо название "кг", "кч"...
							// поэтому добавим там ниже полное
							let withFullName = col.name === 'less_type_id'; 

							let arr;
							if (col.name.indexOf('_id') >= 0) {
								let arr_name = col.name.split('_id')[0] + '_arr';
								arr = this.props.uz[arr_name];
							}

							let needTextarea = !col.forceInput
																	&& (col.name.indexOf('_full') >= 0 
																			|| col.name === 'text' 
																			|| col.name === 'addr'
																			|| col.textarea
																			|| col.maxlen > 200);

							return (<div key={idx} className='enter_item'>
								<div className={'enter_item_label sprav' 
																		+ (needTextarea? ' l_up': '') 
																		+ (col.cb? ' l_up1': '') 
																		// + (isLongName && !needTextarea? ' l_up': '')
																	}
											title={(col.req? 'Заполнять обязательно': '')}>
									<span htmlFor={col.name} 
													className={'text_label' + (col.req? ' required': '')
																			+ (col.name.indexOf('_id') >= 0? ' _id': '')}>
										{col.label}
									</span>
								</div>
								{col.name.indexOf('_id') >= 0
									? <select className={'enter_item_field sprav'
																				+ (col.minWidth < 100 && !withFullName? ' width_30': '')}
														name={col.name}
														autoFocus={af}
														value={value}
														onChange={this.field_Changed.bind(this)}>
											{!col.req && <option key={0} value={0}></option>}
											{arr && arr.map((a1, idx) => {
												// все остальные кроме uz_arr, они типа массив массивов [[id, {всё остальное}], []...]
												// а uz_arr делается руками, в OdminPage receiveData()... и он просто массив [{}, {}...]
												if (Array.isArray(a1))
													return (<option key={idx} value={a1[0]}>
																		{a1[1].name + (withFullName && a1[1].name_full? 
																											` (${a1[1].name_full})`: '')}
																	</option>)
												else
													return (<option key={idx} value={a1.id}>
																		{a1.name_full}
																	</option>)
											})}
										</select>
									: col.cb
									?	<div className='enter_item_field2 remote2'>
											<input className='checkbox' type="checkbox" id={col.name} name={col.name}
														checked={value}
														onChange={this.field_Changed.bind(this)} />
										</div>
									: needTextarea
									?	<textarea className={'enter_item_field sprav '
																	+ (col.minWidth < 100? ' width_30': '')
																	+ ((col.name === 'text' || col.maxlen > 200)? ' text': '')
																	+ (col.code? ' code': '')
																	+ (col.req && !value && value !== 0? ' required': '')} 
											type="text" id={col.name} name={col.name}
											// spellCheck={col.spellcheck === false? false: true}
											rows={(col.name === 'text' || col.maxlen > 200) && !col.forceInput? 9: 3}
											value={value}
											autoFocus={af}
											maxLength={col.maxlen? col.maxlen: 45}
											onChange={this.field_Changed.bind(this)}/>
									:	<input className={'enter_item_field sprav '
																	+ (col.minWidth < 100? ' width_30': '')
																	+ (col.req && !value && value !== 0? ' required': '')} 
											type={col.password? 'password'
														: col.int? 'number': 'text'} id={col.name} name={col.name}
											// spellCheck={col.spellcheck === false? false: true}
											spellCheck='false'
											maxLength={col.maxlen? col.maxlen: 6} // 6 - типа int
											value={value}
											autoFocus={af}
											disabled={col.password && !col.canEdit && this.props.oper === _op_Edit}
											onChange={this.field_Changed.bind(this)}/>}
									{col.password && this.props.oper === _op_Edit
										? <Btn title='Изменить пароль' disabled={col.canEdit}
													onClick={() => {
														col.canEdit = true; 
														col.value = '';
														this.setState({
															columns 		: this.columns,
															focus_name	: col.name,
														});
													}}
											>{func.Words('op_Edit')}</Btn>
										: null
									}
							</div>)
						})}
					</form>
					</div>

					<div className='enter_footer'>
						<div className='op_button_cont2'>
							{window.innerWidth >= 500 &&
								<Btn className='enter_btn_left'  caption='Очистить' onClick={this.b_ClearClick.bind(this)}/>}
							{window.innerWidth >= 320 &&
								<Btn className='enter_btn_right' caption='Отмена' onClick={this.back_Click.bind(this)}/>}
							<Btn className='enter_btn_right' caption='Сохранить' onClick={this.b_SaveClick.bind(this)}/>
						</div>
					</div>
				</div>
			</Backdrop>
		)
	}
}

export default EnterSprav;
