import { Component } from 'react';
import { withRouter } from "react-router-dom";
import {Link} from "react-router-dom";
import {keys, func} from './func.js';
import connector from './connector.js';

import './Odmin.css';

const _op_Add		= 1;
const _op_Edit	= 2;
const _op_Del		= 3;

const currSel = {
	less: {},
	cancelOper: (full, operSuccess) => {
		// console.log('cancelOper', currSel);
		currSel.oper = 0;							// закрыли EnterLess
		currSel.operSuccess = operSuccess;

		currSel.prev_lessN = null;
		currSel.prev_dayN = 0;

		currSel.prev_less = null;

		currSel.less_enter = {};
		if (currSel.less)
			for (let key in currSel.less)
				currSel.less_enter[key] = currSel.less[key];

		currSel.prev_less = null;

		if (full) {
			currSel.oper = 0;
			currSel.lessN = null;
			currSel.less = null;
			currSel.less_enter = {};
		}
	}
}

let lang_table;
let LT;

if (!LT) {
	const receiveData = (msg, data) => {
		lang_table = data;
		UserProfile.prepareLangTable();
	}
	connector.do_query({cmd:'get_lang_table'}, receiveData);
}


const UserProfile = (function() {

	const prepareLangTable = () => {
		let obj = {};
		let lang = UserProfile.getLang();
		
		lang_table.map((lt1, idx) => {																//	{{} {} {}}
			obj[lt1.word_key] = {};
			obj[lt1.word_key] = lt1[lang];
			if (lt1.parse)
				obj[lt1.word_key] = JSON.parse(obj[lt1.word_key]);
		});

		LT = obj;

		if (LT.main_menu && LT.main_caption) { 
			LT.main_menu.splice(0, 0, LT.main_caption);
		}
	}

	const getLang = () => {
		return localStorage.getItem('lang') || 'kaz';
	}

	const setLang = (lang) => {
		localStorage.setItem('lang', lang);
		UserProfile.prepareLangTable();
	}

  const getUser = () => {
		try {
			return JSON.parse(localStorage.getItem('user'));
		} catch (error) {
			return undefined
		}
	}

  const getToken = () => {
		try {
			let tok = JSON.parse(localStorage.getItem('user')).token;
			return tok.slice(8) + tok.slice(0, 8);
		} catch (error) {
			return undefined
		}
	}

  const setUser = (the_user) => {
		if (the_user && the_user.token)
			the_user.token = the_user.token.slice(8) + the_user.token.slice(0, 8);
		localStorage.setItem('user', JSON.stringify(the_user));
  }

	const getOdminMode = (uz_url) => {
		let user = getUser();
		return uz_url 
						&& user 
						&& (uz_url === user.uz_url
								|| user.isRoot)
						// && window.innerWidth > 1000;
	}

  return {
    getUser:	getUser,
		getToken:	getToken,
    setUser:	setUser,
    getLang:	getLang,
    setLang:	setLang,
		prepareLangTable: prepareLangTable,
		getOdminMode: getOdminMode
  }

})();

class TOdminPanel extends Component {
	constructor(props) {
		super(props);
		// this.state = {
		// 	oper	: -1,
		// }
	}

	buttons = [
		{caption: 'Добавить',	oper: _op_Add,	align: 'left', title: 'Добавить занятие\nв выделенный урок (Ins)' }, 
		{caption: 'Изменить',	oper: _op_Edit,	align: 'left', title: 'Изменить выделенное занятие (F2)' }, 
		{caption: 'Удалить',	oper: _op_Del,	align: 'left', title: 'Удалить выделенное занятие...' }, 

		{caption: 'kestesi.kz', href: 'http://kestesi.kz', align: 'right', title: 'Перейти на сайт' }, 
		{caption: 'Выход',		onClick: ()=>{UserProfile.setUser()}, 
													href: this.props.uz_url? this.props.root + this.props.uz_url: undefined, 
													align: 'right', title: 'Выйти из кабинета' }, 
		{caption: 'Список УЗ', href: 'http://' + window.location.host + '/select', forceA:true,
													// style: {marginLeft:'1em'},
													align: 'right', title: 'К списку учебных заведений', root:true }, 
		{caption: 'Справочники', onClick: ()=>{currSel.oper = 0; currSel.lessN = null; this.forceUpdate()},
													href: this.props.root + this.props.uz_url + '/admin',
													align: 'right', title: 'Заполнение справочников' }, 
		{caption: 'Расписание', //onClick: ()=>{this.props.history.replace(this.props.root + this.props.uz_url)},
													href: this.props.root + this.props.uz_url + '/schedule', forceA:true,
													align: 'right', title: 'Заполнение расписания' }, 
	];


	componentDidMount() {
		this.ti_changed_Less = setInterval(() => {
			if (this.selLessN != currSel.lessN 
					|| this.selDayN != currSel.dayN
					|| this.prev_less != currSel.prev_less) {
				this.prev_less = currSel.prev_less;
				this.selLessN = currSel.lessN;
				this.selDayN = currSel.dayN;
				this.forceUpdate();
			}
		}, 100);
	}

	componentWillUnmount() {
		clearInterval(this.ti_changed_Less);
	}

	delCallback() {
		// тут ещё передаём success, чтобы расписание обновилось
		currSel.cancelOper(true, true);
	}

	delLess() {
		if (!currSel.less || !currSel.less.id) return;
		if (!window.confirm('Удалить выделенный предмет?')) return;
		console.log('del', currSel.less.id);

		connector.do_commit({ cmd:			'lesson_del', 
													less_id:	currSel.less.id, 
													token:		UserProfile.getToken()}, this.delCallback);
	}

	handle_ClickOper = (oper) => {
		currSel.oper = oper;

		if (oper == _op_Del)
			this.delLess();
	}

	render() {
		if (!UserProfile.getOdminMode(this.props.uz_url)) {
			document.documentElement.style.setProperty(`--odmin`, '0');
			return null;
		}

		document.documentElement.style.setProperty(`--odmin`, '1s');

		let width = window.innerWidth;

		const Btn = (btn) => {
			return (<div className={'op_button op_' + btn.align}
										style={btn.style}
															// (btn.oper === this.state.oper? ' selected': '')
										// title={btn.oper === this.state.oper? 'отменить': ''}
										title={btn.title || ''}
										onClick={ btn.onClick || this.handle_ClickOper.bind(this, btn.oper) }
										// onClick={ btn.href? null: btn.onClick || this.handle_ClickOper.bind(this, btn.oper) }
										// onClick={ btn.onClick || null }
							>
								{btn.caption}
							</div>)
		}

		this.buttons[1].caption = 
			(currSel.lessN >= 0 && !currSel.less && currSel.prev_less? 
			'Переместить': 'Изменить');

		let user = UserProfile.getUser();
		if (!user)
				return null;

		return (
					<div className='OdminPanel'>
						{width > 1000?
							<div className='op_left'>
								{this.props.uz && this.props.uz.name || 'Кабинет'}{': '}
								{user.isRoot
									? user.name
									: func.getShortFIO(user.name)}
							</div> : null}
													
						<div className='op_button_cont op_left'>
							
							{ this.buttons.map((b1, idx) => {
									if (idx <= 2 && !currSel.lessN && currSel.lessN !== 0) return null;
									if (idx === this.buttons.length - 2 && this.props.odmin) return null;
									if (idx === this.buttons.length - 1 && !this.props.odmin) return null;
									if (b1.root == true && !user.isRoot) return null;
									return	(b1.href?
														b1.href.indexOf('http://')>=0 || b1.forceA?
															<a href={b1.href} key={idx}><Btn {...b1}/></a>
															:<Link to={b1.href} key={idx}><Btn {...b1}/></Link>
														: <Btn {...b1} key={idx}/>)
								})}
						</div>
						&nbsp;
					</div>)
	}
}

const OdminPanel = withRouter(TOdminPanel);
export {UserProfile, OdminPanel, currSel, LT}
