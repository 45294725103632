import { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import connector from './connector.js';
import {keys, func} from './func.js';
import './OdminPage.css';
import DataTable from './DataTable.js';
import { UserProfile } from './Odmin.js';


let tables = [
	// список УЗ обязательно должен быть первым
	// из него мы получаем uz.arr_uz для списка пользователей (вот так заморочено)
	// ну или ииначе надо делать чтоб в load_uz к uz Добавлялся uz_list.
	{name: <span style={{textAlign:'right'}}>Учебные заведения</span>,
		hint: `Список учебных заведений системы <br/>
						В столбце ссылка - реально ссылки на соотв. УЗ`, 
		b64_top:'-3.5em', b64_right:'16px', b64_height:'3em',
		tableName: 'uz',		cmd:'get_uz_list', orderBy: 'name_full', root: true},

	{name: 'Пользователи',
		hint: `Список пользователей системы<br/>
						В кабинет могут входить только "активные" пользователи`,
		tableName: 'users',		cmd:'get_users', orderBy: 'uz_name', root: true},

	{name: '-', root: true},	// разделитель. дальше таблицы видимые всем юзерам

	{name: 'Лента', 					b64_top:'1.75em', b64_right:'1em', b64_width:'22.5%',
		hint: `Список изображений "ленты". Номер определяет положение сверху.<br/>
					Записи без номера или изображения в ленту не включаются.`,
		tableName: 'lenta',		masterTables: ['uz'],		cmd:'get_lenta', orderBy: 'num'},

	{name: 'События',
		hint: `События отображаются в бегущей строке внизу экрана<br>
					Формат даты: "ГГГГ-ММ-ДД ЧЧ:ММ", время указывать не обязательно`,
		tableName: 'events',	masterTables: ['uz'],		cmd:'get_events'},

	{name: 'Факультеты',
		hint: `Список факультетов ВУЗа`,
		tableName: 'fac',			masterTables: ['uz'], 	is_high: true},

	{name: 'Уровни',
		hint: `Для ВУЗов: магистратура, аспирантура и т.д. Номер - положение кнопки фильтра.<br>
					Для школ: возможно использование как переключатель смен. Номер соответствует номеру смены.`,
		tableName: 'level',		masterTables: ['uz'], orderBy: 'num', cmd:'get_level'},//, 	is_high: true

	{name: 'Корпусы',
		hint: `Учебные, лабораторные и т.п. корпусы учебного заведения.<br>
					Если учебное заведение целиком расположено в одном корпусе, его можно не указывать.<br>
					Адрес указывается в случае, если корпус отделён территориально.`,
		tableName: 'build',		masterTables: ['uz'], 	cmd:'get_build', 	is_high: true},

	{name: 'Аудитории',
		hint: `Кабинеты, спортзалы и прочие помещения, в которых проводятся занятия.<br>
						Можно указывать только корпус.`,
		tableName: 'room',		masterTables: ['uz', 'build'], orderBy: 'name', cmd:'get_room'},

	{name: 'Преподаватели',
		hint: '-',
		tableName: 'teacher',	masterTables: ['uz', 'fac'], orderBy: 'name', cmd:'get_teachers'},

	{name: 'Типы занятий',
		hint: `Типы занятий используются для визуального выделения определённых предметов в расписании.<br>
					Например: "л" - лекция, "пр" - практическое занятие, "кч" - классный час`,
		tableName: 'less_type',	masterTables: ['uz'], orderBy: 'name', cmd:'get_less_type'},

	{name: 'Предметы',
		hint: `Полное наименование заполняется в случае, если оно не умещается в кратком наименовании.<br>
					Если предмет обычно относится к определённому типу занятия, его можно указать здесь`,
		tableName: 'subj',	masterTables: ['uz', 'fac', 'less_type'], orderBy: 'name', cmd:'get_subj'},

	{name: 'Звонки',
		hint: `У разных классов могут быть разные планы звонков.<br>
					Номер урока и время первой смены указываются обязательно.`,
		tableName: 'bell',	masterTables: ['uz'], orderBy: 'num, lessN', cmd:'get_bell'},

	{name: 'Классы',
		hint: `Для класса "2Б": указывается номер - "2", буква - "Б", и т.д.`,
		tableName: 'group',	masterTables: ['uz'], orderBy: 'kurs, name', cmd:'get_group'},
]

const table_fields = {lenta: ['uz_id', 'num', 'name', 'pic_b64']}

const table_columns = {
	uz: 	[{ name: 'url', 			label: 'Cсылка',		minWidth: 50, maxlen:45 },
					{	name: 'is_high',	label: 'ВУЗ',		align: 'center',	minWidth: 10,	int:true, cb:true },
					{ name: 'name', 		label: 'Краткое',		minWidth: 50, maxlen:15 },
					{ name: 'name_full',label: `Полное\nнаименование`,			minWidth: 100, maxlen:200, forceInput: true },
					{ name: 'addr',			label: 'Адрес',	minWidth: 100, maxlen:100, forceInput: true, visible:false },
					{ name: 'UI',				label: 'Интерфейс',	minWidth: 100, maxlen:1000, visible:false, code:true},
					{	name: 'logo_b64',	label: 'Логотип',		minWidth: 27 },
					{	name: 'pics_id',	label: 'Фоновые изображения',	minWidth: 27, visible:false },
					{	name: 'pics_name',label: 'Фоны', hint:'Фоновые изображения', minWidth: 27 },
				],

	users: [{ name: 'name', 		label: 'ФИО',			minWidth: 100, maxlen:45 },
					{ name: 'login',		label: 'Логин', 	minWidth: 100, maxlen:45, req:true },
					{ name: 'pass',			label: 'Пароль', 	minWidth: 50, maxlen:45, visible:false, req:true, password:true },
					{ name: 'uz_id',		label: 'Учебное заведение', minWidth: 100, req:true, int:true, visible:false },
					{ name: 'uz_name',	label: 'Учебное заведение',	minWidth: 100, 
						ext_table: 'uz', ext_field: 'name' },
					{	name: 'active',		label: 'Активен', minWidth: 10,	align: 'center', int:true, cb:true, default:1 }
				],

	lenta: [{	name: 'num', 				label: 'Номер',			 					minWidth: 40,		align: 'center', int:true },
					{	name: 'name',				label: 'Описание изображения',minWidth: 170,	req:true, maxlen:100, textarea:true },
					{	name: 'pic_b64',		label: 'Изображение',					minWidth: 270 },
				],

	events: [{	name: 'date', 		label: 'Дата,\u00a0время', 		minWidth: 120, req:true, maxlen:20,
							format: (value) => func.localTime(new Date(value), false, true) },
						{	name: 'text',			label: 'Текст', 							minWidth: 170, req:true, maxlen:500, },
						{	name: 'active',		label: 'Активно',							minWidth: 70,		align: 'center', int:true, cb:true }
					],

	fac: [{	name: 'name', 				label: 'Аббревиатура',			 	minWidth: 70, maxlen:10  },
					{	name: 'name_full',	label: 'Полное наименование', minWidth: 170, maxlen:50 },
				],
	level: [{	name: 'name', 			label: 'Наименование',				minWidth: 70, maxlen:10 },
					{	name: 'name_full',	label: 'Полное наименование', minWidth: 170, req:true, maxlen:45 },
					{	name: 'num',				label: 'Номер', 							minWidth: 40, req:true,	align: 'center', int:true },
				],

	build: [{	name: 'name', 			label: 'Наименование', 				minWidth: 70, maxlen:25 },
					{	name: 'name_full',	label: 'Полное наименование', minWidth: 170, maxlen:145 },
					{	name: 'addr', 			label: 'Адрес', 							minWidth: 170, maxlen:145 },
					{	name: 'ext', 				label: 'Отдельное',						minWidth: 70, align: 'center', int:true, cb:true }
				],
	room: [	{ name: 'name',				label: 'Номер или наименование',	minWidth: 70,		orderLikeNumbers: true, maxlen:15 },
					{ name: 'floor',			label: 'Этаж',										minWidth: 70,		align: 'center', int:true	},
					{ name: 'name_full',	label: 'Полное наименование',			minWidth: 170, maxlen:45	},
					// { name: 'build_id',		label: 'Корпус',									minWidth: 70,		visible:false, int:true	},
					// { name: 'build_name',	label: 'Корпус',									minWidth: 170,	ext_table: 'build', ext_field: 'name'	},
				],

	teacher: [{	name: 'name', 			label: 'Фамилия И.О.',				minWidth: 100, req:true, spellcheck:false, maxlen:45 },
					  { name: 'name_full',	label: 'ФИО полностью', 			minWidth: 170, maxlen:100 },
				    { name: 'fac_name',		label: 'Факультет',						minWidth: 70,		is_high: true,
							ext_table: 'fac', 	ext_field: 'name' },
					],

	less_type: [{ name: 'name', 		label: 'Наименование',				minWidth: 70, align: 'center', maxlen:10 },
							{ name: 'name_full',label: 'Полное наименование', minWidth: 170, maxlen:45 },
							{ name: 'color',		label: 'Цвет (#rrggbb)',			minWidth: 70, align: 'center', maxlen:20 },
				],

	subj: [{ name: 'name', 						label: 'Наименование',				minWidth: 100, req:true, maxlen:35 },
					{ name: 'name_full',			label: 'Полное наименование', minWidth: 170, maxlen:100 },
					{ name: 'less_type_id',		label: 'Тип', 								minWidth: 70, align: 'center', int:true, visible:false },
					{ name: 'less_type_name',	label: 'Тип', 								minWidth: 70, align: 'center',
						ext_table: 'less_type', ext_field: 'name' },
				],

	bell: [{ name: 'num', 					label: 'План №',							minWidth: 70, align: 'center', int:true, maxlen:2 },
			 	 { name: 'lessN',					label: 'Урок №', 							minWidth: 70, align: 'center', int:true, req:true, maxlen:2 },
				 { name: 'time11',				label: 'Смена 1, Начало', 		minWidth: 70, align: 'center', req:true, maxlen:5,
				 		format: (v) => v.split(':')[0]+':'+v.split(':')[1] },
				 { name: 'time12',				label: 'Смена 1, Конец',	 		minWidth: 70, align: 'center', req:true, maxlen:5,
				 		format: (v) => v.split(':')[0]+':'+v.split(':')[1] },
				 { name: 'time21',				label: 'Смена 2, Начало', 		minWidth: 70, align: 'center', maxlen:5,
						format: (v) => v.split(':')[0]+':'+v.split(':')[1] },
				 { name: 'time22',				label: 'Смена 2, Конец',	 		minWidth: 70, align: 'center', maxlen:5,
				 		format: (v) => v.split(':')[0]+':'+v.split(':')[1] },
				],

	group: [{ name: 'kurs', 				label: 'Номер',								minWidth: 70, align: 'center', int:true, maxlen:2 },
			 	 { name: 'name',					label: 'Буква', 							minWidth: 70, align: 'center', maxlen:45 },
				 { name: 'room_name',			label: 'Кабинет', 						minWidth: 70, align: 'center' },
				 { name: 'teacher_name',	label: 'Классный руководитель',minWidth: 170 },
				 	{ name: 'room_id',			label: 'Кабинет', 						 minWidth: 70, align: 'center', visible:false, int:true },
				 	{ name: 'teacher_id',		label: 'Классный руководитель',minWidth: 170, visible:false, int:true},
				 { name: 'bell_num',			label: 'План звонков',				minWidth: 70, align: 'center', int:true, maxlen:2 },
				 { name: 'shiftN',				label: 'Смена',								minWidth: 70, align: 'center', int:true, maxlen:2 },
				],
}


function TablePanel(props) {
  const { children, caption, table, ...other } = props;

	let columns = table_columns[table.tableName] || [];

	// let rows = [];
	let rows_obj = props.uz[table.tableName];
	let rows = rows_obj;

	if (table.cmd && props.table_data)
		rows = props.table_data;
	else
	if (!Array.isArray(rows)) {
		// большинство таблиц на серваке преобразуются в _объект_ объектов
		// а для таблицы нужен массив объектов. мутим в массив:
		// console.log(rows_obj)
		rows = [];
		let idx = -1;
		for (let id in rows_obj) {
			idx ++;
			// rows[key] = obj[key];
			// rows[key].id = +key;
			rows.push(rows_obj[id]);
			if (!rows[idx].id)
				rows[idx].id = +id;

			columns.forEach(col => {
				if (!col || !col.ext_table) return;
				let ext_id = rows[idx][col.ext_table + '_id'];
				let ext_row = props.uz[col.ext_table] && props.uz[col.ext_table][ext_id];
				if (ext_row)
					rows[idx][col.name] = ext_row[col.ext_field];
			});
		}
	} // !Array

	// console.log(rows);

	if (!props.uz.is_high)
		columns = columns.filter((col) => !col.is_high);

  return (
    <div className='TabPanel'
      role="tabpanel"
      // hidden={value !== index}
      id={`vertical-tabpanel-${table.tableName}`}
      aria-labelledby={`vertical-tab-${table.tableName}`}
      // {...other}		вот изза этого он ругался на callbackRefresh (неважно название)
    >
      <>
				{caption !== '-' &&
					<Box p={2} className='TabPanelHeader'>
						<Typography>{ReactHtmlParser(caption)}</Typography>
					</Box>}
        <Box p={2} className='TabPanelHeader'>
          <Typography>{'Кол-во записей: '}
						{props.loading? 'загрузка...': ReactHtmlParser('<b>'+rows.length+'</b>')}
					</Typography>
        </Box>
        <Box p={2}>
          <DataTable 
						uz = {props.uz}
						columns = {columns}
						rows = {rows}
						orderBy = {table.orderBy}
						table = {table}
						callbackRefresh = {props.callbackRefresh}
						// orderLikeNumbers = {table.orderLikeNumbers}
					/>
        </Box>
      </>
    </div>
  );
} // TablePanel

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };


class OdminPage extends Component {
	constructor(props) {
		super(props);
	
		this.uz = props.uz;

		// убираем ВУЗовские таблицы, если у нас школа
		if (this.uz && !this.uz.is_high)
			tables = tables.filter((table) => !table.is_high);

		// для обычных юзеров убираем рутовые таблицы
		if (!UserProfile.getUser().isRoot)
			tables = tables.filter((table) => !table.root);

		this.state = {
			table			: tables[0],
			tableData	: undefined,
			loading		: !!tables[0].cmd
		}
	}

	componentDidMount() {
		if (this.state.table.cmd) {
			let q = {cmd: this.state.table.cmd, uz_id: this.uz.id};
			if (this.state.table.root)
				q.token = UserProfile.getToken();
			connector.do_query(q, this.receiveData);
		}
	}

	handle_ChangeTab = (e, table) => {
		if (table.cmd) {
			let q = {cmd: table.cmd, uz_id: this.uz.id};
			if (table.root)
				q.token = UserProfile.getToken();
			connector.do_query(q, this.receiveData);
		}

    this.setState({
			table 		: table,
			tableData	: undefined,
			loading		: !!table.cmd
		});
  };

	receiveData = (data_msg, data) => {
		data = data || [];
		// console.log('receiveData', data, data_msg);
		if (this.state.table.tableName === 'uz') {
			this.uz.uz_arr = data.filter((d1) => d1.id !== 0);
		}

    this.setState({
			tableData	: data,
			loading		: false
		});
	}

	render() {
		let sym = ' ' + (this.uz && this.uz.UI && this.uz.UI['link-symbol'] || '❯') + ' ';
		let docTile = func._mainDocTitle + sym + 'admin';
		document.title = docTile;

		let table = this.state.table;

		const theme = createMuiTheme({
			palette: {
				primary: {
					main: this.uz && this.uz.UI && this.uz.UI.colors['med'] || '#666',
				},
				action: {
					selected: '#00f'
				}
			},
		});

		function a11yProps(index) {
			return {
				id: `vertical-tab-${index}`,
				'aria-controls': `vertical-tabpanel-${index}`,
				// 'align-items': "flex-start"
			};
		}
				
		return (
			<MuiThemeProvider theme={theme}>
			{/* <div style={{textAlign:'left', marginLeft:'0.5em'}}>Справочники со знаком "+" обновляются автоматически</div> */}
			{/* <div style={{textAlign:'left', marginLeft:'0.5em'}}>{func.Words('UnderConstruct')}</div> */}

			<div className='odmin_Page'>
				<div className='odmin_TabsCont'>
					<div className='odmin_TabsHeader'></div>
					<Tabs
						TabIndicatorProps={{
							style: {
								width:"4px",
							}
						}}
						orientation="vertical"
						variant="scrollable"
						value={table}
						onChange={this.handle_ChangeTab}
						// aria-label="Vertical tabs example"
						// aria-labelledby={true}
						className='odmin_Tabs'
						indicatorColor="primary"						
					>
						{tables.map((t1, idx) => {
							if (t1.name === '-')
								return <Tab key={idx} className='tab_divider' disabled/>
							else
								return (<Tab key={idx} value={t1} label={t1.name} {...a11yProps(t1.tableName)} />)
						})}
					</Tabs>
				</div>

				<TablePanel table={table} uz={this.uz} 
									table_data={this.state.loading? this.state.tableData : this.state.tableData || []}
									loading={this.state.loading? 1: 0}
									caption={table.hint || table.name} 
									callbackRefresh={() => this.handle_ChangeTab(0, table)}
									/>
			</div>
			</MuiThemeProvider>
		)
	}
}

export default OdminPage;