import {UserProfile, OdminPanel, LT} from './Odmin.js';

const func = {

	_mainDocTitle	: 'kestesi.kz',

	getShortFIO: function(FIO) {
		FIO = FIO + '';
		if (FIO.indexOf('.') < 0) {
			let a = FIO.split(' ');
			FIO = a[0] + (a[1]? ' '+a[1][0]+'.': '') + (a[2]? a[2][0]+'.': '');
		}
		return FIO;
	},

	Words : function(wordName) {
		const words = {
			Enter						: {kaz: 'Кіру', rus: 'Войти', eng: 'Enter'},
			Back						: {kaz: 'Артқа', rus: 'Назад', eng: 'Back'},
			Pass						: {kaz: 'Құпия сөз', rus: 'Пароль', eng: 'Password'},
			EMail						: {kaz: 'Электрондық пошта', rus: 'Электронная почта', eng: 'E-mail'},
			Login						: {kaz: 'Қолданушының аты', rus: 'Имя пользователя', eng: 'Username'},
			LoginCaption		: {kaz: 'Кабинетке кіріңіз', rus: 'Вход в кабинет', eng: 'Cabinet login'},
			MainCaption			: {kaz: 'Электрондық кестесi', rus: 'Электронное расписание', eng: 'Electronic timetable'},
			UnderConstruct	: {kaz: 'Бұл бөлім салынып жатыр...', 
													rus: 'Данный раздел находится в разработке...', 
													eng: 'This section is under construction ...'},
			Timetable				: {kaz: 'Кесте', rus: 'Расписание', eng: 'Timetable'},
			lesson					: {kaz: 'сабақ', rus: 'урок', eng: 'lesson'},
			op_Add					: {kaz: 'Қосу', rus: 'Добавить', eng: 'Add'},
			op_Edit					: {kaz: 'Өзгерту', rus: 'Изменить', eng: 'Edit'},
			op_Move					: {kaz: 'Жылжыту', rus: 'Переместить', eng: 'Move'},
			Teachers				: {kaz: 'Мұғалімдер', rus: 'Преподаватели', eng: 'Teachers'},
			remote 					: {kaz: 'қашықтан', rus: 'удалённо', eng: 'remote'},
			remote_learning	: {kaz: 'Қашықтықтан оқыту', rus: 'Удалённое обучение', eng: 'Remote learning'},
			diffSubjects		: {kaz: 'Әр түрлі пәндер!', rus: 'Разные предметы!', eng: 'Different subjects!'},
			group_boss			: {kaz: 'Сынып жетекшісі', rus: 'Классный руководитель', eng: 'Classroom teacher'},
			room_short			: {kaz: 'сын.', rus: 'каб.', eng: 'aud.'},
			no_teacher			: {kaz: 'мұғалім жоқ', rus: 'нет преподавателя', eng: 'no teacher'},
			no_subj					: {kaz: 'тақырыбы көрсетілмеген', rus: 'предмет не указан', eng: 'subject not specified'},
			// week_hours			: {kaz: 'Жалпы сағаттар', rus: 'Всего часов', eng: 'Total hours'},
			week_hours			: {kaz: 'Жалпы оқу сағаты', rus: 'Всего учебных часов', eng: 'Total teaching hours'},
			group						: {kaz: 'топ', rus: 'группа', eng: 'group'},
			gr							: {kaz: 'т.', rus: 'гр.', eng: 'gr.'},
		}
		return words[wordName] && words[wordName][UserProfile.getLang()] || wordName;
	},

	MonthR : function(n) { 
		const months = {
			kaz: ['қаңтар', 'ақпан', 'наурыз', 'сәуір', 'мамыр', 'маусым',
						'шілде', 'тамыз', 'қыркүйек', 'қазан', 'қараша', 'желтоқсан'],
			rus: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
						'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
			eng: ['january', 'february', 'march', 'april', 'may', 'june',
						'july', 'august', 'september', 'october', 'november', 'december'],
		}
		return months[UserProfile.getLang()][n];
	},

	DoW : function(n) { 
		if (isNaN(n)) return n;
		const dw = {
			kaz: ['Жексенбі','Дүйсенбі','Сейсенбі','Сәрсенбі','Бейсенбі','Жұма','Сенбі','Жексенбі'],
			rus: ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота','Воскресенье'],
			eng: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
		};
		return dw[UserProfile.getLang()][n] || n;
	},

	DoW_short : function(n) { 
		if (isNaN(n)) return n;
		const dw = {
			kaz: ['Же','Дү','Сй','Ср','Бе','Жұ','Сн','Же'],
			rus: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб','Вс'],
			eng: ['Su','Mo','Tu','We','Th','Fr','Sa','Su'],
		}
		return dw[UserProfile.getLang()][n] || n;
		// return ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'][n] || n;
	},

	groupBy : function(s, n) {
		if (n <= 0) return s;
		let i = 0;
		while (i < s.length - n) {
			i += n + 1;
			s = s.substr(0, i-1) + ' ' + s.substr(i-1);
		}
		return s;
	},

	hex : function (n, digits, grBy) {
		let h = (~~n).toString(16).toUpperCase();
		while (h.length < digits)
			h = '0' + h;

		return func.groupBy(h, grBy);
	},

	NN : function (n) {
		n = ~~n;
		return (n < 10? '0'+n: ''+n);
	},

	bin : function(n, grBy) {
		let b = (n >>> 0).toString(2);
		while (b.length < 8)
			b = '0' + b;

		return func.groupBy(b, grBy);
	},

	lo : (w) => {
		return w & 0x00FF;
	},

	hi : (w) => {
		return w >>> 8;
	},

	localTime : function(timestamp, noSeconds, withDate, withMs, UTC, TZ) {
		if (!timestamp) return '';
		
		noSeconds = (noSeconds || false);
		withDate = (withDate || false);
		withMs = (withMs || false);
		UTC = UTC || false;

		if (TZ || TZ === 0) {
			TZ = TZ * 60;
		}
		else	
			TZ = new Date().getTimezoneOffset() * (-1);

		// console.log('TZ', TZ);
		// TZ = ((TZ || TZ === 0) && TZ*60) || new Date().getTimezoneOffset();

		let time = new Date(timestamp);
		let minsTZ = UTC? 0: TZ;
		time = new Date(time.getTime() + (minsTZ)*60*1000);

		// if (UTC || TZ)
		// 	console.log('TIME', time);

		time = time.toISOString().replace('T', ' ').replace('Z', '');

		if (!withDate) 
			time = time.split(' ')[1];
		else
			time = time.split(' ')[0] + ' ' + time.split(' ')[1];

		if (!withMs) time = time.split('.')[0];

		if (noSeconds) {
			time = time.split(':');
			time = time[0] +':'+ time[1];
		}

		return time;
	},

	getSeconds : function(t, TZ) {
		// '12:45' => 12*60 + 45
		t = t || '';
		TZ = TZ || 0;

		let s = 0;
		if (t === '') {		// если нет параметра - возвращаем текущее "время"
			if (TZ)
				t = func.localTime(new Date(), false, false, false, false, TZ).split(':');
			else
				t = func.localTime(new Date(), false).split(':');
			s = +t[2];
			t = t[0] + ':' + t[1];
		}
		if (t.indexOf(':') < 0) return 0;
		return (+t.split(':')[0] * 60  +  +t.split(':')[1]) * 60  + s;
	},

	time_lesson : function(time_str, tz, thin) {
		let timestamp = func.getSeconds(time_str);
		let tl = func.localTime(timestamp * 1000, true, false, false, true);//, false, tz);

		if (thin)
			tl = tl.replace(':', ': :');
		else
			if (tl.substr(0, 1) === '0') tl = tl.substr(1);

		return tl;
	},

	time_unix : function(timestamp, withMs) {
		return func.localTime(timestamp * 1000, true, withMs).replace(' ', '<br>');
	},

	time : function (sec) {
		if (!sec) return '';

		let dd = ~~(sec / (3600*24));
		sec = sec - dd * 3600 * 24;
		let hh = ~~(sec / 3600);
		let nn = ~~((sec - hh * 3600) / 60);
		let ss = sec - hh * 3600 - nn * 60;
		return (dd > 0? dd+'д ': '') + func.NN(hh, 2) + ':' + func.NN(nn, 2) + ':' + func.NN(ss, 2);
	}
}

const keys = {
	VK_ESCAPE			: 27,
	VK_RETURN			: 13,
	VK_UP					: 38,
	VK_DOWN				: 40,
	VK_PAGEUP			: 33,
	VK_PAGEDOWN		: 34,
	VK_INSERT			: 45,
	VK_DELETE			: 46,
	VK_F2					: 113,
	VK_APPS				: 93,
	VK_F11				: 122,
}

// export default ((NN, bin, time, time_unix) => { 
// 	return {NN, bin, time, time_unix}
// })(func.NN, func.bin, func.time, func.time_unix);

// export default func;
export {keys, func}
