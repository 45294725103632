import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

import connector from './connector.js';
import {keys, func} from './func.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import './Schedule.css';

import {Link} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import {UserProfile, currSel, LT} from './Odmin.js';


class Schedule_T extends Component {

	constructor(props) {
		super(props);

		this.uz 				= props.uz;
		this.odmin_mode	= UserProfile.getOdminMode(this.uz && this.uz.url);

		this.state = {
			sched			: undefined,
			sched_msg	: undefined,
			colsTime	: false,
			less			: {}
		}

		this.root						= this.props.root

		this.req_interval				= 10 * 60 * 1000
		this.req_interval_mount	= 50
		this.req_interval_click		= 10 * 1000		// пока не исп., получается
		this.subj_clear_interval	= 10 * 1000		// очистка выделения "одинаковых" предметов

		this.maxLessN 			= this.uz.bell && this.uz.bell.length-1-1;

		this.lh 						= this.uz.lesson_hours;
		this.teacher				= props.teacher;

		this.td_curr_large	= false;

		this.colsBells			= this.state.colsTime;
		this.prevMinute			= undefined;
	}

	prepareSizes() {
		let week_length = this.uz.week_length;
		this.sched && this.sched.forEach((l1, idx) => {
			if (week_length < l1.dayN)
				week_length = l1.dayN;
		});

		this.week_days = ['▼'];	// \u00A0		🡻	▼
		for (let i = 1; i <= Math.min(7, Math.max(5, week_length)); i++)
			this.week_days.push(i);

		let sched_fs		= 1.5;
		let table_fs 		= 17.8;
		let table_fs360	= 15.75;
		let sched_mw360	= 1010;

		let cols_count = this.colsBells? this.maxLessN + 1: week_length + 1;
		// console.log('cols_count', cols_count);

		if (cols_count <= 5+1) { 
			sched_fs = 1.75;	
			table_fs = 21.0; 
			//table_fs360 = 19.0 
			sched_mw360 = 830;
		}
		if (cols_count >= 7+1) { 
			sched_fs = 1.29;	
			table_fs = 15.0; 
			table_fs360 = 13.5 
			// sched_mw360 = 1010;
		}

		// console.log('sched_fs', sched_fs);

		document.documentElement.style.setProperty(`--sched_fs`, 		sched_fs		+'vw');
		document.documentElement.style.setProperty(`--table_fs`, 		table_fs		+'px');
		document.documentElement.style.setProperty(`--table_fs360`, table_fs360	+'px');
		document.documentElement.style.setProperty(`--sched_mw360`, sched_mw360	+'px');
	}

	componentDidMount() {
		this.getData(this.req_interval_mount);

		if (this.uz.bell)
			if (this.uz.bell[0] !== '▶')	// 🡺	▲ ► ► ▶
				this.uz.bell.splice(0, 0, '▶');

		this.prepareSizes();

		document.addEventListener("keydown", this.handle_KeyDown);

		this.ti_checkMinute = setInterval(() => {
			let nn = func.localTime(new Date()).split(':')[1];
			if (this.prevMinute != nn) {
				this.prevMinute = nn;
				this.forceUpdate();
			}
		}, 1000);
	}

	componentWillUnmount() {
		clearTimeout(this.ti_query);
		clearTimeout(this.ti_checkMinute);
		document.removeEventListener("keydown", this.handle_KeyDown);
	}

	getData(delay) {
		delay = delay || this.req_interval || 1000;
		clearTimeout(this.ti_query);

		this.ti_query = setTimeout(() => {
			connector.do_query({
					cmd:'get_teacher_schedule', 
					uz_id:this.uz.id, teacher_id:this.teacher.id},
					this.receiveData);
		}, delay);
	};

	receiveData = (data_msg, data) => {
		let aw = {};
		// НЕ запоминаем и восстанавливаем anotherWeek'и
		// this.sched && this.sched.forEach((l1)=> aw[l1.id] = l1.anotherWeek );
		this.sched = data;
		// this.sched && this.sched.forEach((l1)=> l1.anotherWeek = aw[l1.id] );

		// чтобы не выводить последний столбец или строку последней пары,
		// если в это время нет уроков.
		// и размеры шрифтов от этого зависят
		let ml = this.maxLessN;
		this.maxLessN = this.uz.bell && this.uz.bell.length-1-1;
		this.sched && this.sched.forEach((l1, idx) => {
			if (this.maxLessN < l1.lessN)
				this.maxLessN = l1.lessN;
		});
		// if (ml != this.maxLessN)
			this.prepareSizes();


		this.sched_msg = data_msg;

		this.setState({
			sched			: this.sched,
			sched_msg	: data_msg,
		});

		this.getData();
	};

	scroll_less_curr = (forceToday) => {
		this.scrolled = true;
		forceToday = forceToday || false;

		if (!forceToday) {
			if (this.td_curr) {
				this.td_curr.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center'
				});			
				return;
			}

			if (this.td_next) {
				this.td_next.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center'
				});			
				return;
			}
		}

		var currColRow = document.querySelector('#tableid td.td_less.less_today');
		if (currColRow)
			currColRow.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'center'
			});

		// setTimeout(() => {									// это когда я пытался одновременно скролить 2 селектора (строка/столбец)
		// }, this.todayN * 120 || 150);			// если без плавности - делает, с ней - нет. второй стопорит 1й
																					// щас крутим сразу к нужному td
	}

	componentDidUpdate() {
		this.td_curr = document.querySelector('#tableid td.less_curr');
		this.td_next = document.querySelector('#tableid td.less_next');

		if (this.scrolled) return;

		this.scroll_less_curr();
	}

	tableClick = () => {
		return;
	}

	handle_KeyDown = (e) => {
		if (e.keyCode === 27) {
			e.preventDefault();
			this.tableClick();
			this.scroll_less_curr();
		}
	}

	tableDoubleClick() {
		this.tableClick();
		this.scroll_less_curr();
	}

	td_Click(less, less_aw, lessN, dayN, shiftN) {
		// let sameEmptyLess = currSel.less.lessN === lessN &&
		// 										currSel.less.dayN === dayN &&
		// 										currSel.less.shiftN === shiftN;

		// currSel.less = {id: (!less || this.state.less.id === less.id? undefined: less.id),
		// 								lessN:	!less && !sameEmptyLess && lessN	|| undefined, 
		// 								dayN:		!less	&& !sameEmptyLess && dayN		|| undefined,
		// 								shiftN:	!less	&& !sameEmptyLess && shiftN || undefined};

		let subj_id	= this.state.subj_id;
		if (!less || subj_id == less.subj_id) subj_id = 0;
		// else if (subj_id && subj_id != less.subj_id) subj_id = 0;
		else subj_id	= less.subj_id;

		let subj_cnt = 0;
		// let prevLessN = 0;
		// let prevDayN = 0;
		let arr = [];

		if (subj_id) {
			this.sched.forEach((l1) => {
				if ((l1.subj_id == subj_id)
						&& (arr.indexOf(l1.lessN+'_'+l1.dayN+'_'+l1.shiftN) < 0))
						// && (!l1.weekN || l1.weekN === this.uz.weekN)
						// && (!l1.subN || l1.lessN !== prevLessN || l1.dayN !== prevDayN) )
				{
					subj_cnt ++;
					arr.push(l1.lessN+'_'+l1.dayN+'_'+l1.shiftN);
					// prevLessN = l1.lessN;
					// prevDayN = l1.dayN;
				}
			});
			document.documentElement.style.setProperty(`--subj-cnt`, `"${subj_cnt}"`);
		}

		this.setState((state) => ({
			// less: currSel.less,
			subj_id	 : subj_id,
			subj_cnt : subj_cnt,
		}));

		// if (less && (less.weekN || less_aw)) {
		// 	this.sched.forEach((l1)=>{
		// 		if (l1.lessN == less.lessN && l1.dayN == less.dayN) {
		// 			l1.anotherWeek = !l1.anotherWeek;
		// 			// чтобы восстановилось, но не хз через сколько, 
		// 			// а ровно через... (там таймер заново запустится)
		// 			this.getData(this.req_interval_click);
		// 		}
		// 	});
	
		if (subj_id) {
			clearTimeout(this.ti_clearSubj);
			this.ti_clearSubj = setTimeout(() => {
				this.setState((state) => ({
					subj_id	: 0
				}));						
			}, this.subj_clear_interval);
		}

		// 	this.forceUpdate(() => {
		// 		if (less.dayN === this.todayN)
		// 			this.scroll_less_curr(true);
		// 	});
		// }
	}

	handle_corner_Click() {
		this.colsBells = !this.colsBells;
		this.setState({
			colsTime : this.colsBells
		});
		this.prepareSizes();
		this.scrolled = false;
	}

	prepare_group_name(name) {
		let quotL = '';
		if ((''+name).substr(2,1) === 'З' || (''+name).substr(2,1) === 'О')
			quotL = '\'';
		if (this.uz.group_name_kurs)
			name = (''+name).replace('_', quotL);
		else
			name = (''+name).substr(name.indexOf('_')+1);
		return name;
	}

	render() {
		let sched		= this.sched;
		let msg			= this.sched_msg;// || 'Загрузка...';

		let d = new Date();
		d = new Date(d.getTime() + (d.getTimezoneOffset()/60 + this.uz.timezone)*3600*1000);
		let todayN	= d.getDay() || 7;
		// let todayN	= new Date().getDay() || 7;

		this.shift_count  = this.uz.shift_count || 1;
		let shifts	= [];
		let bell_num = [0, 1, 1];	// план звонков для каждой смены

		for (let i = 1; i <= this.shift_count; i++) {
			shifts.push(i);

			if (sched) {						// берём план первой попавшейся группы. 
															// у остальных этой смены должны быть такие же по идее
				let l1 = sched.find((l1) => (!l1.shiftN && i === 1) || (l1.shiftN === i));
				bell_num[i] = l1 && (this.uz.group.find((g1) => g1.id === l1.group_id)).bell_num || 1;
			}
		}
		// console.log(bell_num);

		if (this.todayN !== todayN) 
			this.scrolled = false;
		this.todayN = todayN;

		const findCurrLessonN = (shiftN, bell_num) => {
			if (!sched) return;
			let lessN = -1;
			let tz = this.uz.timezone;
			let tt = func.getSeconds('', tz);

			this.uz.bell.map((b1, idx) => {
				if (b1.num && b1.num != bell_num) return;
				// время пары
				let t1 = func.getSeconds(b1.time11, tz);
				let t2 = (b1.time22 && func.getSeconds(b1.time22, tz)) || func.getSeconds(b1.time12, tz);
				// время 1й смены
				if (this.lh === 1) {
					if (shiftN === 1)
						t2 = func.getSeconds(b1.time12, tz);
					else {
						t1 = func.getSeconds(b1.time21, tz);
						t2 = func.getSeconds(b1.time22, tz);
					}
				}

				let l1 = sched.find((l1) => {
					return l1.lessN === b1.lessN && l1.dayN === todayN && 
									(!l1.shiftN || l1.shiftN === shiftN)
				});
				if (l1 && t1 <= tt && t2 > tt)
						lessN = b1.lessN;
			});
			return lessN;
		}

		const findNextLessonN = (shiftN, bell_num) => {
			if (!sched) return;
			let lessN = -1;
			let tz = this.uz.timezone;
			let tt = func.getSeconds('', tz);

			this.uz.bell.map((b1, idx) => {
				if (b1.num && b1.num != bell_num) return;

				let t1 = func.getSeconds(b1.time11, tz);
				if (shiftN === 2)
					t1 = func.getSeconds(b1.time21, tz);

				let l1 = sched.find((l1) => {
					return l1.lessN === b1.lessN && l1.dayN === todayN && 
									(!l1.shiftN || l1.shiftN === shiftN)
				});
				if (l1 && lessN === -1 && t1 > tt) {
					lessN = b1.lessN;
				}
			});
			return lessN;
		}

// тут были поиски curr и next уроков

		const getCntCurrLessOfDay = (dayN, shiftN, currLessN, nextLessN) => {
			let cnt = 0;
			let curr = 0;
			let prevLessN = 0;

			// считаем кол-во уроков в каждом дне и номер текущего/следующего
			sched && sched.forEach((l1) => {
				if (l1.dayN === dayN 
						&& (!l1.shiftN || l1.shiftN === shiftN)
						&& (!l1.weekN || l1.weekN === this.uz.weekN)
						&& (l1.lessN !== prevLessN) 	//!l1.subN || 
				){
					cnt++;
					if (dayN === todayN) {
						if (currLessN === l1.lessN) curr = cnt;
						if (currLessN < 0 && nextLessN === l1.lessN) curr = cnt;
					}
					prevLessN = l1.lessN;
				}
			});

			return {cnt, curr}
		}

		const TableHeader = (props) => {
			// let todayN = new Date().getDay() || 7;
			let shiftN = props.shiftN;

			let arr = this.week_days;
			if (this.colsBells) {
				// в bell уже вставлен 0й эл-т "уголок", в DidMount
				arr = this.uz.bell;
			}
			
			return (
				<thead className='sched'>
					<tr>
						{arr.map((b1, idx) => {

							let dayN	= b1;											// b1 == this.week_days[idx]
							let lessN	= 0;
							let time1, time2;

							if (this.colsBells && idx > 0) {		// b1 == this.uz.bell[idx]
								if (b1.num && b1.num != props.bell_num) {
									// console.log('TableHeader', `shiftN=${shiftN} ${b1.num} != ${props.bell_num}`);
									return;
								}
								lessN = b1.lessN;
								if (b1.lessN > this.maxLessN) return;
								time1 = this.lh > 1 || shiftN === 1? b1.time11: b1.time21;
								time2 = this.lh > 1 || shiftN === 2? b1.time22: b1.time12;
								if (!time1) return;
							}

							let {cnt, curr} = 0;

							if (!this.colsBells) {
								cnt  = getCntCurrLessOfDay(dayN, shiftN, props.currLessN, props.nextLessN).cnt;
								curr = getCntCurrLessOfDay(dayN, shiftN, props.currLessN, props.nextLessN).curr;
							}

							return (
								<td className={'head_row' + 
																(this.shift_count > 1? ' top35': '') + 
																(this.colsBells && idx > 0? ' no_padding': '') +
																// вот тут vv всегда будет ИЛИ то ИЛИ то
																// в colsBells тут не может быть dayN 
																// в !colsBells тут не может быть lessN 
																(dayN === todayN || props.currLessN === lessN || props.nextLessN === lessN
																	? ' todayN other_cols'
																 	: (idx === 0? ' sticky_col first_col': ' other_cols'))}
										onClick={idx === 0? this.handle_corner_Click.bind(this): null}
										title={idx === 0? 'Время в строках / в столбцах': ''}
														// (this.colsBells? 'Время в строках': 'Время в столбцах') : ''}
										key={idx}>
									<div>
										{	this.colsBells && idx > 0						// звонки по горизонтали
											? <div className='colsTime'>
													<div className='time_num_col'>{'#' + b1.lessN}</div>
													{props.currLessN === lessN || props.nextLessN === lessN
													? <><span className='time_time_col' title='Начало'>
															{func.time_lesson(time1, 0)}
														</span>
														<span className='time_end' title='Конец'>
															-{func.time_lesson(time2, 0, false)}
														</span></>
													: <span className='time_time_col_m-r' 
																	title={'до '+func.time_lesson(time2, 0, false)}>
															{func.time_lesson(time1, 0)}
														</span>}
												</div>
											: <>
													{func.DoW(dayN)}
													<span className='time_num'>{cnt? `\u00A0(${curr? curr+'/': ''}${cnt})`: ''}</span>
												</>
										}
									</div>
								</td>
							)
						})} 
					</tr>
				</thead>
			)
		} // TableHeader

		// теперь эти 3 - общие для обоих типов TableRow
		const LessT = (props) => {	// less_type
			let l1 = props.l1;
			if (!l1 || !l1.less_type_id || !this.uz.less_type) return null;

			let lt_id = l1.less_type_id;

			let lt = {};
			lt.style = { backgroundColor: this.uz.less_type[lt_id].color, 
									color: 'white', border:'none' };
			lt.name = this.uz.less_type[lt_id].name;
			lt.hint = this.uz.less_type[lt_id].name_full;

			return <div className='typeN' 
									style={lt.style}
									title={lt.hint} 
									style={lt.style}
							>
								{lt.name}
							</div>
		}

		const LW = (props) => {	// weekN
			let l1 		= props.l1;
			let l_aw	= props.l_aw;
			if (!l1 || (!l1.weekN && !l_aw)) return null;

			let st = {backgroundColor: 'var(--cl-med-dark)', color: 'white'}
			if (!l_aw) st = {backgroundColor: 'var(--cl-med)', color: 'white'}

			return <div className='weekN' 
									style={st}
							>
								{l1.weekN === 1? 'I' + (l_aw? ' : II':'') : 'II' + (l_aw? ' : I':'')}
							</div>
		}

		const Room = (props) => {
			let l1 = props.l1;
			if (!l1 || (!l1.room_id && !l1.remote)) return null;

			let name = '';
			let full = false;
			let title = '';
			let b1;

			if (l1.room_id && !l1.remote) {
				let r1 = this.uz.room[l1.room_id];
				b1 = r1 && r1.build_id? this.uz.build[r1.build_id]: '';

				full = (r1.name_full? r1.name_full: '');
				full += b1?	(full? ', ': '') + b1.name_full	: '';
				
				if (full)	// если там только "этаж N", то нафиг такую подсказку
					full += r1.floor?	(full? ', ': '') + 'этаж '+r1.floor	: '';

				name = (r1.name || '') + ' ' + (b1? b1.name: '');
			}
			else {
				name = func.Words('remote');
				title = func.Words('remote_learning');
			}

			return	<div className='room_name_cont'>
								<div className='room_name' title={title}>
									{name}
									{full?
										<span>
											{full}{full && b1 && b1.addr? <hr/>: ''}
											<span>{b1? b1.addr: ''}</span>
										</span>
									:null}
								</div>
								{l1.subN? <div className='subN' title={`${func.Words('group')} ${l1.subN}`}>
															{(isNaN(l1.subN)? '': func.Words('gr')) + l1.subN}
														</div>: null}
							</div>
		}

		const TableRowBells = (props) => {
			let LRow = props.bells;	// массив уроков с одним dayN
			let dayN = props.dayN;

			let {cnt, curr} = getCntCurrLessOfDay(dayN, props.shiftN, props.currLessN, props.nextLessN);

			return (
				<tr key={props.idx} className='the_row'>
					<td key={props.idx} className={'td_center td_time sticky_col first_col' + 		// время
													(dayN === todayN? ' time_curr': '') }>
						<div className='time_DoW'>
							{func.DoW_short(dayN)}
							<div className='DoW_divider'/>
							<div className='time_num'>
								{(cnt? `(${curr? curr+'/': ''}${cnt})`: '')}
							</div>
						</div>
					</td>

					{this.uz.bell.map((b1, idx) => {
						if (idx < 1) return null;
						if (b1.lessN > this.maxLessN) return;
						if (b1.num && b1.num != props.bell_num) return;

						let time1 = this.lh > 1 || props.shiftN === 1? b1.time11: b1.time21;
						if (!time1) return null;

						// l_aw - какбы признак, что есть урок на другой неделе
						let l1_anotherWeek = false;

						let l_aw = LRow.find((l1) => {
													return l1 && l1.lessN === b1.lessN && (l1.weekN && l1.weekN !== this.uz.weekN)
												}) || false;
												
						let l1 = LRow.find((l1, idx) => {
												return	l1 
																&& l1.lessN === b1.lessN
																&& (!l1.weekN 
																		|| (l1.weekN === this.uz.weekN && !l1.anotherWeek)
																		|| (l1.weekN && l1.weekN !== this.uz.weekN && l1.anotherWeek)
																		)
											});

						if (!l1 && l_aw) {
							l1 = l_aw;
							l1_anotherWeek = true;
							l_aw = false;
						}

						if (!l1)
							return <td key={idx} className={'sched td_empty' + 
																							(dayN === todayN? ' less_today': '') + 
																							(this.state.less.lessN === b1.lessN &&
																							 this.state.less.dayN === dayN &&
																							 this.state.less.shiftN === props.shiftN ? ' selected': '')}
													onClick={() => this.td_Click(null, null, b1.lessN, dayN, props.shiftN)}>
											<div className='col_width_wide'>x</div></td>;

						// берём все подходящие, включая этот (подгруппы)
						let ll = LRow.filter((l) => {
							return l.lessN === l1.lessN && l.dayN === l1.dayN && l.weekN === l1.weekN;
						});

						let title = '';
						if (l1 && l1.weekN)	
							title = l_aw? (l1.weekN === 1? 'Нечётная неделя' : 'Чётная неделя')
												:(l1.weekN === 1? 'только по Нечётным' : 'только по Чётным')

						// в зависимости от наличия weekN и less_type, делаем или нет отступ для "меток"
						// т.е. для каждого урока он может быть или нет, а не как раньше, если у 1го то у всех отступ
						let col_width_type = '_wide';
						if (l1.weekN || l1.less_type_id) col_width_type = '_narrow';

						let isCurr = props.currLessN === b1.lessN;
						let isNext = props.nextLessN === b1.lessN;

						let diffSubjects = false;
						ll.forEach((l1, idx) => {
							if (idx > 0 && l1.subj_id != ll[idx-1].subj_id)
								diffSubjects = true;
						});

						return (
							<td className={'sched td_less' + (dayN === todayN? ' less_today' +
																					(isCurr? ' less_curr': '') +
																					(isCurr && this.td_curr_large? ' large': '') +
																					(isNext? ' less_next': ''): '') +
																				(l1.weekN && l_aw? ' less_weekN': '') +
																				(l1_anotherWeek? ' less_anotherWeek': '') +
																				(l1.anotherWeek? ' less_anotherWeek2': '') +
																				(l1.id === this.state.less.id? ' selected': '') +
																				(l1.remote? ' remote': '')
														}
									title = {title}
									onClick={() => this.td_Click(l1, l_aw)}
									key={idx}>
								<div className={'col_width' + col_width_type}>
									<div	className={'subj_name' + (diffSubjects? ' diffSubjects': '')
																							 + (l1.subj_id === this.state.subj_id? ' same': '')}
												title={diffSubjects? func.Words('diffSubjects')
																	:l1.subj_id && this.uz.subj[l1.subj_id].name_full || ''} >
										{l1.subj_id && l1.subj_name || func.Words('no_subj')}
									</div>
									{/* <SameCnt l1={l1}/> */}
									<Room l1={l1}/>
									<div className='group_name_cont'>
										{ll.map((l1, idx) => {
											return (<span key={idx}>
																<Link to = {`${this.root}${this.uz.url}/group/${l1.group_id}`}>
																	<span className='group_name'>
																		{this.prepare_group_name(l1.group_name || '')}
																	</span>
																</Link>
																{idx < ll.length-1? ', ': null}
															</span>
														)}
										)}
										{ll.length == 1 && l1.subN && (!l1.room_id && !l1.remote) &&
											<div className='subN' title={`${func.Words('group')} ${l1.subN}`}>
												{(isNaN(l1.subN)? '': func.Words('gr')) + l1.subN}
											</div>}
									</div>
									<LessT l1={l1}/>
									<LW l1={l1} l_aw={l_aw}/>
								</div>
							</td>
						)
					} // this.uz.bell.map
					)}
				</tr>
			)
		} // TableRowBells
		
		const TableRowDays = (props) => {
			let days = props.days;	// массив уроков с одним lessN
			let b1 = props.b1;			// 1 элемент bell[]
			let shiftN = props.shiftN;

			let time1 = this.lh > 1 || shiftN === 1? b1.time11: b1.time21;
			let time2 = this.lh > 1 || shiftN === 2? b1.time22: b1.time12;
			if (!time1) return null;

			return (
				<tr key={props.idx} className='the_row'>
					<td key={props.idx} className={'td_center td_time time_bell sticky_col first_col' + 		// время
													(props.isCurr? ' time_curr': '') + 
													(props.isNext? ' time_next': '')}>
						<div className='time_num'>{'#' + b1.lessN}</div>
						<div className='time_vert' 
									title={time2 && (this.props.innerWidth <= 1000 || (!props.isCurr && !props.isNext))? 
													'до '+func.time_lesson(time2, 0, false)
													: 'Начало'}>
							{func.time_lesson(time1, 0, this.props.innerWidth <= 1000)}
						</div>
						{time2 && (props.isCurr || props.isNext)?
							<div className={'time_end'+(this.props.innerWidth <= 1000? '_small': '')} title='Конец'>
								{func.time_lesson(time2, 0)}
							</div>
							: null}
					</td>

					{this.week_days.map((dayN, idx) => {
						if (idx < 1) return null;		// "уголок" здесь не нужен

						// l_aw - какбы признак, что есть урок на другой неделе
						let l1_anotherWeek = false;
						let l_aw = days.find((l1) => {
													return l1 && l1.dayN === dayN && (l1.weekN && l1.weekN !== this.uz.weekN)
												}) || false;

						let l1 = days.find((l1, idx) => {
												return	l1 
																&& l1.dayN === dayN 
																&& (!l1.weekN 
																		|| (l1.weekN === this.uz.weekN && !l1.anotherWeek)
																		|| (l1.weekN && l1.weekN !== this.uz.weekN && l1.anotherWeek)
																		)
											});

						if (!l1 && l_aw) {
							l1 = l_aw;
							l1_anotherWeek = true;
							l_aw = false;
						}

						if (!l1)
							return <td key={idx} className={'sched td_empty' + 
																							(dayN === todayN? ' less_today': '') + 
																							(this.state.less.lessN === b1.lessN &&
																							 this.state.less.dayN === dayN &&
																							 this.state.less.shiftN === props.shiftN ? ' selected': '')}
													onClick={() => this.td_Click(null, null, b1.lessN, dayN, props.shiftN)}>
											<div className='col_width_wide'>x</div></td>;

						// берём все подходящие, включая этот (подгруппы)
						let ll = days.filter((l) => {
							return l.lessN === l1.lessN && l.dayN === l1.dayN && l.weekN === l1.weekN;
						});

						let title = '';
						if (l1 && l1.weekN)	
							title = l_aw? (l1.weekN === 1? 'Нечётная неделя' : 'Чётная неделя')
												:(l1.weekN === 1? 'только по Нечётным' : 'только по Чётным')

						// в зависимости от наличия weekN и less_type, делаем или нет отступ для "меток"
						// т.е. для каждого урока он может быть или нет, а не как раньше, если у 1го то у всех отступ
						let col_width_type = '_wide';
						if (l1.weekN || l1.less_type_id) col_width_type = '_narrow';

						let diffSubjects = false;
						ll.forEach((l1, idx) => {
							if (idx > 0 && l1.subj_id != ll[idx-1].subj_id)
								diffSubjects = true;
						});

						return (
							<td className={'sched td_less' + (dayN === todayN? ' less_today' +
																					(props.isCurr? ' less_curr': '') +
																					(props.isCurr && this.td_curr_large? ' large': '') +
																					(props.isNext? ' less_next': ''): '') +
																				(l1.weekN && l_aw? ' less_weekN': '') +
																				(l1_anotherWeek? ' less_anotherWeek': '') +
																				(l1.anotherWeek? ' less_anotherWeek2': '') +
																				(l1.id === this.state.less.id? ' selected': '') +
																				(l1.remote? ' remote': '')
														}
									title = {title}
									onClick={() => this.td_Click(l1, l_aw)}
									key={idx}>
								<div className={'col_width' + col_width_type}>
									<div	className={'subj_name' + (!l1.subj_id? ' no_teacher': '')
																							 + (diffSubjects? ' diffSubjects': '')
																							 + (l1.subj_id === this.state.subj_id? ' same': '')}
												title={diffSubjects? func.Words('diffSubjects')
																	:l1.subj_id && this.uz.subj[l1.subj_id].name_full || ''} >
										{l1.subj_id && l1.subj_name || func.Words('no_subj')}
									</div>
									<Room l1={l1}/>
									<div className='group_name_cont'>
										{ll.map((l1, idx) => {
											return (<span key={idx}>
																<Link to = {`${this.root}${this.uz.url}/group/${l1.group_id}`}>
																	<span className='group_name'>
																		{this.prepare_group_name(l1.group_name || '')}
																	</span>
																</Link>
																{idx < ll.length-1? ', ': null}
															</span>
														)}
										)}
										{ll.length == 1 && l1.subN && (!l1.room_id && !l1.remote) &&
											<div className='subN' title={`${func.Words('group')} ${l1.subN}`}>
												{(isNaN(l1.subN)? '': func.Words('gr')) + l1.subN}
											</div>}
									</div>
									<LessT l1={l1}/>
									<LW l1={l1} l_aw={l_aw}/>
								</div>
							</td>
						)
					} // this.week_days.map
					)}
				</tr>
			)
		} // TableRowDays

		let currLessN = [-1, -1, -1];
		let nextLessN = [-1, -1, -1];

		if (!this.teacher)
			return null;
		else
		return (
			sched || msg? 
			<div className='Schedule'>

					{shifts.map((shiftN, idx) => {

						if (currLessN[shiftN-1] < 0) {
							currLessN[shiftN] = findCurrLessonN(shiftN, bell_num[shiftN]);
							this.currLessN = currLessN[shiftN];
						}
						// следующий урок не выделяем, пока не закончен текущий.
						if (currLessN[shiftN-1] < 0 && nextLessN[shiftN-1] < 0) {
							nextLessN[shiftN] = (currLessN[shiftN] >= 0? -1: findNextLessonN(shiftN, bell_num[shiftN]));
							this.nextLessN = nextLessN[shiftN];
						}

						if ((currLessN[shiftN] > 0 && this.currLessN !== currLessN[shiftN]) || 
								(nextLessN[shiftN] > 0 && this.nextLessN !== nextLessN[shiftN]))
							this.scrolled = false;

						return (<div key={idx} >

							{shifts.length > 1? 
								<div className='shift_name_cont'>
									<div className='shift_name'>{LT.shift_name + ' ' + shiftN}</div>
								</div>
								: null}

							<table id='tableid'
								// tabIndex='1'
								onClick={this.tableClick.bind(this)}
								onDoubleClick={() => this.tableDoubleClick()}>	

								<TableHeader bell_num={bell_num[shiftN]} shiftN={shiftN} currLessN={this.currLessN}/>
								<tbody>
									{	this.colsBells
										? this.week_days.map((dayN, idx) =>							// внешний цикл по дням			day1	less1 less2
											{																									// вложенный - по парам		day2	less1	less2
												if ((idx === 0) ||
													(!sched && !this.odmin_mode)) return null;

												return <TableRowBells idx={idx} key={idx}
													currLessN = {currLessN[shiftN]}						// ИМЕННО ТАК передавать. через this. - лажа
													nextLessN = {nextLessN[shiftN]}
													// isCurr={currLessN === b1.lessN}						// "текущий урок" определяется во вложенном цикле
													// isNext={nextLessN === b1.lessN}
													bell_num = {bell_num[shiftN]}
													shiftN	 = {shiftN}														// в TableRowBells нужно знать, для 1го столбца
													dayN={dayN} bells={sched && sched.filter((l1) => 
																								l1.dayN === dayN && 
																								(!l1.shiftN || l1.shiftN === shiftN) ) || []}
												/>
											})
										:	this.uz.bell.map((b1, idx) =>									// внешний цикл по парам		less1	day1 day2...
											{																									// вложенный - по дням 		less2	day1 day2...
												if ((idx === 0) 
														|| (!sched && !this.odmin_mode) 
														// || (idx > this.maxLessN)
														) {
													if (idx > this.maxLessN)
														console.log(`(idx > this.maxLessN) (${idx} > ${this.maxLessN})`);
													return null;
												}

												if (b1.num && b1.num != bell_num[shiftN]) 
													return null;
														
												return <TableRowDays idx={idx} key={idx}
													shiftN = {shiftN}
													isCurr = {currLessN[shiftN] === b1.lessN}			// "текущий урок" определяется во внешнем цикле
													isNext = {nextLessN[shiftN] === b1.lessN}
													b1 = {b1} days = {sched && sched.filter((l1) => 
																								l1.lessN === b1.lessN && 
																								(!l1.shiftN || l1.shiftN === shiftN) ) || []}
												/>
											})
									}
								</tbody>
							</table>
							<div className='table_divider'></div>
						</div>)
					}) // shifts.map
					} 
				</div>
				: <div className='img_loading'>
						<CircularProgress color='inherit' size='5rem'/>
					</div>
		)
	} // render()

} // Schedule_T

export default Schedule_T;
