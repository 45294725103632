import React, { Component } from 'react';
import { withRouter } from "react-router-dom";		// для handle_group_Click
import ReactHtmlParser from 'react-html-parser'; 

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import connector from './connector.js';
import {keys, func} from './func.js';

import {Link, useHistory} from "react-router-dom";
// import Tooltip from '@material-ui/core/Tooltip';

import AnimateHeight from 'react-animate-height';
import {UserProfile, LT} from './Odmin.js';

import './Select.css';

import Schedule from './Schedule.js';


const ErrMsg = function(props) {
	return (<div className='ErrMsg'>{props.children}</div>)
}

class Select extends Component {

	constructor(props) {
		super(props);

		this.state = {
			uz						: this.props.uz,
			
			fac_id				: this.props.fac_id || undefined,
			level_id			: undefined,

			group_id			: this.props.group_id || undefined,
			pageIdx				: 0,
		}

		this.root				= this.props.root
		this.uz 				= this.props.uz
		this.odmin_mode	= UserProfile.getOdminMode(this.uz && this.uz.url);

		this.fac_id			= this.state.fac_id
		this.level_id		= undefined
		this.kurs				= -1

		this.group_id		= this.state.group_id
		
		this.kurs_list			= []
		this.kurs_list_full	= []

		this.kurs_list_max  = 0
		this.level_count 		= 0

		this.pageIdx		= 0

		this.cnt				= 0
		this.facRef 		= [];
	}

	componentDidUpdate() {
	}

	componentDidMount() {
		// console.log(`DidMount Select`);

		this.ti_facRef = setInterval(() => {
			// курсор бегает по факультетам
			if (this.facRef.length === 0) return;

			let prev = this.cnt - 1;
			if (prev < 0)
				prev = this.facRef.length - 1;

			if (this.facRef[prev] && this.facRef[prev].current)
				this.facRef[prev].current.classList.remove('curr');

			this.cnt ++;
			if (this.cnt > this.facRef.length * 10)
				this.cnt = 0;

			if (this.facRef[this.cnt] && this.facRef[this.cnt].current)
				this.facRef[this.cnt].current.classList.add('curr');
		}, 50);
	}

	componentWillUnmount() {
		clearInterval(this.ti_facRef);
	}

	prepare_kurs_list() {
		// составляем список имеющихся курсов групп,
		// в завис. от выбранного факультета и уровня
		// т.е. на такоv-то факультете может быть 4 курса
		// на другом - 6, а на аспирантуре - 3
		let kl = [];
		let kl_full = [];

		let levelsIsShifts = !this.uz.fac && this.uz.shift_count > 1;
		let level_num = this.level_id? this.get_level(this.level_id).num: -1;

		if (this.uz && this.uz.group)
			this.uz.group.map((g1) => {
				if ((!this.fac_id	|| this.fac_id === g1.fac_id) &&
						(!this.level_id	|| this.level_id === g1.level_id ||
							(levelsIsShifts && g1.shiftN === level_num)))
					if (kl.indexOf(g1.kurs) < 0)
						kl.push(g1.kurs);

				let quotL = this.uz.group_name_kurs? '\'': '';
				if (g1.name != 'З' && g1.name != 'О')
							quotL = '';
				let quotR = this.uz.group_name_kurs? '': '';

				g1.name_full = (this.uz.group_name_kurs? g1.kurs + quotL: '') + g1.name + quotR;

				if (!this.fac_id || this.fac_id === g1.fac_id)
					if (kl_full.indexOf(g1.kurs) < 0)
						kl_full.push(g1.kurs);
			});

		// для вывода полного или краткого наименования уровней
		this.kurs_list_max = Math.max(this.kurs_list_max, kl_full.length);

		// if (kl.length > 0 && kurs > kl[kl.length-1])
		if (kl.length === 0 || kl.indexOf(this.kurs) < 0)
			this.kurs = -1;			

		this.kurs_list = kl;
		this.kurs_list_full = kl_full;

		// this.setState({
		// 	kurs: this.kurs
		// });
	}

	get_level(level_id) {
		return this.uz.level.find((lv1) => {
			return lv1.id === level_id;
		});
	}

	get_level_by_num(num) {
		return this.uz.level.find((lv1) => {
			return lv1.num === num;
		});
	}

	prepare_levels() {
		// проверяем, есть ли по выбранному факультету группы с каждым из уровней
		// если нет - .exists = false и соотв. кнопка уровня не будет отображаться.
		if (!this.uz || !this.uz.level) return;
		if (!this.uz.group) return;

		this.uz.level.map((lv1) => lv1.exists = false);

		this.uz.group.map((g1) => {
			// для ВУЗов
			if (g1.level_id && (!this.fac_id || g1.fac_id === this.fac_id)) {
				let lv = this.get_level(g1.level_id);
				if (lv)
					lv.exists = true;
				else
					console.log(`this.get_level(${g1.level_id})`);
			}
			// для школ. № смены == num уровня
			// чтобы указав только № смен, можно было использовать кнопки уровней для фильтра смены
			if (!this.uz.fac && this.uz.shift_count > 1 && g1.shiftN) {
				let lv = this.get_level_by_num(g1.shiftN);
				if (lv)
					lv.exists = true;
			}
		});
		
		this.level_count = 0;
		this.uz.level.map((l1) => {if (l1.exists) this.level_count ++});
	}

	prepare_widths() {
		// ищем максимальную длину наименования групп
		// и делаем соотв. мин/макс ширину .Button
		// if (!this.uz.group) return;

		const get_tex_width = (txt, font) => {
			// document.getElementById('root')
			this.element = document.createElement('canvas');
			this.context = this.element.getContext("2d");
			this.context.font = font;
			return this.context.measureText(txt).width;
		}
	
		let fs = window.getComputedStyle(document.documentElement).getPropertyValue(`--fs`);

		let w = 0;

		if (this.uz && this.uz.group)
			this.uz.group.map((g1) => {
				if (!this.fac_id || g1.fac_id === this.fac_id)
					w = Math.max(w, get_tex_width(g1.name, fs+' Arial'));
			});

		// так классы школ (кнопки групп) точно поместятся по 3 в строке в мобильном виде:
		let dw = (!this.fac_id && window.innerWidth < 400) * 20;

		w = +w.toFixed(0) + 40 - dw + (!this.uz.fac? 40: (window.innerWidth < 400? -10: 0));
		document.documentElement.style.setProperty(`--buttonW`, w+'px');

		// кнопки уровней
		if (!this.uz.level) return;

		w = 0;
		this.uz.level.map((l1) => {
			w = get_tex_width(l1.name, fs+' Arial');
			w = +w.toFixed(0) + 40;
			l1.buttonW = w;
		});
	}

	get_fac(fac_id) {
		if (!this.uz || !this.uz.fac)
			return null;
		return this.uz.fac.find((f1) => {
			return f1.id === fac_id;
		});
	}

	get_group(gr_id) {
		if (!this.uz || !this.uz.group || !gr_id)
			return null;
		return this.uz.group.find((g1) => {
			return g1.id === gr_id;
		});
	}

	get_group_name_full(gr_id, full) {
		full = full || false;

		let g1 = this.get_group(gr_id);
		let fn = g1.name_full;
		if (this.uz.group_name_kurs)
			fn = fn + (full? ' '+LT.group_name[this.uz.group_name]: '');
		else
			if (full)
				fn = LT.group_name[this.uz.group_name] + ' ' + fn;

		return fn;
	}

	handle_fac_Click(fac_id) {
		if (!fac_id && (this.level_id || this.kurs >= 0)) {
			this.level_id = undefined;
			this.kurs = -1;
			this.group_id = undefined;
	
			this.setState({
				level_id: this.level_id, 
				kurs: 		this.kurs, 
				group_id: this.group_id,
			});
		}

		return;

		if (!this.fac_id && fac_id)
			this.kurs_list_max = 0;

		this.group_id = undefined;

		if (!fac_id && (this.level_id || this.kurs >= 0)) {
			this.level_id = undefined;
			this.kurs = -1;
			this.group_id = undefined;
		}
		else
			this.fac_id = fac_id;
		// запоминаем какой был выбран,
		// чтобы при очистке, цепочка свернулась плавно
		this.fac_id_prev = fac_id ? fac_id : this.fac_id_prev;
		// эти сбрасываем на случай, если цепь будет ссылками и можно будет куда угодно пойти
		// this.level_id = undefined;
		// this.kurs = -1;
		// this.group_id = undefined;
		
		if (fac_id) {
			this.prepare_kurs_list();
			this.prepare_levels();
			this.prepare_widths();
		}

		this.setState({
				fac_id: 	this.fac_id, 
				level_id: this.level_id, 
				kurs: 		this.kurs, 
				group_id: this.group_id,
			});
	}

	handle_level_Click(level_id) {
		if (level_id === this.level_id && !this.group_id)
			level_id = undefined;

		this.internalClick = true;

		this.level_id = level_id;
		this.group_id = undefined;
		this.prepare_kurs_list();
		this.setState({level_id: level_id, group_id: this.group_id});
	}

	handle_kurs_Click(kurs) {
		if (kurs === this.kurs && !this.group_id)
			kurs = -1;

		this.internalClick = true;

		this.kurs = kurs;
		this.kurs_prev = kurs >=0 ? kurs : this.kurs_prev;
		this.group_id = undefined;
		this.setState({kurs: kurs, group_id: this.group_id});
	}

	handle_group_Click(gr_id) {
		if (gr_id === this.group_id)						// это не понадобится когда будет выводиться расписание
			gr_id = undefined;

		let gr = this.get_group(gr_id);
		// console.log('gr_id', gr_id, gr && gr.name);

		if (gr) {
			this.group_id = gr_id;
			this.level_id = gr.level_id;
			this.kurs = gr.kurs;
			this.kurs_prev = gr.kurs;
			
			// вот это тема кароче. руками пихшем в историю чё надо из компанента
			// для этого в конце export default withRouter(Select);
			this.props.history.push(`${this.root}${this.uz.url}/group/${gr_id}`);
		}

		this.setState({
			level_id: this.level_id, 
			kurs: 		this.kurs, 
			group_id: gr_id,
		});
	}


	render() {
		// console.log('Select render()');

		{
			this.fac_id = this.props.fac_id;
			this.group_id = this.props.group_id;

			if (this.uz.fac && !this.fac_id) {
				this.level_id = undefined;
				this.kurs			= -1;
			}

			// если нам сразу передан group_id (через маршрут)
			// подбираем fac_id, чтобы он был какбы "выбран"
			let gr = null;
			if (!this.fac_id && this.group_id) {
				gr = this.get_group(this.group_id);
				if (gr) {
					this.fac_id = gr.fac_id;
					this.level_id = gr.level_id;
					this.kurs = gr.kurs;
				}
			}

			let sym = ' ' + (this.uz.UI && this.uz.UI['link-symbol'] || '❯') + ' ';
			this.fac = this.get_fac(this.fac_id);
			document.title = this.uz.name + sym + 
												(this.fac? this.fac.name: func.Words('Timetable')) + 
												(this.group_id && this.get_group(this.group_id)? 
													sym + this.get_group_name_full(this.group_id): '');
			if (this.props.updateManifest) {
				this.props.updateManifest(document.title, window.location.href);
				if (this.group_id && this.get_group(this.group_id))
					this.props.updateManifest(this.get_group_name_full(this.group_id, true), 
																		window.location.href);
			}

			this.prepare_kurs_list();
			this.prepare_levels();
			this.prepare_widths();
		}

		// let mainW = document.querySelector(`.Main`) &&
		// 						document.querySelector(`.Main`).clientWidth ||
		// 								document.querySelector(`.Main100`) &&
		// 								document.querySelector(`.Main100`).clientWidth || 0;
		// console.log('mainW', mainW);

		// this.internalClick = false;

		const facSelectedOrNoFac = this.fac_id || !this.uz || !this.uz.fac;

		let chain_height = this.fac_id ? 'auto': 0;
		let filter_height = chain_height;
		if (!this.uz || !this.uz.fac) {
			chain_height = this.kurs >= 0 || this.level_id? 'auto': 0;
			filter_height = 'auto';
		}

		// кнопки навигации
		let vuz = this.uz.fac || this.uz.level;
		document.documentElement.style.setProperty(`--nav-bottom`, '0px');
		// 	 vuz? (window.innerWidth <= 720 ? '-1.2em': '1.2em'): '0px');
			 
			// this.uz.fac || this.uz.level || window.innerWidth < 1000 ? '2.5em': '-20px');		
			// this.uz.fac || this.uz.level || window.innerWidth < 1000 ? 'calc(70px + 1vh)': '10px');		
			// this.uz && this.uz.fac							для ВУЗов
			// filter_height && this.uz.level
		// document.documentElement.style.setProperty(`--nav-display`, 
		// 	this.uz.level && this.group_id? 'none': 'block');
		// document.documentElement.style.setProperty(`--nav-opacity`, 
		// 	1===1? '50%': '100%');		// this.uz.level && this.group_id


		const SelectFac = (props) => {
			this.facRef = [];
			if (facSelectedOrNoFac) return null;
			this.cnt = this.uz.fac.length;

			const Fac = (props) => {
				this.facRef[props.idx] = React.createRef();
				return (
					<Link to = {`${this.root}${this.uz.url}/fac/${props.f1.id}`}>
						<div	className='Fac' ref={this.facRef[props.idx]} 
									onClick={this.handle_fac_Click.bind(this, props.f1.id)}>
							{props.f1.name_full}
						</div>
					</Link>
				)
			}

			return (
				<div className='Fac_Cont'>
					{this.uz.fac.map((f1, idx) => <Fac key={idx} idx={idx} f1={f1}/>)}
				</div>
			)
		} // SelectFac

		const Chain_Dop = (props) => {
			if (!this.uz) return null;

			let gr = this.get_group(this.group_id);
			let room = gr && gr.room_id && this.uz.room[gr.room_id];

			return (<div className='Chain_Dop_Cont'>
				{room && <div className='Chain_Dop'>{'к.' + room.name}</div>}
				{!this.uz.level && gr && gr.shiftN && 
					<div className='Chain_Dop'>{`${LT.shift_name} ${gr.shiftN}`}</div>}
				</div>
			)
		}

		const Chain = (props) => {
			// if (!canSelectGroup) return null;
			if (!this.uz) return null;

			const CL = (props) => {
				let title = (props.title || '').replace('<hr>', '\n').replace('<br/>', '\n');
				return (<>
						{props.linkTo? 
						<Link to={props.linkTo}>
							<div className='Chain_Link' onClick={props.onClick} 
										title={title}
							>{props.children}</div>
						</Link>
						: <div className='Chain_Link' onClick={props.onClick} 
										title={title}
							>{props.children}</div>}
					</>
				)}

			// если выбрано что-то кроме факультета, название факультета выводить кратко?
			let someSelected = this.level_id || this.kurs >= 0 || this.group_id;

			// const LevelKurs = (props) => {
			// 	return (<>
			// 		{props.level_id? <CL>{this.get_level(props.level_id).name_full}</CL>: ''}
			// 		{props.kurs >= 0? <CL>{props.kurs+' '+LT.kurs_name[this.uz.kurs_name]}</CL>: ''}
			// 	</>)
			// }

			let gr = this.get_group(this.group_id);
			let room = gr && gr.room_id && this.uz.room[gr.room_id];
			let fac_prev = this.get_fac(this.fac_id_prev);

			// клик по факультету/уровню/курсу/группе сбрасывает соотв. фильтр
			// в title факультета (который в цепи) и группы написано куда попадём по клику.
			// name_full факультета выводим если кроме него ничего не выбрано
			// если группа выбрана, уровень и курс выводим только у неё в title

			if (this.fac_id && !this.fac)
				return <ErrMsg>Факультет не найден</ErrMsg>;

			let levelsIsShifts = !this.uz.fac && this.uz.shift_count > 1;

			return (<>
				<div className={'Chain' + (!this.uz.level? '_School': '') }>
				{/* + (!this.uz.level? ' School': '') */}
					{!facSelectedOrNoFac || !this.uz.fac? 
						(fac_prev?			// чтобы когда fac_id сброшен, название скрывалось плавно:
							<CL>{fac_prev && fac_prev.name_full}</CL> 
							: null)
						:	<CL linkTo={`${this.root}${this.uz.url}`+
													(someSelected? (this.fac_id? `/fac/${this.fac_id}`: ''): `/schedule`)}
									onClick={() => this.handle_fac_Click(undefined)}
									title={this.fac && (this.level_id || this.kurs >= 0)? 
													this.fac.name_full
													: this.uz.name_full} >
								{someSelected? this.fac.name : this.fac.name_full}
							</CL>}
					{/* <LevelKurs	level_id={(gr && gr.level_id) || this.level_id}
											kurs={(gr && gr.kurs > 0 && gr.kurs) || this.kurs} /> */}
					{gr?	<CL	//linkTo = {`${this.root}${this.uz.url}`}
										linkTo={`${this.root}${this.uz.url}/`+(this.fac_id? `fac/${this.fac_id}`: 'schedule')}
										onClick={() => this.handle_group_Click(gr.id)}
										title={(this.level_id? this.get_level(this.level_id).name_full:'')
														+ (this.level_id && this.kurs >= 0? '<br/>':'')
														+ (this.kurs >= 0? this.kurs+' '+LT.kurs_name[this.uz.kurs_name]:'')
													}>								
									<strong>{this.get_group_name_full(gr.id, true)}</strong>
								</CL>
						:<>{this.level_id? <CL onClick={() => this.handle_level_Click(this.level_id)}>
																	{(levelsIsShifts? LT.shift_name + ' ': '') + 
																		this.get_level(this.level_id).name_full}
															 </CL>: ''}

							{this.kurs >= 0? <CL onClick={() => this.handle_kurs_Click(this.kurs)}>
																	{this.kurs+' '+LT.kurs_name[this.uz.kurs_name]}</CL>
																	: (!this.uz.level && this.kurs_prev && 1===2 >= 0?
															<CL>{this.kurs_prev+' '+LT.kurs_name[this.uz.kurs_name]}</CL>: '') }</>
					}

				</div>

				{room && <div className='Chain_Dop'>{func.Words('room_short') + room.name}</div>}
				{!this.uz.level && gr && gr.shiftN && 
					<div className='Chain_Dop'>{`${LT.shift_name} ${gr.shiftN}`}</div>}

					{/* {room &&
							<div	className='Chain_shiftN'>
								{(!this.uz.level && gr && gr.shiftN || gr && gr.spec_id? ', ': '') + 'кабинет ' + room.name}
							</div>} */}
					{/* {!this.uz.level && gr && gr.shiftN &&
							<div	className='Chain_shiftN'>
								{(gr && gr.spec_id? ', ': '') + ` ${LT.shift_name} ${gr.shiftN}`}
							</div>} */}
					{gr && gr.spec_id &&
						<div	className='Chain_spec' 
									// title={	window.innerWidth > 375 || 
									// 					!this.uz.spec[gr.spec_id].name_full? 'Специальность'
									// 				:this.uz.spec[gr.spec_id].name_full}
													>
							{this.uz.spec[gr.spec_id].name_full && this.uz.level? //&& window.innerWidth > 375? 
								this.uz.spec[gr.spec_id].name_full
								: this.uz.spec[gr.spec_id].name}
						</div>}
				</>
			)
		} // Chain

		const Btn = (props) => {
			let title = (props.title || '').replace('<hr>', '\n').replace('<br/>', '\n');
				// <Tooltip title={props.title? ReactHtmlParser(props.title): ''} disableFocusListener 
				//  					enterDelay={500} leaveDelay={100}>

			const btn = <div	className={props.className? props.className: 'Button'} 
												onClick={props.onClick}
												title={title}>
										{props.children}
									</div>
			
			// return <Link to={props.linkTo} replace={props.linkReplace || false}>{btn}</Link>

			return (props.linkTo
							? <Link to={props.linkTo} replace={props.linkReplace || false}>{btn}</Link>
							: btn)
		}

		const SelectGroup = (props) => {
			if (!this.uz || this.group_id || !facSelectedOrNoFac) return null;
			if (!this.uz.group)
				return (<div className='Button_Cont'>Нет расписания</div>);
			
			let levelsIsShifts = !this.uz.fac && this.uz.shift_count > 1;
			let level_num = this.level_id? this.get_level(this.level_id).num: -1;

			return (<>
					{/* {this.uz && this.uz.shift_count > 1? 'смены': null} */}
				<div className='Button_Cont'>
					{this.uz.group.map((g1, idx) => {

						let title = (g1.spec_id? 
													(this.uz.spec[g1.spec_id].name_full || this.uz.spec[g1.spec_id].name)
													+'<hr>': '') + 
												(g1.shiftN && !this.level_id? LT.shift_name + ' ' + g1.shiftN: '');

						if (this.uz.fac)
							title = g1.name + ' : ' + (g1.spec_id? 
													(this.uz.spec[g1.spec_id].name_full || this.uz.spec[g1.spec_id].name)
													+'<hr>': '')
												+ (g1.level_id? this.get_level(g1.level_id).name_full+'<br/>': '')
												+ (g1.kurs >= 0? g1.kurs+' '+LT.kurs_name[this.uz.kurs_name]: '')

						if ((!this.fac_id || g1.fac_id === this.fac_id) &&
								(!this.level_id || g1.level_id === this.level_id ||
										(levelsIsShifts && g1.shiftN === level_num)) &&
								(this.kurs < 0 || this.kurs === g1.kurs)) {
							return <Btn key={idx} className={'Button Button_Group'
																		+ (g1.id === this.group_id? ' selected': '')
																		+ (!g1.sched_exists? ' disabled': '')}
													// linkTo={`${this.root}${this.uz.url}/group/${g1.id}`}
													title={title}
													onClick={this.handle_group_Click.bind(this, g1.id)}
												>
												{this.get_group_name_full(g1.id)}
											</Btn>
						}
					})}
				</div>
			</>)
		} // SelectGroup

		const Filter = (props) => {
			if (!this.uz) return null;
			// растягивать ли кнопки фильтра на 100% в завис. от их кол-ва и ширины 
			// @media all and (max-width: 900/600px)... FK_many/FK_few
			// переменные для @media не канают.
			let ll = this.kurs_list_max + this.level_count;
			// console.log(`ll ${this.kurs_list_max}+${this.level_count}`, ll);
			let mainW = window.innerWidth;
			let many = ll > 4 || mainW < 720;

			let needKursUnderLevels = mainW*0.8 < (100 + 120 * this.level_count + 70 * this.kurs_list_max);

			let needShortLevelNames = (mainW*0.8 < (150 + 220 * this.level_count + 70 * this.kurs_list_max))
																&& (!needKursUnderLevels || this.level_count > 2);

			let link = `${this.root}${this.uz.url}/`+(this.fac_id? `fac/${this.fac_id}`: 'schedule');

			let levelsIsShifts = !this.uz.fac && this.uz.shift_count > 1;
			let gr_shiftN = this.group_id && this.get_group(this.group_id).shiftN;

			return (
				<div className={'Filter_Cont' + (!this.uz.level? '_School': '') + 
																				(needKursUnderLevels? ' FK_many': ' FK_few') +
																				(this.group_id? ' no_border_top': '')}>
					<div className={'Filter_Level' + (many? ' FK_many': ' FK_few')}>
						{this.uz && this.uz.level && this.uz.level.map((lv, idx) => {

							let l1_name = lv.name? lv.name: lv.name_full.substr(0, 4);

							if (levelsIsShifts) {
								l1_name = LT.shift_name;
								if (needShortLevelNames)
									l1_name = LT.shift_name.substr(0, 2);
								l1_name += ' ' + lv.num;
							}

							if ((mainW < 340 || (mainW > 716 && mainW <= 800)) && this.level_count > 3) {
								if (l1_name.length === 3) l1_name = l1_name.substr(0, 1) + l1_name.substr(2, 1);
								if (l1_name.length === 4) l1_name = l1_name.substr(0, 2) + l1_name.substr(3, 1);
							}

							if (lv.exists)
								return	<Btn key={idx} 
															className={'Button_Filter '+
																			((lv.id === this.level_id) ||
																				(levelsIsShifts && lv.num === gr_shiftN)? 'selected': '')}
															title={!levelsIsShifts? lv.name_full: ''}
															linkTo={link}
															linkReplace={true}
															onClick={this.handle_level_Click.bind(this, lv.id)}>
													{needShortLevelNames || levelsIsShifts? l1_name: lv.name_full}
												</Btn>
						})}
					</div>
					<div className={'Filter_Kurs' + (needKursUnderLevels? ' FK_many': ' FK_few') + 
																					(this.uz.level? ' FK_vuz': '')}>
						{this.kurs_list_full.map((k1, idx) => {
							let dis = this.kurs_list.indexOf(k1) < 0;
							return <Btn key={idx} 
													className={'Button_Filter'
																			+ (this.kurs === k1? ' selected': '')
																			+ (dis? ' disabled': '')
																		}
													title={k1+' '+LT.kurs_name[this.uz.kurs_name]}
													linkTo={link}
													linkReplace={true}
													onClick={dis? null: this.handle_kurs_Click.bind(this, k1)}
											>{k1}</Btn>
						})}
					</div>
				</div>
			)
		} // Filter

		return (<>
			<div className='Select_cont'>
				<AnimateHeight 
						easing='ease-out'
						id={'Filter_Cont'}
						//  + ((!this.uz || !this.uz.level) && 1===1? '_School': '')}
						duration={ 250 }
						height={ chain_height } // see props documentation below
					>
					<Chain />
					{/* <Chain_Dop /> */}
				</AnimateHeight>

				<div className='Select'>
					<SelectFac />
					<SelectGroup />
					{this.group_id && 
						<Schedule 
							root={this.root}
							uz={this.uz}
							innerWidth={window.innerWidth}
							group={this.uz.group && this.uz.group.find((gr1) => gr1.id === this.group_id)}
						/>
					}
				</div>
			</div>
			{filter_height != 0?
				<AnimateHeight 
						easing='ease-out'
						id={'Filter_Cont' + (!this.uz || !this.uz.level? '_School_Bottom': '')}
						duration={ 0 }
						height={ filter_height } // see props documentation below
					>
					<Filter />
				</AnimateHeight>
				: null}
		</>)
	}
} // Select

export default withRouter(Select);		// для handle_group_Click
