import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import './Odmin.js';
import {UserProfile, currSel} from './Odmin.js';
import connector from './connector.js';
import {keys, func} from './func.js';
import './EnterLess.css';
import { queryAllByAltText } from '@testing-library/dom';

const _op_Add		= 1;
const _op_Edit	= 2;
const _op_Del		= 3;

class EnterLess extends Component {

	constructor(props) {
		super(props);
		this.uz = props.uz;
		this.odmin_mode	= UserProfile.getOdminMode(this.uz && this.uz.url);

		this.state = {
			focus_name : 'subj'
		}

	}

	form_Click(e) {
		// console.log('form_Click', e.target.name);
		e.stopPropagation();
	}

	form_KeyDown(e) {
		// console.log('form_KeyDown Less');

		if (e.key == 'Delete' && e.target.type == 'select-one') {
			currSel.less_enter[e.target.name + '_id'] = null;
			this.setState((state) => ({
				focus_name	: e.target.name,
			}));
		}

		if (e.keyCode == 13) {
			e.stopPropagation();
			e.preventDefault();
			this.saveLess();
		}

		if (e.keyCode === 27) {
			e.stopPropagation();
			e.preventDefault();
			currSel.cancelOper(!currSel.oper);
			this.setState((state) => ({
				focus_name	: 'subj'
			}));
		}
	}

	back_Click(e) {
		console.log('back_Click');
		e.stopPropagation();
		currSel.cancelOper();
	}

	field_Changed(e) {
		// console.log('field_Changed', e.target.name);

		currSel.less_enter = currSel.less_enter || {};

		if (e.target.type === 'checkbox')
			currSel.less_enter[e.target.name] = e.target.checked + 0;
		else
		if (e.target.type === 'text')
			currSel.less_enter[e.target.name] = e.target.value || null;
		else {
			if (e.target.name === 'subj') {
				let s1 = this.uz.subj_arr.find((a1) => a1[0] === e.target.value)[1];
				currSel.less_enter['less_type_id'] = s1.less_type_id;
			}

			currSel.less_enter[e.target.name + '_id'] = +e.target.value || null;
		}

		// console.log(currSel.less_enter);

		this.setState((state) => ({
			less_enter	: currSel.less_enter,
			focus_name	: e.target.name,
		}));
	}

	b_CancelClick(e) {
		e.stopPropagation();
		currSel.cancelOper();
	}

	saveCallback(reply) {
		console.log(reply);
		currSel.operSuccess = true;		// для Schedule - ti_changed_LessOP - updateSched()
	}

	saveLess() {
		console.log('saveLess()');

		// сохранение
		currSel.less_enter.group_id = this.props.group_id;
		currSel.less_enter.dayN = currSel.dayN;
		currSel.less_enter.lessN = currSel.lessN;

		if (currSel.oper == _op_Add)	// собственно вот и всё отличие add/edit
			currSel.less_enter.id = 0;	// так сервер поймёт, добавлять или изменять

		connector.do_commit({ cmd:		'lesson_add_edit', 
													uz_id:	this.uz.id, 
													token:	UserProfile.getToken(),
													less:		currSel.less_enter}, this.saveCallback );

		currSel.cancelOper();
		this.setState({focus_name	: 'subj'});
	}

	b_SaveClick(e) {
		e.stopPropagation();
		console.log('b_SaveClick');
		this.saveLess();
	}

	b_ClearClick(e) {
		currSel.less_enter = {};
		currSel.less_enter.subN = '';
		this.setState((state) => ({
			less_enter	: currSel.less_enter,
		}));
		this.forceUpdate();
	}

	render() {
		// if (this.lessN != currSel.lessN || this.dayN != currSel.dayN) {
		// }
		// this.lessN = currSel.lessN;
		// this.dayN = currSel.dayN;

		const Btn = (btn) => {
			return (<div className={'op_button ' + btn.className}
										// onClick={ btn.href? null: btn.onClick || this.handle_ClickOper.bind(this, btn.oper) }
										onClick={ btn.onClick || null }
							>
								{btn.caption}
							</div>)
		}

		const MySelect = (props) => {
			if (!props.name) return null;
			return (<div className='enter_item'>
				<div className='enter_item_label'>
					{props.label}
				</div>
				<select className={'enter_item_field' + (props.short? ' width_30': '')}
								name={props.name}
								autoFocus={this.state.focus_name === props.name}
								value={currSel.less_enter && currSel.less_enter[props.name + '_id'] || 0}
								onChange={this.field_Changed.bind(this)}>
					{!props.required && <option key={0} value={0}></option>}
					{props.arr && props.arr.map((s1, idx) => {
						if (idx == 0 && !currSel.less_enter.subj_id && props.name == 'subj')
							currSel.less_enter.subj_id = s1[0];
						return (<option key={idx} value={s1[0]}>
											{s1[1].name + (props.withFullName && s1[1].name_full? 
																				` (${s1[1].name_full})`: '')}
										</option>)
					})}
				</select>
			</div>)
		}

		if (!currSel.lessN && currSel.lessN !== 0 || !currSel.oper || currSel.oper === _op_Del) return null;
		return (
			<Backdrop open={true} onClick={this.back_Click.bind(this)} style={{zIndex:'999'}}>
				<div className='pic_full enter' 
							onClick={this.form_Click.bind(this)}
							onKeyDown={this.form_KeyDown.bind(this)}
							>
					<div className='pic_full_name enter_header_left'>
						{/* <span className='enter_header_span'>{func.Words(`op_${currSel.oper === _op_Add? 'Add': 'Edit'}`)}</span>{' '}
						{func.DoW(currSel.dayN).toLowerCase()} / {func.Words('lesson')} {currSel.lessN} */}
						{window.innerWidth >= 320 &&
							<span className='enter_header_span_L'>
								{func.Words(`op_${currSel.oper === _op_Add? 'Add': 
									currSel.lessN && !currSel.less && currSel.prev_less? 'Move': 'Edit'}`)}
							</span>}
						<span>&nbsp;&nbsp;</span>
						<span className='enter_header_span'>#{currSel.lessN}</span>
						<span> </span>
						<span className='enter_header_span'>{func.DoW(currSel.dayN).toLowerCase()}</span> 
					</div>
					<div className='enter_body'>
						{/* <div className='enter_item' отступ /> */}

						<MySelect name='subj'      arr={this.uz.subj_arr}				label='Предмет'	required />
						<MySelect name='less_type' arr={this.uz.less_type_arr}	label='Тип' 		withFullName />
						<MySelect name='room'      arr={this.uz.room_arr}				
							label={window.innerWidth >= 500? 'Аудитория': 'Кабинет'} short/>
						<MySelect name='teacher'   arr={this.uz.teacher_arr}		
							label={window.innerWidth >= 550? 'Преподаватель': 'Учитель'}/>

						<div className='enter_item'>
							<div className='enter_item_label'>
								<label htmlFor="subN">Группа</label>
							</div>
							<input className='enter_item_field width_30' type="text" id="subN" name="subN" 
										 value={currSel.less_enter['subN'] || ''}
										 onChange={this.field_Changed.bind(this)}/>
						</div>

						<div className='enter_item'>
							<div className={'enter_item_label' + (window.innerWidth >= 500? ' remote15': '')}>
								<label htmlFor="remote">Удалённо</label>
							</div>
							<div className='enter_item_field2 remote2'>
								<input className='checkbox' type="checkbox" id="remote" name="remote" 
											 checked={currSel.less_enter['remote']}
											 onChange={this.field_Changed.bind(this)} />
							</div>
						</div>

						{/* <div className='enter_item' отступ /> */}
						
					</div>
					<div className='enter_footer'>
						<div className='op_button_cont2'>
							{window.innerWidth >= 500 &&
								<Btn className='enter_btn_left'  caption='Очистить' onClick={this.b_ClearClick.bind(this)}/>}
							{window.innerWidth >= 320 &&
								<Btn className='enter_btn_right' caption='Отмена' onClick={this.b_CancelClick.bind(this)}/>}
							<Btn className='enter_btn_right' caption='Сохранить' onClick={this.b_SaveClick.bind(this)}/>
						</div>
					</div>
				</div>
			</Backdrop>
		)
	}
}

export default EnterLess;
