import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

import {Link} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Grow from '@material-ui/core/Grow';

// import { instanceOf } from 'prop-types';
// import { withCookies, Cookies } from 'react-cookie';

import connector from './connector.js';
import {keys, func} from './func.js';

import './HF.css';
import { green } from '@material-ui/core/colors';


class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			req_interval	: 1000,
			uz						: this.props.uz,
			currDate			: '',
			currTime			: '',
		}

		this.root					= this.props.root;
		this.uz						= this.props.uz;

		this.timeColon		= false;

		// this.wasTouchOnce = false;
	}

	componentDidMount() {
		this.prepareUI();

		this.ti_DateTime = setInterval(() => this.updateCurrDateTime(), 1000);
		this.updateCurrDateTime();
	}

	componentWillUnmount() {
		clearTimeout(this.ti_query);
		clearInterval(this.ti_DateTime);
		document.documentElement.style.setProperty(`--cl-fone`, '#fff');
	}

	// getData(delay) {
	// 	delay = delay || this.state.req_interval || 1000;

	// 	this.ti_query = setTimeout(() => {
	// 		connector.do_query(this.props.url, this.state.req, this.receiveData);
	// 	}, delay);
	// };

	// receiveData = (data_msg, data) => {
	// 	this.uz = data;
	// 	this.prepareUI();

	// 	this.setState({
	// 		school 		: data,
	// 	});
	// 	this.getData(10000);
	// };

	prepareUI() {
		if (this.uz && this.uz.UI) {
			let cls = this.uz.UI.colors;
			if (cls) {	// устанавливаем цвета школы
				if (!cls['table-sel'])
					cls['table-sel'] = cls['light'];

				for (let cl in cls)
					document.documentElement.style.setProperty(`--cl-${cl}`, cls[cl]);
			}

			if (cls['fone']) {
				let cl = cls['fone'].substr(1, 6)+'';
				if (cl.length === 3) cl += cl;
				if ((cls['fone'] || '').length > 7) cl = 'FFFFFF';
				let clFone = parseInt(cl, 16);
				let B = clFone & 0xFF;
				let G = (clFone & 0xFF00) >>> 8;
				let R = (clFone & 0xFF0000) >>> 16;

				// console.log(clFone, cls['fone'], R, G, B);
				document.documentElement.style.setProperty(`--sh-r`, R);
				document.documentElement.style.setProperty(`--sh-g`, G);
				document.documentElement.style.setProperty(`--sh-b`, B);
			}

			let size = this.uz.UI.size || 'small';

			if (this.size && this.size !== size) {
				window.location.reload();
			}
			this.size = size;

			if (this.uz.UI['font-family'])
				document.documentElement.style.setProperty(`--font-family`, this.uz.UI['font-family']);
 			if (this.uz.UI['link-symbol'])
				document.documentElement.style.setProperty(`--link-symbol`, `"${this.uz.UI['link-symbol']}"`);

			document.documentElement.style.setProperty(`--symbol-size`, `${this.uz.UI['symbol-size']}`);
			document.documentElement.style.setProperty(`--symbol-top`, `${this.uz.UI['symbol-top']}`);
			document.documentElement.style.setProperty(`--symbol-left`, `${this.uz.UI['symbol-left']}`);
			document.documentElement.style.setProperty(`--symbol-lh`, `${this.uz.UI['symbol-lh']}`);

			document.documentElement.style.setProperty(`--logoX`, this.uz.UI.logoX || 1);
			document.documentElement.style.setProperty(`--logoPadding`, this.uz.UI.logoPadding);
			document.documentElement.style.setProperty(`--logoMargin`, this.uz.UI.logoMargin);
			document.documentElement.style.setProperty(`--logoWidth`, this.uz.UI.logoWidth || '100%');
			document.documentElement.style.setProperty(`--logoHeight`, this.uz.UI.logoHeight || '100%');
			// document.documentElement.style.setProperty(`--FilterMargin`, 'auto');

			if (this.uz.UI['subj-name-h'])
				document.documentElement.style.setProperty(`--subj-name-h`, `${this.uz.UI['subj-name-h']}`);

			if (this.uz.UI['hue-rotate'])
				document.documentElement.style.setProperty(`--hue-rotate`, `${this.uz.UI['hue-rotate']}`);
			if (this.uz.UI['hue-rotate-fone'])
				document.documentElement.style.setProperty(`--hue-rotate-fone`, `${this.uz.UI['hue-rotate-fone']}`);

			// размер заголовка, в завис. от кол-ва переносов в нём
			// let count = (this.uz.name.match(/<br>/g) || []).length;
			let count = this.uz.name_full.split('<br').length - 1;
			document.documentElement.style.setProperty(`--captionX`, ['2em','1em','0.7em','0.58em'][count]);

			if (size === 'small') {														// -small >> -normal
				['fs','hh','lw'].forEach((prName) => {
					let pr_small = window
										.getComputedStyle(document.documentElement)
										.getPropertyValue(`--${prName}-small`);
					document.documentElement.style.setProperty(`--${prName}-normal`, pr_small);
				});
			}

			if (size === 'normal' || size === 'small') {			// -normal >> -large
				['fs','hh','lw'].forEach((prName) => {
					let pr_normal = window
										.getComputedStyle(document.documentElement)
										.getPropertyValue(`--${prName}-normal`);
					document.documentElement.style.setProperty(`--${prName}-large`, pr_normal);
				});
			}
		}
	}

	updateCurrDateTime() {
		let d = new Date();

		let tz = (this.uz && (this.uz.timezone || this.uz.timezone === 0))
							|| d.getTimezoneOffset() * (-1) / 60;

		d = new Date(d.getTime() + (d.getTimezoneOffset()/60 + tz)*3600*1000);
		let sd = func.DoW(d.getDay())+',<br/>'+d.getDate()+' '+func.MonthR(d.getMonth());
		
		//, false, false, false, this.uz.timezone
		d = new Date();
		let st = func.localTime(d, true, false, false, false, tz).split(':');
		this.timeColon = !this.timeColon;
		st = st[0] + '<span class="'+(this.timeColon? '': 'hid')+'">:</span>' + st[1];
		if (st.substr(0, 1) === '0')
			st = st.substring(1);

		this.setState({
			currDate : sd,
			currTime : st,
		});
	}

	// logo() {
	// 	return (
	// 		(this.state.uz && this.state.uz.logo_b64?
	// 			<div className='h_Logo'>
	// 				{this.state.uz.logo_type === 'svg'?
	// 					<img src={`data:image/svg+xml;base64,${this.state.uz.logo_b64}`} alt=""/>
	// 					: <img src={`data:image/${this.state.uz.logo_type};base64,${this.state.uz.logo_b64}`} alt=""/>}
	// 			</div>
	// 		: null)
	// 	)
	// }

	prepareCaption() {
		return this.uz.length === 0? 
			ReactHtmlParser(
					'Расписание<br>'+
					'<small>Нет информации или соединения с сервером</small>')
			: (this.uz.UI.name_in_header !== false || 1===1
					? ReactHtmlParser(this.uz.name_full): 
					'')
	}

	render() {

		const Caption = (props) => {
			let text = props.text;
			let mainW = document.querySelector(`.Main`) &&
									document.querySelector(`.Main`).clientWidth ||
									document.querySelector(`.Main100`) &&
									document.querySelector(`.Main100`).clientWidth || 0;

			if (mainW < 560 && text.indexOf(' ') > 0)										// насильно расставляем переносы
				text = text.split(' ').join('<br/>');

			mainW = window.innerWidth;

			let count = text.split('<br').length - 1;			
			let size = ['1.7em','1em','0.7em','0.58em'][count];

			if (this.uz && this.props.sel_item > 0 
					&& this.uz.UI
					&& this.uz.UI.name_in_header !== false
					&& (window.innerWidth > 560))
				text = this.uz.name + ' ' + 					// теперь учитывается в длине при расстановке переносов.
								`<span class="cl_med">`+
								(this.uz.UI && this.uz.UI['link-symbol']
									? this.uz.UI['link-symbol']: '❯') +'</span>'+ ' ' + text;

			let len = text.replace(/(<([^>]+)>)/gi, ' ').length;

			if (count === 0 && 
					((len > 10 && mainW < 1000) || 
						(this.uz && (len > 20 && mainW > 1200 && mainW < 1700))))
			{			// если переносов нет - уменьшаем длинный текст
				size = `calc(${size} - 0.125em)`;
			}

			document.documentElement.style.setProperty(`--captionX`, size);

			return	<div className='h_UZName'>
								{!this.uz || this.uz.length === 0 || !this.uz.UI? 
									ReactHtmlParser(text)
								: (this.uz.UI.name_in_header !== false || 1===1
										? ReactHtmlParser(text) 
										: null)}
							</div>
		} // Caption

	
		return (
			<div className={'Header' + (this.props.login 
																	|| this.props.sel_item === 0
																	|| !this.props.uz.pics
																	? ' Header_filled': '')}
						onClick={this.props.onClick}>
				{process.env.NODE_ENV.substr(0, 3) === 'dev' &&
					<div className='debug'>{window.innerWidth +'x'+ window.innerHeight}</div>}

				<Caption text={this.props.caption}/>

				<div></div>

				<div className='date_cont'>
						<div className='weekN_cont'>
							{this.uz && this.uz.weekN?
								<div className='h_WeekN' title={['','Нечё','Чё'][this.uz.weekN] + 'тная неделя'}>
									{['','I','II'][this.uz.weekN]}</div>
								: <div className='h_WeekN_empty'></div>}
						</div>
					<div className='h_Date'>
						{ReactHtmlParser(this.state.currDate)}
					</div>
				</div>
				<div className='h_Time'>
					{ReactHtmlParser(this.state.currTime)}
				</div>
			</div>
		)
	}
} // Header

export default Header;