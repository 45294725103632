import React, { useState } from 'react';

import './App.css';

import connector from './Modules/connector.js';
import Lenta	from './Modules/Lenta.js';
import Main		from './Modules/Main.js';
import Footer	from './Modules/Footer.js';

import Login	from './Modules/Login.js';
import {UserProfile} from './Modules/Odmin.js';


import CircularProgress from '@material-ui/core/CircularProgress';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
	useHistory
} from "react-router-dom";


import kes_192 from './icons/kes_192.png';
import kes_512 from './icons/kes_512.png';
import kes_1024 from './icons/kes_1024.png';

const manifest = {
  "short_name": "Электронное расписание",
  "name": "Электронное расписание",
  "icons": [
    {
      "src": "icons/favicon.ico",
      "sizes": "16x16",
      "type": "image/x-icon"
    },
    {
      "src": kes_192,
      "type": "image/png",
      "sizes": "192x192"
    },
    {
      "src": kes_512,
      "type": "image/png",
      "sizes": "512x512"
    },
    {
      "src": kes_1024,
      "type": "image/png",
      "sizes": "1024x1024"
    }
  ],
  "start_url": "/select",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
}


const root				= '/';//'timetable/';
const url_select	= 'select';
const url_login		= 'login';
const url_loginR	= 'дщпшт';
const url_admin		= 'admin';
const url_root		= 'root';

let bom = false;

function updateManifest(short_name, start_url, theme_color, background_color) {
	// console.log('updateManifest', short_name);
	manifest.short_name = short_name;
	manifest.start_url = root + start_url;

	// manifest.theme_color = theme_color || '#000000';
	// manifest.background_color = background_color || '#ffffff';

	const stringManifest = JSON.stringify(manifest);
	const blob = new Blob([stringManifest], {type: 'application/json'});
	const manifestURL = URL.createObjectURL(blob);
	document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
}

export default function App() {
	if (!bom) {
		bom = true;
		// console.log('!bom');
	}

	let user = UserProfile.getUser();

  return (
    <div>
      <BrowserRouter>
				<Switch>
					{/* список УЗ */}
					<Route path={`${root}${url_select}`}>
						{user && user.isRoot
							? <SelectUZ />
							: <ShowLogin />}
					</Route>

					{/* разные варианты по УЗ */}
					<Route path={`${root}:uz_url/schedule`}>
        		<ShowUZ sel_item={1}/>
					</Route>
					<Route path={`${root}:uz_url/fac/:fac_id`}>
        		<ShowUZ sel_item={1}/>
					</Route>
					<Route path={`${root}:uz_url/group/:group_id`}>
        		<ShowUZ sel_item={1}/>
					</Route>

					<Route path={`${root}:uz_url/teacher/:teacher_id`}>
        		<ShowUZ sel_item={2}/>
					</Route>
					<Route path={`${root}:uz_url/teacher`}>
        		<ShowUZ sel_item={2}/>
					</Route>

					{/* события/информация УЗ */}
					<Route path={`${root}:uz_url/event`}>
        		<ShowUZ sel_item={4}/>
					</Route>

					{/* login */}
					<Route path={`${root}${url_loginR}`}>
						{user && user.uz_url && user.uz_url != url_root
								? <ShowUZ uz_url={user.uz_url} />
								: <ShowLogin loginR />}
					</Route>
					<Route path={`${root}${url_login}`}>
						{user && user.uz_url && user.uz_url != url_root
								? <ShowUZ uz_url={user.uz_url} />
								: <ShowLogin />}
					</Route>
					<Route path={`${root}:uz_url/${url_login}`}>
						{user && user.uz_url && user.uz_url != url_root
								? <ShowUZ uz_url={user.uz_url} />
								: <ShowLogin />}
					</Route>

					<Route path={`${root}${url_admin}`}>
						{user && user.uz_url && user.uz_url != url_root
								? <ShowUZ uz_url={user.uz_url} />
								: user && user.isRoot
									? <SelectUZ />
									: <ShowLogin />}
					</Route>
					<Route path={`${root}:uz_url/${url_admin}`}>
						<ShowUZ odmin={true} />
					</Route>

					{/* корень УЗ */}
					<Route path={`${root}:uz_url`}>
        		<ShowUZ />
					</Route>

					{/* просто корень - пока пустой */}
					<Route path={`${root}`}>
						<div style={{margin:'1em'}}>Учебное заведение не выбрано</div>
					</Route>

				</Switch>
      </BrowserRouter>
    </div>
  );
} // App


function SelectUZ() {
	const [uz_list, setUzList] = useState(null);
	const [msg, setMsg] = useState('');
	const [querySended, setQuerySended] = useState(false);

	updateManifest('select', url_select);

	const receiveData = (msg, data) => {
		if (Array.isArray(data) && data.length === 0)
			data = null;
		setUzList(data);
		setMsg(msg);
	}

	if (!querySended) {
		setQuerySended(true);
		connector.do_query({cmd: 'get_uz_list'}, receiveData);
	}

	let user = UserProfile.getUser();
	if (!user || !user.isRoot)
	 	return <ShowLogin />;

	return (<>
			{!uz_list?
				<div className='Main100'>
					<div className='img_loading'>
						<CircularProgress color='inherit' size='5rem'/>
					</div>
				</div>
			:	<div style={{margin: '20px'}}>
				<h3>{'Учебные заведения'}</h3>
				<ul>
					{uz_list.map((uz1, idx) => {
						return (
							<li key={idx}>
								<Link to={root + uz1.url + '/' + url_admin}>
									{uz1.name_full.replace(/(<([^>]+)>)/gi, ' ')}
								</Link>
							</li>
						)
					})}
				</ul>
				</div>
			}
		</>)
} // SelectUZ


function ShowUZ(props) {
	const [uz, setUz] = useState(null);
	const [lt, setLt] = useState(null);	// lang_table
	const [msg, setMsg] = useState('');
	const [querySended, setQuerySended] = useState(false);

  let { uz_url, fac_id, group_id, teacher_id } = useParams();

	if (!isNaN(fac_id))			fac_id			= +fac_id;
	if (!isNaN(group_id))		group_id		= +group_id;
	if (!isNaN(teacher_id))	teacher_id	= +teacher_id;

  let match = useRouteMatch();
	const history = useHistory();

	if (uz_url && props.uz_url && uz_url !== props.uz_url) {
		UserProfile.setUser();
		history.replace(root + url_login);
		return null;
	}

	if (props.uz_url) {
		history.replace(root + props.uz_url);
		return null;
	}

	uz_url = uz_url || props.uz_url;

	if (props.odmin && !UserProfile.getOdminMode(uz_url)) {
		history.replace(root + url_login);
		return null;
	}

	const receiveData = (msg, uz) => {
		if (Array.isArray(uz) && uz.length === 0)
			uz = null;

		setUz(uz);
		setMsg(msg);

		if (uz && uz.UI && !group_id && !teacher_id)
			updateManifest(uz.name, uz_url, uz.UI.colors['header-color']);

		if (msg)
			console.log(msg);
	}

	const receiveDataLT = (msg, lt) => {
		if (Array.isArray(lt) && lt.length === 0)
			lt = null;
		setLt(lt);
		console.log(lt);
	}

	// сюда мы попадаем также при нажатии любой кнопки, заключённой в <Link>
	// и кароче если это так, запрос УЗ не делаем, мы типа и так в нём

	if (!querySended) {
		updateManifest(uz_url, uz_url);
		setQuerySended(true);
		connector.do_query({uz_url:uz_url, cmd:'get_uz', 
												for_odmin:UserProfile.getOdminMode(uz_url)}, 
												receiveData);
		// connector.do_query({cmd:'get_lang_table'}, receiveDataLT);
	}

  if (!uz && !msg) {
		return (
			<div className='Main100'>
				<div className='img_loading'>
					<CircularProgress color='inherit' size='5rem'/>
				</div>
			</div>
		)
	}
	
	// console.log(`App: UZ ${uz? '': 'NOT '}received`);

	return (<>
    <div className="App noselect notranslate">
			<Lenta root={root} uz={uz} />
			<Main root={root} 
						uz={uz} 
						msg={msg}
						sel_item={uz? props.sel_item: 0}
						odmin={props.odmin}
						fac_id={fac_id} 
						group_id={group_id}
						teacher_id={teacher_id} 
						updateManifest={updateManifest}
						/>
			<Footer uz={uz}/>
		</div>
	</>)
} // ShowUZ

function ShowLogin(props) {
	let { uz_url } = useParams() || '';
	const history = useHistory();
	
	// UserProfile.setUser();
	if (props.loginR)
		history.replace(root + url_login);

	updateManifest('login', url_login);
	document.documentElement.style.setProperty(`--font-family`, 
					"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif");

	return (<>
    <div className="App noselect notranslate">
			<Login root={root} uz_url={uz_url} url_admin={url_admin}/>
		</div>
	</>)
}


// function ShowOdminPage(props) {
// 	let { uz_url } = useParams() || '';
// 	const history = useHistory();

// 	if (!uz_url) {
// 		history.replace(root + url_login);
// 		return 
// 	}

// 	updateManifest('admin', url_admin);
// 	document.documentElement.style.setProperty(`--font-family`, 
// 					"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif");

// 	return (<>
//     <div className="App noselect notranslate">
// 			<OdminPage root={root} uz_url={uz_url} />
// 		</div>
// 	</>)
// }