import React from 'react';
import { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import md5 from 'md5';
import connector from './connector.js';
import {UserProfile} from './Odmin.js';
import EnterSprav from './EnterSprav.js';
import {keys} from './func.js';

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}


const _op_Add		= 1;
const _op_Edit	= 2;
const _op_Del		= 3;
const _op_Image	= 4;
const _op_UZ		= 5;


class DataTable extends Component {
	constructor(props) {
		super(props);
	
		this.uz = this.props.uz;

		this.state = {
			sel_row	: undefined,
			sel_id	: 0,
			order		: this.props.table.order || 'asc',
			orderBy : this.props.table.orderBy,
			oper		: 0,
		}

		this.inputRef = React.createRef();
		this.paperRef = React.createRef();
	}

	componentDidMount = () => {
	}

	componentDidUpdate = () => {
		if (this.tableName != this.props.table.tableName) {
			this.tableName = this.props.table.tableName;
			this.setState({
				sel_row	: undefined,
				sel_id	: 0
			});
		}
	}

	handle_sortClick = (event, orderBy) => {
		let order = this.props.table.order || 'asc';
		if (orderBy === this.props.table.orderBy)
			order = order === 'asc'? 'desc': 'asc';
			
		this.props.table.orderBy = orderBy;
		this.props.table.order = order;

		this.forceUpdate();
		// this.setState({
		// 	order		: order,
		// 	orderBy : orderBy
		// });
	}

	handle_RowClick = (row) => {
		// console.log(`handle_RowClick row.id`, row && row.id);

		let sel_row = row;
		if (sel_row && sel_row.id === this.state.sel_id)
			sel_row = undefined;

		let row_image_b64;

		if (sel_row) {
			// for (let i = 0; i < this.columns.length; i++)
			// 	if (this.columns[i].name.indexOf('_b64') >= 0)
			// 		row_image_b64 = row[this.columns[i].name];

			this.columns.forEach((col) => {
				if (col.name.indexOf('_b64') >= 0)
					row_image_b64 = row[col.name];
			});
		}

		this.row_image_b64 = row_image_b64;
		this.setState({
			oper			: 0,
			msg				: '',
			sel_row 	: sel_row,
			sel_id 		: sel_row && sel_row.id || 0,
		});
	}


	descendingComparator = (a, b, orderBy, likeNumbers, order) => {
		// console.log(`if (${b[orderBy]} < ${a[orderBy]})`);
		let tn = this.props.table.tableName;
		if (tn == 'users') {
			// Пользователи текущего УЗ всегда вверху списка (root)
			let sign = order === 'desc'? 1: -1;
			if (a.uz_id == this.props.uz.id && b.uz_id != this.props.uz.id ) return -sign;
			if (b.uz_id == this.props.uz.id && a.uz_id != this.props.uz.id ) return sign;
		}

		let x = a[orderBy];
		let y = b[orderBy];

		if (likeNumbers) {
			x = parseInt(a[orderBy]);
			y = parseInt(b[orderBy]);
		}

		if (!x && x !== 0) x = null;
		if (!y && y !== 0) y = null;

		if (x > y || y === null || likeNumbers && isNaN(x)) {
			return -1;
		}
		if (x < y || x === null || likeNumbers && isNaN(y)) {
			return 1;
		}
		return 0;
	}
	
	getComparator = (order, orderBy, likeNumbers) => {
		return order === 'desc'
			? (a, b) => this.descendingComparator(a, b, orderBy, likeNumbers, order)
			: (a, b) => -this.descendingComparator(a, b, orderBy, likeNumbers, order);
	}
	
	stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	table_KeyDown = (e) => {
		if (e.keyCode === keys.VK_ESCAPE) {
			e.stopPropagation();
			e.preventDefault();
			this.handle_RowClick(this.state.sel_row);
		}
		if (e.keyCode == keys.VK_INSERT) {
			e.stopPropagation();
			e.preventDefault();
			this.handle_ClickOperButton(_op_Add);
		}
		if (e.keyCode == keys.VK_DELETE) {
			e.stopPropagation();
			e.preventDefault();
			this.handle_ClickOperButton(_op_Del);
		}
		if (e.keyCode == keys.VK_F2 || e.keyCode == keys.VK_RETURN) {
			e.stopPropagation();
			e.preventDefault();
			this.handle_ClickOperButton(_op_Edit);
		}

		// вот это кароче пока фиг. дороги постоянно сортируются в рендере 
		// и индексы их в таблице и в this.rows отличаются конкретно.

		if (this.rows
				&& (e.code == 'ArrowDown' 
						|| e.code == 'ArrowUp'
						|| e.code == 'Home'
						|| e.code == 'End')) {
			let idx = 0;
			if (this.state.sel_row)
				idx = this.rows.findIndex((row) => row === this.state.sel_row);

			if (e.code == 'ArrowDown')	if ((++ idx) > this.rows.length - 1) idx = this.rows.length - 1;
			if (e.code == 'ArrowUp')		if ((-- idx) < 0) idx = 0;

			if (e.code == 'Home') idx = 0;
			if (e.code == 'End') idx = this.rows.length - 1;

		this.handle_RowClick(this.rows[idx]);
		}

	}

	handle_ClickOperButton = (oper, e) => {
		if (oper === _op_UZ) {
			return;
		}

		if (oper === _op_Del) {
			this.record_del();
			return;
		}

		if (oper === _op_Image) {
			let col = this.columns.find((col) => col.name.indexOf('_b64') >= 0);
			let row = this.state.sel_row;

			if (this.row_image_b64) {
				if (!window.confirm(`Удалить ${this.b64_name.toLowerCase()}?`)) return;
				connector.do_commit({ cmd:				'record_b64', 
															table_name:	this.props.table.tableName,
															record_id:	row && row.id, 
															field_name:	col.name,
															token:			UserProfile.getToken(),
															image_b64:	''},	// удоляем
															() => {
																this.handle_RowClick(row);
																this.props.callbackRefresh && this.props.callbackRefresh();
															});
			}
			else {
				this.inputRef.current.click();
				return;
				this.onFileChange();	// дальше тут.
			}

			return;
		}

		if (oper != _op_Add
				&& (!this.state.sel_row 
						|| !this.state.sel_row.id)) return;

		this.setState({
			oper: oper
		});
	}

	getBase64(file, cb) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			console.log('getBase64() Error:', error);
		};
 	}

	onFileChange(e) {
		if (!e || !e.target || !e.target.files || !e.target.files.length) {
			this.setState({msg: ''});
			return;
		}

		let file = e.target.files[0];
		if (file.size > 300e3) {
			this.setState({msg: 'Слишком большой файл'});
			return;
		}

		this.getBase64(file, (result) => {
			let col = this.columns.find((col) => col.name.indexOf('_b64') >= 0);
			let row = this.state.sel_row;

			// вот это надо убирать
			// data:image/jpeg;base64,
			result = result.split(',').slice(1).join('');

			connector.do_commit({ cmd:				'record_b64', 
														table_name:	this.props.table.tableName,
														record_id:	row && row.id, 
														field_name:	col.name,
														token:			UserProfile.getToken(),
														image_b64:	result},
														() => {
															this.handle_RowClick(row);
															this.props.callbackRefresh && this.props.callbackRefresh();
														});
		});
	}

	record_del() {
		let row = this.state.sel_row;
		if (!row) return;
		if (!window.confirm(`Удалить запись?${row.name ? `\n"${row.name}"`: ''}`)) return;

		// console.log('del', row.id, this.props.table);

		connector.do_commit({ cmd:				'record_del', 
													table_name:	this.props.table.tableName,
													record_id:	row.id, 
													token:			UserProfile.getToken()}, 
													() => {
														this.handle_RowClick(row);
														this.props.callbackRefresh && this.props.callbackRefresh();
													});
	}

	callback_Enter(save) {
		let row = this.state.sel_row;

		let columns = [];
		this.columns.forEach((c1) => {
			let col = Object.assign({}, c1);
			if (this.state.oper === _op_Add || !col.password)
				col.canEdit = true
			if (col.password) {
				if (!col.value)
					col.canEdit = false;
				if (col.value)
					col.value  = md5(col.value);
			}
			columns.push(col);
		});

		// console.log(`callback_Enter(${save})`, save, columns);

		if (save) {
			connector.do_commit({ cmd:				'record_add_edit', 
														table_name:	this.props.table.tableName,
														uz_id:			this.props.uz.id, 
														record_id:	this.state.oper === _op_Edit && row ? row.id : 0, 
														token:			UserProfile.getToken(),
														record:			columns},
														() => {
															this.handle_RowClick(row);
															this.props.callbackRefresh && this.props.callbackRefresh();
														});
		}

		this.setState({oper: 0, msg: ''});
		this.paperRef.current.focus();
	}
	
	render() {
		// this.rows = this.props.rows || [];
		this.columns = this.props.columns || [];
		this.columns.forEach((c1) => c1.canEdit = !c1.password);

		if (this.columns_prev !== this.columns) {
			this.row_image_b64 = '';
			this.columns_prev = this.columns;
		}

		let b64_exists = (this.columns || []).findIndex((c1) => c1.name.indexOf('_b64') >= 0) >= 0;
		this.b64_name = '';
		if (b64_exists)
			this.b64_name = (this.columns || []).find((c1) => c1.name.indexOf('_b64') >= 0).label;

		let order = this.props.table.order;
		let orderBy = this.props.table.orderBy;


		this.rows = this.stableSort(this.props.rows || [], 
			this.getComparator(order, orderBy, 
				this.columns.filter((col) => col.name === orderBy)
				.orderLikeNumbers))


		const TableButtons = (props) => {
			const Btn = (btn) => {
				return (<div className={'op_button ' 
																+ ('op_' + (btn.align || 'left'))
																+ (btn.disabled? ' op_disabled': '')}
											title={btn.title || ''}
											onClick={ !btn.disabled 
																&& this.handle_ClickOperButton.bind(this, btn.oper) || null}
								>
									{btn.caption}
								</div>)
			}

			return (<div className='table_buttons_cont'>
					<Btn caption='Добавить' oper={_op_Add}/>
					<Btn caption='Изменить' oper={_op_Edit} disabled={!this.state.sel_id} />
					<Btn caption='Удалить' oper={_op_Del} disabled={!this.state.sel_id} />

					{this.props.table.tableName == 'uz'
						&& <a href={`http://${window.location.host}/
													${this.state.sel_row && this.state.sel_row.url || '...'}/admin`}>
									<Btn caption='Переключиться' oper={_op_UZ} align='right'
											title='Войти в выбранное УЗ' disabled={!this.state.sel_id} /></a>}

					{props.b64_exists && this.state.sel_id
						? <Btn caption={!this.row_image_b64
															? 'Загрузить ' + props.b64_name.toLowerCase() 
															: 'Удалить ' + props.b64_name.toLowerCase()}
										oper={_op_Image} // align='right' нельзя, может перекрыться картинкой
										disabled={!this.state.sel_id} />: null}
				</div>)
		}

		let logo_type = this.state.sel_row && (this.state.sel_row.logo_type || 'jpg');
		if (logo_type && logo_type[0] === '!') 
			logo_type = (logo_type || '').substr(0, logo_type.length - 1);
		if (logo_type === 'svg') logo_type = 'svg+xml';

		return (<>
			{this.state.sel_row 
							&& this.row_image_b64
							&& <img className='row_image' 
											style={{marginTop:this.props.table.b64_top,
															right:this.props.table.b64_right,
															width:this.props.table.b64_width, 
															height:this.props.table.b64_height }}
											src={`data:image/${logo_type};base64,${this.row_image_b64}`} alt=''/>
							|| null}

			<Paper className='PaperCont' ref={this.paperRef} tabIndex={-1}  
						onKeyDown={this.table_KeyDown.bind(this)}>
							
				{/* {this.state.sel_row 
							&& this.row_image_b64
							&& <img className='row_image' src={`data:image/$jpg;base64,${this.row_image_b64}`} alt=''/>
							|| null} */}

				<TableContainer className='DataTableCont'>
					<Table stickyHeader aria-label="sticky table" size="small" className='DataTable'>
						<TableHead>
							<TableRow>
								{this.columns.map((col, idx) => (col.visible !== false) && (
									<TableCell
										className='DataTableHeader'
										key={idx}
										align={col.align}
										style={{ minWidth: col.minWidth }}
										title={col.hint || ''}
									>

										<TableSortLabel
											active={orderBy === col.name}
											direction={orderBy === col.name ? order : 'asc'}
											onClick={(e) => this.handle_sortClick(e, col.name)}
											// title='Сортировка'
											className='TableSortLabel'
										>
											{col.label}
										{/* {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
										</TableSortLabel>

									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{this.rows.map((row, row_idx) => 
							{
								let sel = row.id && this.state.sel_id === row.id;
								
								let tn = this.props.table.tableName;
								// выделение юзеров текущего УЗ в таблице
								let curr = (tn == 'users' && row.uz_id == this.uz.id)
														|| (tn == 'uz' && row.id == this.uz.id);

								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row_idx} 
														className={'DataTableRow' + (this.state.sel_id === row.id ? ' selected': '')}
														onClick={(e) => this.handle_RowClick(row)}>
										{this.columns.map((col, col_idx) => {
											const value = row[col.name];
											
											let st = {};
											if (this.props.table.tableName === 'less_type' && col.name === 'color')
												st = {color: 'white', background: value};

											return (col.visible !== false) && (
												<TableCell key={col_idx} align={col.align} 
													className={'DataTableCell' 
																			+ (sel ? ' selected': '')
																			+ (curr ? ' current_uz': '')
																		}
													style={st}
													rowSpan={row_idx === 0 && col_idx === this.columns.length-1? 1: 1}
													>
													{col.name.indexOf('_b64') < 0
														? (col.format
																? col.format(value) 
																: col.name == 'url'
																	? <a href={'http://' + window.location.host + '/' + value}
																				title=''>{value}</a>
																	: value)
														: value? 'есть': ''}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{/* {this.rows.length === 0 && <div className='TableComment'></div>} */}

			</Paper>
			
			<TableButtons b64_exists={b64_exists} b64_name={this.b64_name}/>

			{this.state.msg 
				&& <div className='TableComment'>
						{this.state.msg}
					</div>}

			<input
        type="file"
        ref={this.inputRef}
        onChangeCapture={this.onFileChange.bind(this)}
				style={{visibility:'hidden'}}
				accept="image/jpeg"
      />

			{this.state.oper 
			? <EnterSprav 
					uz={this.uz} 
					columns = {this.props.columns}
					row = {this.state.sel_row}
					table = {this.props.table}
					oper = {this.state.oper}
					callback_Enter = {this.callback_Enter.bind(this)}
				/>: null}
		</>)
	}
}

export default DataTable;