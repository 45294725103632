import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import Backdrop from '@material-ui/core/Backdrop';

import connector from './connector.js';
import {keys, func} from './func.js';

import './Lenta.css';

class Lenta extends Component {

	constructor(props) {
		super(props);

		this.state = {
			uz				: this.props.uz,
			pic_idx		: -1,
		}

		this.uz 						= this.props.uz;
		this.req_interval		= 10;
		this.pics						= undefined;
		this.top						= 0;

		this.pic_idx				= this.state.pic_idx;
		this.pic_curr_idx		= 0;
		this.canScroll 			= true;

		this.moveup					= true;
		this.lastScrollTop	= -1;
	}

	scrollPics(toNext) {
		if (!this.canScroll || !this.pics || this.pics.length === 0) return;
		toNext = toNext || false;

		let pic_idx;
		pic_idx = (Math.random() * (this.pics.length - 1)).toFixed(0) * 1;

		if (toNext) {
			pic_idx = this.pic_idx + 1;
			if (pic_idx > this.pics.length - 1)
				pic_idx = 0;
		}

		let pic = document.querySelector(`#pic${pic_idx}`);

		// type ScrollLogicalPosition = "center" | "end" | "nearest" | "start";
		pic.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest'
			//(this.moveup? 'start': 'end'),
			// inline: 'center'
		});

		this.pic_curr_idx = pic_idx;

		if (this.pic_idx >= 0) {				// заменяем просматриваемую картинку **
			this.to_replace = setTimeout(() => {
				this.pic_idx = pic_idx;
				this.setState({
					pic_idx			 : this.pic_idx,
					pic_curr_idx : pic_idx
				});
			}, 300);
		}
		else
			this.setState({
				pic_curr_idx : pic_idx
			});


		clearInterval(this.ti);
		this.ti = setInterval(() => {
			this.scrollPics();
		}, (20 - ((Math.random() * (10)).toFixed(0) * 1)) * 1000);

	} // scrollPics()

	pic_KeyDown = (e) => {
		if (e.keyCode == 27) {
			this.pic_Click(-1);
		}
	}

	componentDidMount() {
		// console.log(`DidMount Lenta`);
		this.getData(this.req_interval);

		// this.handle_Scroll();
		this.ti = setInterval(() => {
			this.scrollPics();
		}, 10 * 1000);
	}

	componentWillUnmount() {
		clearInterval(this.ti);
	}

	handle_Scroll() {
		this.canScroll = false;

		clearTimeout(this.to);
		this.to = setTimeout(() => {
			this.canScroll = true;
		}, 10 * 1000);

		
		// clearInterval(this.ti);
		// this.ti = setInterval(() => {
		// 	this.scrollPics();
		// }, 10 * 1000);
		// console.log(123123);
	}

	getData(delay) {
		if (!this.uz) return;
		delay = delay || this.req_interval || 1000;
		connector.do_query({cmd:'get_lenta', uz_id:this.uz.id}, this.receiveData);
	};

	receiveData = (data_msg, data) => {
		this.pics = data && data.filter((d1) => d1.num && d1.pic_b64) || [];

		// && this.state.uz.logo_b64 
		if (this.state.uz && this.pics.length > 0) {
			let lw = this.uz.UI['lentaWidth'] || '20%';
			document.documentElement.style.setProperty(`--lentaWidth`, lw);
			document.documentElement.style.setProperty(`--lentaDisplay`, `block`);
		}

		this.forceUpdate();
	};

	componentDidUpdate() {
		this.pic_cont = document.querySelector('.pic_cont');
		this.pic_cont_cont = document.querySelector('.pic_cont_cont');
		// if (this.pic_cont)
		// 	console.log(this.pic_cont.clientHeight);
	}

	Logo() {
		return (
			(this.showLogo?	// || this.pics
				<a href={this.props.root + this.uz.url}>
					<div className='h_Logo'>
						{this.showLogo?
							(this.uz.logo_type === 'svg'
							? <img src={`data:image/svg+xml;base64,${this.uz.logo_b64}`} alt=""/>
							: <img src={`data:image/${this.uz.logo_type || 'jpg'};base64,${this.uz.logo_b64}`} alt=""/>)
						: null}
					</div>
				</a>
			: null)
		)
	}

	picFull_Click = (e) => {
		this.canScroll = true;
		this.scrollPics(true);
		e && e.stopPropagation();
	}

	pic_Click(idx, e) {
		this.pic_idx = idx;
		
		if (idx >= 0)
			this.pic_curr_idx = idx;

		this.setState({
			pic_idx 			: this.pic_idx,
			pic_curr_idx	: this.pic_curr_idx,
		});

		clearTimeout(this.to_replace);
		clearTimeout(this.to_full_close);

		if (this.pic_idx >= 0) {
			this.handle_Scroll();
			this.to_full_close = setTimeout(() => {		// закрываем через 5 минут **
				clearTimeout(this.to_replace);
				this.pic_Click(-1);
			}, 300 * 1000);
		}
	}

	PicFull() {
		if (this.pic_idx < 0) return null;
		let pic = this.pics[this.pic_idx];
		return (
			<Backdrop open={true} onClick={this.pic_Click.bind(this, -1)} style={{zIndex:'999'}}>
				<div className='pic_full' tabIndex={-1} onClick={this.picFull_Click} autoFocus
																	onKeyDown={this.pic_KeyDown.bind(this)}>
					<div className='pic_full_name'>{pic.name}</div>
					<img src={`data:image/$jpg;base64,${pic.pic_b64}`} alt=''/>
				</div>
			</Backdrop>
		)
	}

	Pics() {
		if (!this.pics) return (
			<div className='pic'>
				{/* <img src={pic_empty} alt='а нету'/> */}
			</div>
		)

		let pic = this.pics[0];

		return (
		// <div className='ddd'>
		// 	<div className='pic_top'>
		// 		<img src={`data:image/$jpg;base64,${pic.pic_b64}`} alt={pic.name}
		// 				onClick={this.pic_Click.bind(this, 0)}/>
		// 	</div>

			<div className='pic_cont_cont'>

				<div className='pic_cont' onScroll={this.handle_Scroll.bind(this)}>				
					{this.pics.map((pic, idx) => 
						<div className={'pic' + (this.pic_curr_idx === idx? ' pic_curr': '')} 
									key={idx} id={`pic${idx}`}>
							<img src={`data:image/$jpg;base64,${pic.pic_b64}`} alt={pic.name} 
									onClick={this.pic_Click.bind(this, idx)}/>
						</div>
					)}
				</div>
			</div>)
	} // Pics()

	render() {
		this.showLogo = this.uz && this.uz.logo_b64 
										&& (!this.uz.logo_type || this.uz.logo_type[0] != '!');

		document.documentElement.style.setProperty('--logo25em', this.showLogo? '2.5em': '0em');

		if (!this.uz)
			return null
		else 
		return (<>
			<div className='Lenta'>
				{this.Logo()}
				{this.Pics()}
			</div>
			{this.PicFull()}
		</>)
	}
}

export default Lenta;
