import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import {Link, useHistory} from "react-router-dom";

import connector from './connector.js';
import md5 from 'md5';
import {keys, func} from './func.js';

import Header from './Header.js';
import {UserProfile} from './Odmin.js';

import img_fone_main_left from '../images/fone_main_left.png';

import './Main.css';
import './Login.css';

const url_select	= 'select';

const useStyles = makeStyles((theme) => ({
  textField: {
			width: '20ch',
			margin: '1ch',
			color: 'red !important'
		},
}));

export default function Login(props) {

	const classes = useStyles();
	const history = useHistory();

	const _loginPrompt	= func.Words('Login');
	const _passPrompt		= func.Words('Pass');

	const [values, setValues] = React.useState({
    login: '',
    pass: '',
    showPassword: false,
		login_ok: false,
		login_msg: ''
  });

	const handle_Change = (prop) => (event) => {
		let val = event.target.value || '';
		if (prop === 'login')
			val = val.toLowerCase();
    setValues({ ...values, [prop]: val });
  };

  const handle_ClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handle_MouseDownPassword = (event) => {
    event.preventDefault();
  };

	const handle_KeyDown = (e) => {
		if (e.keyCode === keys.VK_RETURN)
			handle_LoginClck();
			// window.location.href = props.root + values.login;
	}

	const receiveData = (msg, user) => {
		msg = msg || '';
		let login_ok = !(!user || user.length < 1);
		if (login_ok)
			user = user[0];
		setValues({ ...values, login_ok: login_ok, login_msg: msg});
		// || user.name + ' ' + user.token + ' ' + user.uz_url

		if (login_ok) {
			UserProfile.setUser(user);
			if (user.isRoot)
				//history.push(props.root + url_select);
				window.location.replace('http://' + window.location.host + '/select')
			else
				history.push(props.root + user.uz_url + '/admin');
			// history.push(props.root + user.uz_url +'/'+ props.url_admin);
		}
	};

	const handle_LoginClck = () => {
		// () => {window.location.href = props.root + values.login;}
		// alert(values.login + ' ' + values.pass);
		connector.do_query({cmd:'get_token', login:values.login, pass:md5(values.pass)},
												receiveData);
	}

	document.documentElement.style.setProperty(`--cl-light`,				`#fff`);
	document.documentElement.style.setProperty(`--cl-med-light`,		`#eee`);
	document.documentElement.style.setProperty(`--cl-med`, 					`#bbb`);
	document.documentElement.style.setProperty(`--cl-dark`, 				`#666`);
	document.documentElement.style.setProperty(`--cl-btn-color`,		`#888`);
	document.documentElement.style.setProperty(`--cl-btn-back`,			`#fff`);
	document.documentElement.style.setProperty(`--cl-header-color`,	`#fff`);
	document.documentElement.style.setProperty(`--cl-header-back`,	`#666`);

	return (
		<div className='Main100 login'>
			<img className={'img_fone_main_left img_fone_bw'} src={img_fone_main_left}/>

			<Header 
				root={props.root} 
				caption={func.Words('MainCaption')}
				login={true}
			/>

			<div className='Login'>
				<div className='Login_Title'>{func.Words('LoginCaption')} {props.uz_url? `[${props.uz_url}]`: null}</div>
				<div className='Login_Body'>
					{/* <div className='Login_Hint'>
						почта: admin@alhs/nsu/bek/gym34<br/>
						пароль соотв.: alhs/nsu/bek/gym34
					</div> */}
					<div>
					<FormControl variant="outlined" className={classes.textField}>
						<InputLabel htmlFor="outlined-adornment-login">{_loginPrompt}</InputLabel>
						<OutlinedInput id="standard-basic" label={_loginPrompt} 
													autoFocus
													onChange={handle_Change('login')}
													onKeyDown={handle_KeyDown} />
					</FormControl>
					</div>
					<div>
					<FormControl variant="outlined" className={classes.textField}>
					<InputLabel htmlFor="outlined-adornment-password">{_passPrompt}</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password" label={_passPrompt}
							type={values.showPassword ? 'text' : 'password'}
							value={values.password}
							onChange={handle_Change('pass')}
							onKeyDown={handle_KeyDown}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handle_ClickShowPassword}
										onMouseDown={handle_MouseDownPassword}
										edge="end"
									>
										{values.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							// labelWidth={60}
						/>
					</FormControl>
					</div>
					<div className={"login_msg" + (values.login_ok? '_ok': '_fail')}>
						{values.login_msg}
					</div>
				</div>

				<div className='Login_Buttons'>
					<div className="Button Button_Login Button_Default" 
							onClick={handle_LoginClck}>
						{func.Words('Enter')}
					</div>
					<div className="Button Button_Login" onClick={() => {window.history.back();}}>
						{func.Words('Back')}
					</div>
				</div>

			</div>

		</div>
	)
}

// export default Login;