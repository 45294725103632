require('dotenv').config();
const srv_port = process.env.REACT_APP_SRV_PORT || 8025;

class conn {

  static timestampToLocalTimeStr = (timestamp, plusMins, withMs) => {
		plusMins = (plusMins || 0);
		withMs = (withMs || false);
	
		let time = new Date(timestamp);
		var mins = -(new Date().getTimezoneOffset());
		time = new Date(time.getTime() + (mins + plusMins)*60*1000);

		time = time.toISOString().replace('T', ' ').replace('Z', '');
		if (!withMs)
			time = time.split('.')[0];
		return time;
	}
	
	static do_post = (path, query, callback) => {
		let xhr = new XMLHttpRequest();

		try {
			query.log && console.log('>>> do_query', query);
      xhr.open('POST', `http://${window.location.hostname}:${srv_port}/${path}`, true);
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.send(JSON.stringify(query));
    } catch(e) {
      callback(`Ошибка. Не удалось отправить запрос: ${query.cmd}`);
		}
		
		xhr.onreadystatechange = (oEvent) => {
			if (xhr.readyState !== XMLHttpRequest.DONE)
				return;
			callback(xhr);
		}
	}


	static do_query = (query, callback) => {			//async

		this.do_post('get', query, (xhr) => {
			let rows = [];
			let msg = null;

			if (xhr.status === 200) {
				// if (xhr.getResponseHeader('Content-Type').indexOf('text/csv') >= 0) {
				// 	return;
				// }

				try {
					rows = JSON.parse(xhr.response);
				}
				catch(e) {
					callback && callback(xhr.response);
					return
				}

				if (xhr.response === '[]') {
					msg = 'Нет данных';
				}
			}
			else {	//query.cmd
				msg =  'query' + ': ' + (xhr.statusText !== ''? xhr.statusText: 'Ошибка')+' '+ xhr.status;
				if (xhr.status === 0)
					msg = 'Ошибка: нет соединения';
			}
			
			callback && callback(msg, rows);
		}); // do_post callback

	} // do_query

	static do_commit = (query, callback) => {			//async

		this.do_post('post', query, (xhr) => {
			let msg = null;

			if (xhr.status === 200) {
				callback && callback(xhr.response || 'Нет данных');
			}
			else {
				msg =  'query' + ': ' + (xhr.statusText !== ''? xhr.statusText: 'Ошибка')+' '+ xhr.status;
				if (xhr.status === 0)
					msg = 'Ошибка: нет соединения';
				callback && callback(msg);
			}	
		}); // do_post callback

	} // do_commit

} // class conn

export default conn;