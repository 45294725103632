import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

import { makeStyles } from '@material-ui/core/styles';

import connector from './connector.js';
import {keys, func} from './func.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import './Schedule.css';

import {Link} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import {UserProfile, currSel} from './Odmin.js';
import EnterLess from './EnterLess.js';

const _op_Add		= 1;
const _op_Edit	= 2;
const _op_Del		= 3;

class Schedule extends Component {

	constructor(props) {
		super(props);

		this.uz 				= props.uz;
		this.odmin_mode	= UserProfile.getOdminMode(this.uz && this.uz.url);

		this.state = {
			sched			: undefined,
			sched_msg	: undefined,
			colsTime	: false,
			less			: {},
		}

		this.root						= this.props.root

		this.req_interval					= 10 * 60 * 1000
		this.req_interval_mount		= 50
		this.req_interval_click		= 10 * 1000		// пока не исп.
		this.subj_clear_interval	= 10 * 1000		// очистка выделения "одинаковых" предметов

		this.maxLessN 			= 0; // this.uz.bell && this.uz.bell.length-1-1;

		this.lh 						= this.uz.lesson_hours;
		this.group 					= props.group;

		// выделяем звонки группы по bell_num
		if (this.group)
			this.group_bell		= this.uz.bell.filter((b1) => b1.num === this.group.bell_num);

		this.td_curr_large	= false;

		this.colsBells			= this.state.colsTime;
		this.prevMinute			= undefined;
	}

	prepareSizes() {
		let week_length = this.uz.week_length;
		this.sched && this.sched.forEach((l1, idx) => {
			if (week_length < l1.dayN)
				week_length = l1.dayN;
		});

		if (this.odmin_mode)
			week_length = Math.max(6, week_length);

		this.week_days = ['▼'];	// \u00A0		🡻	▼
		for (let i = 1; i <= Math.min(7, Math.max(5, week_length)); i++)
			this.week_days.push(i);

		let sched_fs		= 1.5;
		let table_fs 		= 17.8;
		let table_fs360	= 15.75;
		let sched_mw360	= 1010;

		let cols_count = this.colsBells? this.maxLessN + 1: week_length + 1;
		// console.log('cols_count', cols_count);

		if (cols_count <= 5+1) { 
			sched_fs = 1.75;	
			table_fs = 21.0; 
			//table_fs360 = 19.0 
			sched_mw360 = 830;
		}
		if (cols_count >= 7+1) { 
			sched_fs = 1.29;	
			table_fs = 15.0; 
			table_fs360 = 13.5 
			// sched_mw360 = 1010;
		}

		// console.log('sched_fs', sched_fs);

		document.documentElement.style.setProperty(`--sched_fs`, 		sched_fs		+'vw');
		document.documentElement.style.setProperty(`--table_fs`, 		table_fs		+'px');
		document.documentElement.style.setProperty(`--table_fs360`, table_fs360	+'px');
		document.documentElement.style.setProperty(`--sched_mw360`, sched_mw360	+'px');
	}

	updateSched() {
		console.log('updateSched()...');
		this.getData(this.req_interval_mount);
	}

	componentDidMount() {
		// console.log(`DidMount Schedule LH ${this.uz.lesson_hours}`);
		this.getData(this.req_interval_mount);

		if (this.group_bell)
			if (this.group_bell[0] !== '▶')	// 🡺	▲ ► ► ▶
				this.group_bell.splice(0, 0, '▶');

		this.prepareSizes();

		document.addEventListener("keydown", this.handle_KeyDown);

		this.ti_checkMinute = setInterval(() => {
			let nn = func.localTime(new Date()).split(':')[1];
			if (this.prevMinute != nn) {
				this.prevMinute = nn;
				this.forceUpdate();
			}
		}, 1000);

		this.ti_changed_LessOP = setInterval(() => {
			if (this.sel_oper != currSel.oper
					|| this.sel_succ != currSel.operSuccess) {
				// console.log('ti_changed_LessOP', this.sel_oper, currSel.oper);
				this.sel_oper = currSel.oper;
				this.sel_succ = currSel.operSuccess;
				this.forceUpdate();
				if (currSel.operSuccess) {
					currSel.operSuccess = undefined;
					this.sel_succ = undefined;
					this.updateSched();
				}
			}
		}, 100);
	}

	componentWillUnmount() {
		clearInterval(this.ti_changed_LessOP);
		clearTimeout(this.ti_query);
		clearTimeout(this.ti_checkMinute);
		document.removeEventListener("keydown", this.handle_KeyDown);
	}

	getData(delay) {
		delay = delay || this.req_interval || 1000;
		clearTimeout(this.ti_query);

		if (this.group)
			this.ti_query = setTimeout(() => {
				connector.do_query({
						cmd:'get_group_schedule', 
						uz_id:this.uz.id, group_id:this.group.id},
						this.receiveData);
			}, delay);
	};

	receiveData = (data_msg, data) => {
		let aw = {};
		// НЕ запоминаем и восстанавливаем anotherWeek'и
		// this.sched && this.sched.forEach((l1)=> aw[l1.id] = l1.anotherWeek );
		this.sched = data;
		this.sched && this.sched.forEach((l1) => {
			if (!l1.teacher_id)
				l1.teacher_name = func.Words('no_teacher');
			else
				l1.teacher_name = func.getShortFIO(l1.teacher_name);
		});
		// this.sched && this.sched.forEach((l1)=> l1.anotherWeek = aw[l1.id] );

		// чтобы не выводить последний столбец или строку последней пары,
		// если в это время нет уроков.
		// и размеры шрифтов от этого зависят
		let ml = this.maxLessN;
		this.maxLessN = 0; // this.uz.bell && this.uz.bell.length-1-1;
		if (this.group) 
			this.group.teacher_name = '';

		if (this.sched) {
			this.sched.forEach((l1, idx) => {
				if (this.maxLessN < l1.lessN)
					this.maxLessN = l1.lessN;

				if (!this.group.teacher_name 		// берём имя классного руководителя
						&& this.group.teacher_id === l1.teacher_id)
					this.group.teacher_name = l1.teacher_name;
			});
		}

		this.prepareSizes();

		// если "переместили" урок
		if (this.odmin_mode && currSel.lessN) {
			let l1 = this.sched.find((l1) => l1.lessN === currSel.lessN 
																					&& l1.dayN === currSel.dayN);
			// if (l1) currSel.cancelOper(true);
			currSel.less = l1;
			currSel.less_enter = l1;
		}

		this.sched_msg = data_msg;

		this.setState({
			sched			: this.sched,
			sched_msg	: data_msg,
			// subj_id		: 0,
		});

		this.getData();
	};

	scroll_less_curr = (forceToday) => {
		this.scrolled = true;
		forceToday = forceToday || false;

		// одновременно скролить 2 селектора (строка/столбец) со smooth НЕЛЬЗЯ
		// второй тупо стопарит 1й. раньше было 2 скролла строка/столбец. щас 1 - td

		if (!forceToday) {
			if (this.td_curr) {
				this.td_curr.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center'
				});			
				return;
			}

			if (this.td_next) {
				this.td_next.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center'
				});			
				return;
			}
		}

		var currColRow = document.querySelector('#tableid td.td_less.less_today');
		if (currColRow)
			currColRow.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'center'
			});
	}

	componentDidUpdate() {
		// console.log('Sched did update');

		this.td_curr = document.querySelector('#tableid td.less_curr');
		this.td_next = document.querySelector('#tableid td.less_next');

		if (this.scrolled) return;

		this.scroll_less_curr();
	}

	tableClick = () => {
		return;
	}

	handle_KeyDown = (e) => {
		if ((e.key === 'F2' || e.key === 'Enter') 
				&& !currSel.oper && currSel.lessN && this.odmin_mode) {
			e.preventDefault();
			currSel.oper = _op_Edit;
		}

		if ((e.key === 'Insert') 
				&& !currSel.oper && currSel.lessN && this.odmin_mode) {
			e.preventDefault();
			currSel.oper = _op_Add;
		}

		if (!this.colsBells && !currSel.oper && this.odmin_mode
				&& (!(e.key || '').indexOf('Arrow')	
						|| e.code == 'PageUp'
						|| e.code == 'PageDown'
						|| e.code == 'Home'
						|| e.code == 'End')
				) {
			e.preventDefault();

			let dayN = currSel.dayN;
			let lessN = currSel.lessN;

			if (currSel.lessN) {
				if (e.code == 'Home')			currSel.dayN = 1;
				if (e.code == 'End')			currSel.dayN = this.week_days.length - 1;

				if (e.code == 'ArrowLeft'		&& currSel.dayN > 1) 														currSel.dayN --;
				if (e.code == 'ArrowRight'	&& currSel.dayN < this.week_days.length - 1)		currSel.dayN ++;

				if (e.code == 'PageUp')		currSel.lessN = 1;
				if (e.code == 'PageDown')	currSel.lessN = this.group_bell.length - 1;

				if (e.code == 'ArrowUp'			&& currSel.lessN > 1)														currSel.lessN --;
				if (e.code == 'ArrowDown'		&& currSel.lessN < this.group_bell.length - 1)	currSel.lessN ++;
			}
			else {
				currSel.dayN = 1;
				currSel.lessN = 1;
			}

			let l1 = this.sched.find((l1) => l1.lessN === currSel.lessN 
																				&& l1.dayN === currSel.dayN);

			// console.log('currSel.dayN', currSel.dayN, l1);

			currSel.less = l1;
			currSel.prev_less = null;

			// if (currSel.less) 
			{
				currSel.less_enter = {}
				for (let key in currSel.less) {
					currSel.less_enter[key] = currSel.less[key];
				}
			}

			// эту не надо вызывать, проще тут обработать
			// this.td_Click(l1, 0, currSel.lessN, currSel.dayN, false, null);

			if (dayN != currSel.dayN || lessN != currSel.lessN) {
				this.forceUpdate();

				let currColRow;
				if (currSel.less)
					currColRow = document.querySelector('#tableid td.td_less.selected');
				else
					currColRow = document.querySelector('#tableid td.td_empty.selected');

				if (currColRow)
					currColRow.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'center'
					});
			}
		} // навигация курсором

		if (e.keyCode === 27) {
			e.preventDefault();

			if (this.odmin_mode) {
				currSel.cancelOper(!currSel.oper);

				this.setState((state) => ({
					less		 : currSel.less,
				}));
				return;		
			}

			this.tableClick();
			this.scroll_less_curr();
		}
	}

	tableDoubleClick() {
		this.tableClick();
		this.scroll_less_curr();
	}

	td_Click(less, less_aw, lessN, dayN, subj_name, e) {
		e && e.stopPropagation();

		if (this.odmin_mode) {
			if (less) {
				lessN = less.lessN;
				dayN = less.dayN;
			}

			let sameLess = ((!less && currSel.lessN === lessN && currSel.dayN === dayN)
											|| (less && currSel.less && currSel.less.id === less.id));

			if (sameLess
					&& ((new Date()).getTime() - this.ticks < 500)) {
				currSel.oper = (less? _op_Edit: _op_Add);
				currSel.less_enter = currSel.less_enter || {};
				currSel.lessN = lessN;
				currSel.dayN = dayN;
				console.log('return', currSel);	
				return;
			} 

			// вот эти хитропутанные мутки - для эмуляции даблклика
			// и ещё чтоб запоминался последний выделенный урок
			// чтобы после него при клике по пустому - подставить его данные
			// ну типа чёто наподобии копи/пасты
			// клик по существующему и, больше ничего не делая, дабл-клик по пустому
			// и там данные ^^ вот этого предыдущего

			if (!sameLess) {
				if (!currSel.less || currSel.less == {}) {
					currSel.less_enter = {};
					currSel.prev_less = null;
					currSel.less = null;
				}
				else {
					currSel.prev_less = currSel.less;
					if (Object.keys(currSel.prev_less).length === 0) {
						currSel.prev_less = null;
						currSel.less = null;
					}
				}
			} 
			else
				currSel.prev_less = null;

			if (sameLess) {
				currSel.prev_lessN = currSel.lessN;
				currSel.prev_dayN = currSel.dayN;
				this.ticks = (new Date()).getTime();
				currSel.oper = 0;
			} 
			else {
				if (currSel.prev_lessN == lessN 
						&& currSel.prev_dayN == dayN
						&& ((new Date()).getTime() - this.ticks < 500))
					currSel.oper = _op_Edit;
				else {
					currSel.oper = 0;
				}

				currSel.prev_lessN = undefined;
				currSel.prev_dayN = undefined;
			}

			currSel.less = currSel.less === less? null: less;
			currSel.lessN = !sameLess && lessN;//(lessN || lessN === 0) || undefined;		///
			if (currSel.lessN === false)
				currSel.lessN = undefined;
			console.log('currSel.lessN', currSel.lessN);
			currSel.dayN = !sameLess && dayN || undefined;

			this.ticks = (new Date()).getTime();
			// console.log(sameLess, this.ticks);	

			currSel.less_enter = currSel.less_enter || {};
			if (currSel.less) {
				currSel.less_enter = {}
				for (let key in currSel.less) {
					currSel.less_enter[key] = currSel.less[key];
				}
			}

			this.setState((state) => ({
				less		 : currSel.less,
			}));
		} // if (this.odmin_mode)

		// console.log(this.uz.subj);
		// var g = JSON.stringify(this.uz.subj).replace(/[\[\]\,\"]/g,'');
		// console.log('full length', g.length);

		// if (this.uz && this.uz.subj) {
		// 	let a = Object.entries(this.uz.subj);
		// 	console.log(a);
		// 	a.map((a1, idx) => {
		// 		console.log(a1[0], ':', a1[1]);
		// 	});
		// 	g = JSON.stringify(a).replace(/[\[\]\,\"]/g,'');
		// 	console.log('compact length', g.length);
		// }

		// if (this.uz && this.uz.subj)
		// 	for (let idx in this.uz.subj) {
		// 		console.log(idx, this.uz.subj[idx])
		// 	};

		if (subj_name || !this.odmin_mode) {
			let subj_id	= this.state.subj_id;
			if (!less || subj_id == less.subj_id) subj_id = 0;
			else subj_id	= less.subj_id;

			let subj_cnt = 0;
			let arr = [];
			
			if (subj_id) {
				this.sched.forEach((l1) => {
					if ((l1.subj_id == subj_id)
							&& (arr.indexOf(l1.lessN+'_'+l1.dayN) < 0))
					{
						subj_cnt ++;
						arr.push(l1.lessN+'_'+l1.dayN);
					}
				});

				// оно там в css выводитс в .subj_name.same:after
				document.documentElement.style.setProperty(`--subj-cnt`, `"${subj_cnt}"`);
			}

			this.setState((state) => ({
				subj_id	 : subj_id,
			}));

			if (subj_id) {
				clearTimeout(this.ti_clearSubj);
				this.ti_clearSubj = setTimeout(() => {
					this.setState((state) => ({
						subj_id	: 0
					}));						
				}, this.subj_clear_interval);
			}
		}
	} // td_Click()

	handle_corner_Click() {
		this.colsBells = !this.colsBells;
		this.setState({
			colsTime : this.colsBells
		});
		this.prepareSizes();
		this.scrolled = false;
	}

	render() {
		let sched		= this.sched;
		let msg			= this.sched_msg;// || 'Загрузка...';

		let d = new Date();
		d = new Date(d.getTime() + (d.getTimezoneOffset()/60 + this.uz.timezone)*3600*1000);
		let todayN	= d.getDay() || 7;
		// let todayN	= new Date().getDay() || 7;

		let shiftN	= this.group && this.group.shiftN || 1;

		if (this.todayN !== todayN) 
			this.scrolled = false;
		this.todayN = todayN;

		const findCurrLessonN = () => {
			if (!sched) return;
			let lessN = -1;
			let tz = this.uz.timezone;
			let tt = func.getSeconds('', tz);

			this.group_bell.map((b1, idx) => {
				// время пары
				let t1 = func.getSeconds(b1.time11, tz);
				let t2 = (b1.time22 && func.getSeconds(b1.time22, tz)) || func.getSeconds(b1.time12, tz);
				// время 1й смены
				if (this.lh === 1) {
					if (shiftN === 1)
						t2 = func.getSeconds(b1.time12, tz);
					else {
						t1 = func.getSeconds(b1.time21, tz);
						t2 = func.getSeconds(b1.time22, tz);
					}
				}

				let l1 = sched.find((l1) => {
					return l1.lessN === b1.lessN && l1.dayN === todayN && 
									(!l1.weekN || l1.weekN === this.uz.weekN)
				});
				if (l1 && t1 <= tt && tt < t2)
						lessN = b1.lessN;
			});
			// console.log('findCurrLessonN', lessN);
			return lessN;
		}

		const findNextLessonN = () => {
			if (!sched) return;
			let lessN = -1;
			let tz = this.uz.timezone;
			let tt = func.getSeconds('', tz);

			this.group_bell.map((b1, idx) => {
				let t1 = func.getSeconds(b1.time11, tz);
				if (shiftN === 2)
					t1 = func.getSeconds(b1.time21, tz);

				let l1 = sched.find((l1) => {
					return l1.lessN === b1.lessN && l1.dayN === todayN &&
									(!l1.weekN || l1.weekN === this.uz.weekN)
				});
				if (l1 && lessN === -1 && t1 > tt) {
					lessN = b1.lessN;
				}
			});
			// console.log('findNextLessonN', lessN);
			return lessN;
		}

		let currLessN = findCurrLessonN();
		// следующий урок не выделяем, пока не закончен текущий.
		let nextLessN = (currLessN >= 0? -1: findNextLessonN());

		// console.log(currLessN, '/', nextLessN);

		if (this.currLessN !== currLessN || this.nextLessN !== nextLessN)
			this.scrolled = false;
		this.currLessN = currLessN;
		this.nextLessN = nextLessN;

		const getCntCurrLessOfDay = (dayN) => {
			let cnt = 0;
			let curr = 0;
			let prevLessN = -1;
			
			// считаем кол-во уроков в каждом дне и номер текущего/следующего
			sched && sched.forEach((l1) => {
				if (l1.dayN === dayN 
						&& (!l1.weekN || l1.weekN === this.uz.weekN)
						&& (!l1.subN || l1.lessN !== prevLessN) 
				){
					cnt++;
					if (dayN === todayN) {
						if (currLessN === l1.lessN) curr = cnt;
						if (currLessN < 0 && nextLessN === l1.lessN) curr = cnt;
					}
					prevLessN = l1.lessN;
				}
			});

			return {cnt, curr}
		}

		const TableHeader = (props) => {
			// let todayN 		= new Date().getDay() || 7;

			let arr = this.week_days;
			if (this.colsBells) {
				// в bell уже вставлен 0й эл-т "уголок", в DidMount
				arr = this.group_bell;
			}

			return (
				<thead className='sched'>
					<tr>
						{arr.map((b1, idx) => {
//							console.log(`${b1.num} != ${this.group.bell_num}`);
//							if (idx > 0 && this.colsBells && b1.num != this.group.bell_num) return null;

							let dayN	= b1;											// b1 == this.week_days[idx]
							let lessN	= 0;
							let time1, time2;

							if (this.colsBells && idx > 0) {		// b1 == this.group_bell[idx]
								lessN = b1.lessN;
								if (b1.lessN > this.maxLessN && !this.odmin_mode) return;
								time1 = this.lh > 1 || shiftN === 1? b1.time11: b1.time21;
								time2 = this.lh > 1 || shiftN === 2? b1.time22: b1.time12;
								if (!time1) return;
							}

							let {cnt, curr} = 0;

							if (!this.colsBells) {
								cnt  = getCntCurrLessOfDay(dayN).cnt;
								curr = getCntCurrLessOfDay(dayN).curr;
							}

							return (
								<td className={'head_row' + (this.colsBells && idx > 0? ' no_padding': '') +
																// вот тут vv всегда будет ИЛИ то ИЛИ то
																// в colsBells тут не может быть dayN 
																// в !colsBells тут не может быть lessN 
																(dayN === todayN || currLessN === lessN || nextLessN === lessN
																	? ' todayN other_cols'
																 	: (idx === 0? ' sticky_col first_col': ' other_cols'))}
										onClick={idx === 0? this.handle_corner_Click.bind(this): null}
										title={idx === 0? 'Время в строках / в столбцах': ''}
														// (this.colsBells? 'Время в строках': 'Время в столбцах') : ''}
										key={idx}>
									<div>
										{	this.colsBells && idx > 0						// звонки по горизонтали
											? <div className='colsTime'>
													<div className='time_num_col'>{'#' + b1.lessN}</div>
													{currLessN === lessN || nextLessN === lessN
													? <><span className='time_time_col' title='Начало'>
															{func.time_lesson(time1, 0)}
														</span>
														<span className='time_end' title='Конец'>
															-{func.time_lesson(time2, 0, false)}
														</span></>
													: <span className='time_time_col_m-r' 
																	title={'до '+func.time_lesson(time2, 0, false)}>
															{func.time_lesson(time1, 0)}
														</span>}
												</div>
											: <>
													{func.DoW(dayN)}
													<span className='time_num'>{cnt? `\u00A0(${curr? curr+'/': ''}${cnt})`: ''}</span>
												</>
										}
									</div>
								</td>
							)
						})} 
					</tr>
				</thead>
			)
		} // TableHeader

		// теперь эти 3 - общие для обоих типов TableRow
		const LT = (props) => {	// less_type
			let l1 = props.l1;
			if (!l1 || !l1.less_type_id || !this.uz.less_type) return null;

			let lt_id = l1.less_type_id;

			let lt = {};
			lt.style = { backgroundColor: this.uz.less_type[lt_id].color, 
									color: 'white', border:'none' };
			lt.name = this.uz.less_type[lt_id].name;
			lt.hint = this.uz.less_type[lt_id].name_full;

			return <div className='typeN' 
									style={lt.style}
									title={lt.hint} 
									style={lt.style}
							>
								{lt.name}
							</div>
		}

		const LW = (props) => {	// weekN
			let l1 		= props.l1;
			let l_aw	= props.l_aw;
			if (!l1 || !l1.weekN) return null;

			let st = {backgroundColor: 'var(--cl-med-dark)', color: 'white'}
			if (!l_aw) st = {backgroundColor: 'var(--cl-med)', color: 'white'}

			return <div className='weekN' 
									style={st}
							>
								{l1.weekN === 1? 'I' + (l_aw? ' : II':'') : 'II' + (l_aw? ' : I':'')}
							</div>
		}

		const Room = (props) => {
			let l1 = props.l1;
			if (!l1 || (!l1.room_id && !l1.remote)) return null;

			let name = '';
			let full = false;
			let title = '';
			let b1;

		/*	чтобы если кабинеты у подгрупп одинаковые (ИМиП), 
				показывать только для 1й подгруппы, чтобы место не занимать 2й раз */
			if (l1.room_id && !l1.remote) {
				if (props.idx && props.l1_prev.room_id === l1.room_id) return null;

				let r1 = this.uz.room[l1.room_id];
				b1 = r1 && r1.build_id? this.uz.build[r1.build_id]: '';

				full = (r1.name_full? r1.name_full: '');
				full += b1?	(full? ', ': '') + b1.name_full	: '';
				
				if (full)	// если там только "этаж N", то нафиг такую подсказку
					full += r1.floor?	(full? ', ': '') + 'этаж '+r1.floor	: '';

				name = (r1.name || '') + ' ' + (b1? b1.name: '');
			}
			else {
				name = func.Words('remote');
				title = func.Words('remote_learning');
			}

			return	<div className='room_name_cont'>
								<div className='room_name' title={title}>
									{name}
									{full?
										<span>
											{full}{full && b1 && b1.addr? <hr/>: ''}
											<span>{b1? b1.addr: ''}</span>
										</span>
									:null}
								</div>
							</div>
		}

		const TableRowBells = (props) => {
			let LRow = props.bells;	// массив уроков с одним dayN
			let dayN = props.dayN;

			let {cnt, curr} = getCntCurrLessOfDay(dayN);

			return (
				<tr key={props.idx} className='the_row'>
					<td key={props.idx} className={'td_center td_time sticky_col first_col' + 		// время
													(dayN === todayN? ' time_curr': '') }>
						<div className='time_DoW'>
							{func.DoW_short(dayN)}
							<div className='DoW_divider'/>
							<div className='time_num'>
								{(cnt? `(${curr? curr+'/': ''}${cnt})`: '')}
							</div>
						</div>
					</td>

					{this.group_bell.map((b1, idx) => {
						if (idx < 1) return null;
						if (b1.lessN > this.maxLessN && !this.odmin_mode) return;

						let time1 = this.lh > 1 || shiftN === 1? b1.time11: b1.time21;
						if (!time1) return null;
			
						// l_aw - какбы признак, что есть урок на другой неделе
						let l1_anotherWeek = false;

						let l_aw = LRow.find((l1) => {
													return l1 && l1.lessN === b1.lessN && l1.weekN !== this.uz.weekN
												}) || false;

						let l1 = LRow.find((l1, idx) => {
												return	l1 
																&& l1.lessN === b1.lessN
																&& (!l1.weekN 
																		|| (l1.weekN === this.uz.weekN && !l1.anotherWeek)
																		|| (l1.weekN !== this.uz.weekN && l1.anotherWeek)
																		)
											});

						if (!l1 && l_aw) {
							l1 = l_aw;
							l1_anotherWeek = true;
							l_aw = false;
						}

						if (!l1)
							return <td key={idx} className={'sched td_empty' + 
																							(dayN === todayN? ' less_today': '') + 
																							(currSel.lessN === b1.lessN &&
																								currSel.dayN === dayN ? ' selected': '')}
													onClick={this.td_Click.bind(this, null, null, b1.lessN, dayN, false)}>
											<div className='col_width_wide'>x</div></td>;

						// берём все подходящие, включая этот (подгруппы)
						let ll = LRow.filter((l) => {
							return l.lessN === l1.lessN && l.dayN === l1.dayN && l.weekN === l1.weekN;
						});

						let title = '';
						if (l1 && l1.weekN)	
							title = l_aw? (l1.weekN === 1? 'Нечётная неделя' : 'Чётная неделя')
												:(l1.weekN === 1? 'только по Нечётным' : 'только по Чётным')

						// в зависимости от наличия weekN и less_type, делаем или нет отступ для "меток"
						// т.е. для каждого урока он может быть или нет, а не как раньше, если у 1го то у всех отступ
						let col_width_type = '_wide';
						if (l1.weekN || l1.less_type_id) col_width_type = '_narrow';

						let isCurr = currLessN === b1.lessN;
						let isNext = nextLessN === b1.lessN;

						let diffSubjects = false;
						ll.forEach((l1, idx) => {
							if (idx > 0 && l1.subj_id != ll[idx-1].subj_id)
								diffSubjects = true;
						});

						return (
							<td className={'sched td_less' + (dayN === todayN? ' less_today' +
																					(isCurr? ' less_curr': '') +
																					(isCurr && this.td_curr_large? ' large': '') +
																					(isNext? ' less_next': ''): '') +
																				(l1.weekN && l_aw? ' less_weekN': '') +
																				(l1_anotherWeek? ' less_anotherWeek': '') +
																				(l1.anotherWeek? ' less_anotherWeek2': '') +
																				(currSel.less && l1.id === currSel.less.id? ' selected': '') +
																				(l1.remote? ' remote': '')
														}
									title = {title}
									onClick={this.td_Click.bind(this, l1, l_aw, 0, 0, false)}
									key={idx}>
								<div className={'col_width' + col_width_type}>
									<div	className={'subj_name' + (l1.remote? ' remote': '') 
																							 + (!l1.subj_id? ' no_teacher': '')
																							 + (diffSubjects? ' diffSubjects': '')
																							 + (l1.subj_id === this.state.subj_id? ' same': '')} 
												title={diffSubjects? func.Words('diffSubjects')
																	:l1.subj_id && this.uz.subj[l1.subj_id].name_full || ''} >
										{l1.subj_id && l1.subj_name || func.Words('no_subj')}
									</div>
									{/* <SameCnt l1={l1}/> */}
									{ll.map((l1, idx) => {
										// тут выводятся подгруппы:
										return (<><div key={idx} className='room_teacher_cont'>
															<Room l1={l1} idx={idx} l1_prev={ll[idx-1]}/>
															{l1.teacher_id?
																<Link to = {`${this.root}${this.uz.url}/teacher/${l1.teacher_id}`}>
																	<div className='teacher_name'>{l1.teacher_name}</div>
																</Link>
																: <div className='teacher_name no_teacher'>{l1.teacher_name}</div>
															}
															{l1.subN && <div className='subN' title={`${func.Words('group')} ${l1.subN}`}>
																						{(isNaN(l1.subN)? '': func.Words('gr')) + l1.subN}
																					</div>}
														</div>
														{/* между группами */}
														{idx < ll.length-1? <hr/>: null}
														</>)
									})}
									<LT l1={l1}/>
									<LW l1={l1} l_aw={l_aw}/>
								</div>
							</td>
						)

					})}
				</tr>
			)
		} // TableRowBells
		
		const TableRowDays = (props) => {
			let days = props.days;	// массив уроков с одним lessN
			let b1 = props.b1;			// 1 элемент bell[]

			let time1 = this.lh > 1 || shiftN === 1? b1.time11: b1.time21;
			let time2 = this.lh > 1 || shiftN === 2? b1.time22: b1.time12;
			if (!time1) return null;

			return (
				<tr key={props.idx} className='the_row'>
					<td key={props.idx} className={'td_center td_time time_bell sticky_col first_col' + 		// время
													(props.isCurr? ' time_curr': '') + 
													(props.isNext? ' time_next': '')}>
						<div className='time_num'>{'#' + b1.lessN}</div>
						<div className='time_vert' 
									title={time2 && (this.props.innerWidth <= 1000 || (!props.isCurr && !props.isNext))? 
													'до '+func.time_lesson(time2, 0, false)
													: 'Начало'}>
							{func.time_lesson(time1, 0, this.props.innerWidth <= 1000)}
						</div>
						{time2 && (props.isCurr || props.isNext)?
							<div className={'time_end'+(this.props.innerWidth <= 1000? '_small': '')} title='Конец'>
								{func.time_lesson(time2, 0)}
							</div>
							: null}
					</td>

					{this.week_days.map((dayN, idx) => {
						if (idx < 1) return null;		// "уголок" здесь не нужен

						// l_aw - какбы признак, что есть урок на другой неделе
						let l1_anotherWeek = false;
						let l_aw = days.find((l1) => {
													return l1 && l1.dayN === dayN && (l1.weekN && l1.weekN !== this.uz.weekN)
												}) || false;

						let l1 = days.find((l1, idx) => {
												return	l1 
																&& l1.dayN === dayN 
																&& (!l1.weekN 
																		|| (l1.weekN === this.uz.weekN && !l1.anotherWeek)
																		|| (l1.weekN && l1.weekN !== this.uz.weekN && l1.anotherWeek)
																		)
											});

						if (!l1 && l_aw) {
							l1 = l_aw;
							l1_anotherWeek = true;
							l_aw = false;
						}

						if (!l1)
							return <td key={idx} className={'sched td_empty' + 
																							(dayN === todayN? ' less_today': '') + 
																							(currSel.lessN === b1.lessN &&
																								currSel.dayN === dayN ? ' selected': '') }
													onClick={this.td_Click.bind(this, null, null, b1.lessN, dayN, false)}>
											<div className='col_width_wide'>x</div></td>;

						// берём все подходящие, включая этот (подгруппы)
						let ll = days.filter((l) => {
							return l.lessN === l1.lessN && l.dayN === l1.dayN && l.weekN === l1.weekN;
						});

						let title = '';
						if (l1 && l1.weekN)	
							title = l_aw? (l1.weekN === 1? 'Нечётная неделя' : 'Чётная неделя')
												:(l1.weekN === 1? 'только по Нечётным' : 'только по Чётным')

						// в зависимости от наличия weekN и less_type, делаем или нет отступ для "меток"
						// т.е. для каждого урока он может быть или нет, а не как раньше, если у 1го то у всех отступ
						let col_width_type = '_wide';
						if (l1.weekN || l1.less_type_id) col_width_type = '_narrow';

						let diffSubjects = false;
						ll.forEach((l1, idx) => {
							if (idx > 0 && l1.subj_id != ll[idx-1].subj_id)
								diffSubjects = true;
						});

						return (
							<td className={'sched td_less' + (dayN === todayN? ' less_today' +
																					(props.isCurr? ' less_curr': '') +
																					(props.isCurr && this.td_curr_large? ' large': '') +
																					(props.isNext? ' less_next': ''): '') +
																				(l1.weekN && l_aw? ' less_weekN': '') +
																				(l1_anotherWeek? ' less_anotherWeek': '') +
																				(l1.anotherWeek? ' less_anotherWeek2': '') +
																				(currSel.less 
																					&& currSel.lessN == l1.lessN
																					&& currSel.dayN == l1.dayN? ' selected'
																					: currSel.prev_less
																						&& currSel.prev_less.id == l1.id
																						&& !currSel.less? ' selected_prev': '') +
																				(l1.remote? ' remote': '')
														} 
									title = {title}
									onClick={this.td_Click.bind(this, l1, l_aw, 0, 0, false)}
									// onClick={() => (ll.length === 1 || !this.odmin_mode) && this.td_Click(l1, l_aw)}
									key={idx}>
								<div className={'col_width' + col_width_type}>
									<div className={'subj_name' + (!l1.subj_id? ' no_teacher': '')
																							 + (diffSubjects? ' diffSubjects': '')
																							 + (l1.subj_id === this.state.subj_id? ' same': '')}
												onClick={this.td_Click.bind(this, l1, l_aw, 0, 0, true)}
												// onClick={this.odmin_mode? this.subj_name_Click.bind(this, l1, 1, 2, 3): null} 
												title={diffSubjects? func.Words('diffSubjects')
																	:l1.subj_id && this.uz.subj[l1.subj_id].name_full || ''} >
										{l1.subj_id && l1.subj_name || func.Words('no_subj')}
									</div>
									{ll.map((l1, idx) => {
										// тут выводятся подгруппы:
										return (<div key={idx}><div className={'room_teacher_cont'}
																		onClick={this.td_Click.bind(this, l1, l_aw, 0, 0, false)}
																		// onClick={() => ll.length > 1 && this.odmin_mode && this.td_Click(l1, l_aw)}
																		>
															<Room l1={l1} idx={idx} l1_prev={ll[idx-1]}/>
															{l1.teacher_id?
																this.odmin_mode?
																	<div className='teacher_name'>{l1.teacher_name}</div>
																	:<Link to = {`${this.root}${this.uz.url}/teacher/${l1.teacher_id}`}>
																		<div className='teacher_name'>{l1.teacher_name}</div>
																	</Link>
																: <div className='teacher_name no_teacher'>{l1.teacher_name}</div>
															}
															{l1.subN && <div className={'subN' + 
																						(currSel.less && l1.id === currSel.less.id? ' selected': 
																								currSel.prev_less 
																								&& currSel.prev_less.id == l1.id
																								&& !currSel.less? ' selected_prev': '')}
																						title={`${func.Words('group')} ${l1.subN}`}>
																						{(isNaN(l1.subN)? '': func.Words('gr')) + l1.subN}
																					</div>}
															{/* между группами */}
															{/* {idx < ll.length-1? <hr/>: null} */}
														</div>
														{idx < ll.length-1? <hr/>: null}
														</div>)
									})}
									<LT l1={l1}/>
									<LW l1={l1} l_aw={l_aw}/>
								</div>
							</td>
						)
					} // this.week_days.map
					)}
				</tr>
			)
		} // TableRowDays


		if (!this.group) return null;

		if (msg) {	// <div Select> тут не надо, т.к. Schedule уже в нём
			if ((msg+'').toLowerCase().indexOf('ошибка') >= 0 || !this.odmin_mode)
				return (<div className='menu_cont'>{msg}</div>)
		}

		this.weekHours = 0;
		this.week_days && this.week_days.forEach((dayN) => {
			this.weekHours += getCntCurrLessOfDay(dayN).cnt;
		});

		return (
			sched || msg? 
				<>
				<div 	className='Schedule'>
					<table id='tableid'
							// tabIndex='1'
							onClick={this.tableClick.bind(this)}
							onDoubleClick={() => this.tableDoubleClick()}>	
						<TableHeader />
						<tbody>
							{	this.colsBells
								? this.week_days.map((dayN, idx) =>							// внешний цикл по дням			day1	less1 less2
									{																									// вложенный - по парам		day2	less1	less2
										if ((idx === 0) ||
												(!sched && !this.odmin_mode)) return null;

										return <TableRowBells idx={idx} key={idx}
											// isCurr={currLessN === b1.lessN}						// "текущий урок" определяется во вложенном цикле
											// isNext={nextLessN === b1.lessN}
											dayN={dayN} bells={sched && sched.filter((l1) => l1.dayN === dayN) || []}
										/>
									})
								:	this.group_bell.map((b1, idx) =>							// внешний цикл по парам		less1	day1 day2...
									{																									// вложенный - по дням 		less2	day1 day2...
										if (idx === 0 ||
												((!sched || idx > this.maxLessN) && !this.odmin_mode)) return null;

										return <TableRowDays idx={idx} key={idx}
											isCurr={currLessN === b1.lessN}								// "текущий урок" определяется во внешнем цикле
											isNext={nextLessN === b1.lessN}
											b1={b1} days={sched && sched.filter((l1) => l1.lessN === b1.lessN) || []}
										/>
									})
							}
						</tbody>
					</table>
				</div>
				<span className='sched_footer_cont'>
					{this.group && this.group.teacher_id && this.group.teacher_name &&
						<div className='sched_footer'>
							{func.Words('group_boss')+': '}
							<Link to = {`${this.root}${this.uz.url}/teacher/${this.group.teacher_id}`}>
								<span className="teacher_name">
									{this.group.teacher_name}
								</span>
							</Link>
						</div>}
						<div className='sched_footer'>
							{func.Words('week_hours')+': '}<span className="week_hours">{this.weekHours}</span>
						</div>
					</span>
					<EnterLess uz={this.uz} group_id={this.group.id} />
				</>
				: <div className='img_loading'>
						<CircularProgress color='inherit' size='5rem'/>
					</div>
			)
	} // render()

} // Schedule

export default Schedule;
