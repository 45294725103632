import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

import { makeStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Grow from '@material-ui/core/Grow';

// import { instanceOf } from 'prop-types';
// import { withCookies, Cookies } from 'react-cookie';

import {Modal} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

import connector from './connector.js';
import {keys, func} from './func.js';

import './HF.css';
import img_icon from '../images/ball_yellow.ico';

let events_exp;
let events_change_id;

const setActiveEvent = (id) => {
	events_change_id = id;
}

class Footer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			events				: this.props.events,
			eventShowing	: false,
			runAlign			: 1000,
			running				: false,
		}

		this.req_interval				= 10 * 60 * 1000
		this.req_interval_mount	= 50

		this.uz 								= this.props.uz

		this.runIdx 			= 0;
		this.runStr				= '';
		this.runStrNoTags	= '';
		this.runStrDate		= 0;
		this.runStrWidth	= 0;
		this.runSpeed			= 3;

		this.icon_cls = 'visible';
		this.events = [];
	}

	getData(delay) {
		if (!this.uz) return;

		delay = delay || this.req_interval || 1000;
		clearTimeout(this.ti_query);

		this.ti_query = setTimeout(() => {
			connector.do_query({cmd:'get_events', uz_id:this.uz.id, active_only:true},
					this.receiveData);
		}, delay);
	};

	get_tex_width(txt, font) {
		this.element = document.createElement('canvas');
		this.context = this.element.getContext("2d");
		this.context.font = font;
		return this.context.measureText(txt).width;
	}

	receiveData = (data_msg, data) => {
		this.events = data;
		this.running = this.events && this.events.length > 0;
		
		if (this.running && this.runStr === '') {
			this.runIdx = 0;
			this.runStr = this.events[this.runIdx].text || '';
			this.runStrDate = this.events[this.runIdx].date || 0;
			this.runStrNoTags = this.runStr.replace(/<[^>]+>/g, '');
			this.runStrWidth	= this.get_tex_width(this.runStrNoTags, '2vmax Arial'); //20px Arial
		}

		events_exp = data;

		this.setState({
			events 		: data,
			runAlign	: this.state.runStr === ''? window.innerWidth: this.state.runAlign,
			running		: this.running
		});
		this.getData();
	};

	componentDidUpdate() {
	}

	componentWillUnmount() {
		clearTimeout(this.ti_query);
		clearInterval(this.ti_run);
	}

	componentDidMount() {
		// console.log(`DidMount ${this.state.req}`);
		this.getData(this.req_interval_mount);

		// setInterval(()=>{
		// 	this.icon_cls = (this.icon_cls !== 'hidden'? 'hidden': 'visible');
		// 	this.forceUpdate();
		// }, 500);

		this.ti_run = setInterval(() => {
			if (!this.running) return;
			let rA = this.state.runAlign - this.runSpeed;

			if (events_change_id && 
					(events_change_id != this.events[this.runIdx].id || !this.state.eventShowing)) {
				this.runIdx = this.events.findIndex((ev) => ev.id == events_change_id);
				this.runStr = this.events[this.runIdx].text || '';
				this.runStrDate = this.events[this.runIdx].date || 0;
				this.runStrNoTags = this.runStr.replace(/<[^>]+>/g, '');
				this.runStrWidth	= this.get_tex_width(this.runStrNoTags, '2vmax Arial'); //20px Arial					
				rA = window.innerWidth;
				this.setState({ eventShowing: true });
				events_change_id = undefined;
			}

			if (rA < -this.runStrWidth - this.runSpeed*100) {
				rA = window.innerWidth;
				this.runIdx ++;
				if (this.runIdx > this.events.length - 1)
					this.runIdx = 0;

				this.runStr = this.events[this.runIdx].text || '';
				this.runStrDate = this.events[this.runIdx].date || 0;
				this.runStrNoTags = this.runStr.replace(/<[^>]+>/g, '');
				this.runStrWidth	= this.get_tex_width(this.runStrNoTags, '2vmax Arial');
			}

			this.setState({
				runAlign	: rA,
			});
		}, 30);
	}

	render() {
		// console.log('Calculated width ' + this.get_tex_width("Hello World", "20px Arial"));

		document.documentElement.style.setProperty(`--footer-height`, 
			this.running? 'max(3.3vmax, 24px)': '0vmax');

		if (!this.running)
			return null;
			// (
			// 	<div className='Footer'>
			// 	</div>
			// )

		let strDateTime = func.localTime(new Date(this.runStrDate), true, true) + '';
		if (strDateTime.split(' ')[1] === '00:00')
			strDateTime = strDateTime.split(' ')[0];

		return (
		<>
			<div className='Footer'
					onClick	=	{() => this.setState({ eventShowing: true })}>
				{/* <div className='f_Icon'>
					<img className={this.icon_cls} alt='[!]' src={img_icon} />
				</div> */}
				<div	className='f_Caption' style={{textIndent:this.state.runAlign+'px'}}>
					{this.runStrNoTags}
				</div>
			</div>

			<ModalHelp
				onClick = {() => this.setState({ runAlign: -this.runStrWidth  - this.runSpeed*100 })}	//* 1.5
				show		= {this.state.eventShowing && this.running}
				onHide	=	{() => this.setState({ eventShowing: false })}
				size		= {this.runStrNoTags.length > 50 || window.innerWidth > 1400? 'lg': ''}
				className = {this.runStrNoTags.length > 50? ' modalWide': ''}
				help = {{ caption: this.state.eventShowing && this.running? strDateTime: '', 
										message: <>
											{/* <div className='f_eventDate_cont'>
												<div className='f_eventDate'>
													{this.state.eventShowing && this.running? strDateTime: ''}
												</div>
											</div> */}
											<div>
												{ReactHtmlParser(this.runStr.replace(/\n/gi, '<br>'))}
											</div> 
										</>
									} 
								}
			/>
		</>
		)
	}
} // Footer

export default Footer;
export {events_exp, setActiveEvent};

function ModalHelp(props) {
	return (
		<Modal
			{...props}
			size={props.size}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			contentClassName={"modalWindow" + props.className}
		>
			<Modal.Header className='modalHeader'>
				<Modal.Title id="contained-modal-title-vcenter" className='modalTitle'>
					{/* <img className='f_Icon_Msg' alt='' src={img_icon} /> */}
					{props.help.caption}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalBody'>
				{props.help.message}
			</Modal.Body>
			{/* <Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer> */}
		</Modal>
	);
}
